import React from "react";
import { Row, Input, InputNumber } from "antd";
import Table from "@mui/material/Table";
// import Input from "@mui/material/Input";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect, useContext } from "react";

const AdminTable = (props) => {
  console.log(props, "this is props in admin table");
  console.log(props.sizeData);

  const [data, setData] = useState(props.sizeData.order);

  const onInputChange = (value, index, record) => {
    // const { value } = e.target;
    console.log(index);
    console.log(record, "recooooredfff");

    // if (!/^\d+$/.test(value)) {
    //   // If the input is not numeric, you can handle the error or simply return
    //   console.error("Invalid input. Please enter only numeric values.");
    //   return;
    // }
    // Create a new array by spreading the existing GaugeSizeDetail array
    const updatedRows = data.rows.map((row, i) => {
      if (i === index) {
        const rate = parseFloat(record.sheet_per_roll);
        const prodKg = (parseFloat(value) * rate).toFixed(2);
        return {
          ...row,
          prod_pcs: value,
          prod_qty: prodKg,
        };
      } else {
        return row;
      }
    });
    // Update the state with the modified data array
    setData((prevData) => ({
      ...prevData,
      rows: updatedRows,
    }));
    //Update the state with the new array
    props.setMata((prevData) => ({
      ...prevData,
      DailyProductionDetail: updatedRows,
    }));
  };

  const onRemarkChange = (value, index, record) => {
    const updatedRows = data.rows.map((row, i) => {
      if (i === index) {
        return {
          ...row,
          remarks: value,
        };
      } else {
        return row;
      }
    });

    setData((prevData) => ({
      ...prevData,
      rows: updatedRows,
    }));

    props.setMata((prevData) => ({
      ...prevData,
      ProductionDetail: updatedRows,
    }));
  };
  // Define columns based on the response type
  let columns = [];

  if (props.sizeData.type === "os") {
    columns = [
      { key: "11", title: "Order Code", dataIndex: "booking_ref" },
      { key: "14", title: "Item", dataIndex: "item_nm" },
      // { key: "13", title: "Customer", dataIndex: "distributor_desc" },

      { key: "15", title: "Texture", dataIndex: "texture_nm" },
      { key: "16", title: "Color", dataIndex: "color" },
      { key: "17", title: "Width", dataIndex: "val_wdth" },

      { key: "19", title: "Length", dataIndex: "val_len" },
      { key: "20", title: "Thick", dataIndex: "val_thik" },
      { key: "21", title: "Masking", dataIndex: "masking" },
      { key: "22", title: "Uv", dataIndex: "uv_t" },

      /////////////////////
      { key: "27", title: "Order Pcs", dataIndex: "tot_bal_pcs" },
      { key: "34", title: "Order Weight", dataIndex: "wt" },
      // {
      //   key: "28",
      //   title: "Prod Pcs",
      //   dataIndex: "prod_pcs",
      //   editable: true,
      //   render: (text, record, index) => (
      //     <InputNumber
      //       value={text}
      //       onChange={(e) => {
      //         onInputChange(e, index, record);
      //       }}
      //       style={{ textAlign: "left" }}
      //     />
      //   ),
      // },
      // { key: "33", title: "Prod Kgs", dataIndex: "prod_qty" },

      // { key: "35", title: "SO Remarks", dataIndex: "remarks1" },
      // {
      //   key: "36",
      //   title: "Plan Remarks",
      //   dataIndex: "remarks",
      //   editable: true,
      //   render: (text, record, index) => (
      //     <Input
      //       value={text}
      //       onChange={(e) => onRemarkChange(e.target.value, index, record)}
      //     />
      //   ),
      // },
      // { key: "35", title: "Sheet Per Roll ", dataIndex: "sheet_per_roll" },
      // { key: "23", title: "Sheet Area ", dataIndex: "sheet_area" },
      // { key: "24", title: "Per SQM ", dataIndex: "per_sqm" },

      // { key: "25", title: "Sheet/Roll", dataIndex: "sheet_per_roll" },
    ];
  } else if (props.sizeData.type === "is") {
    columns = [
      { key: "14", title: "Item", dataIndex: "item_nm" },

      { key: "15", title: "Texture", dataIndex: "texture_nm" },
      { key: "16", title: "Color", dataIndex: "color" },
      { key: "17", title: "Width", dataIndex: "val_wdth" },

      { key: "19", title: "Length", dataIndex: "val_len" },
      { key: "20", title: "Thick", dataIndex: "val_thik" },
      { key: "21", title: "Masking", dataIndex: "masking" },
      { key: "22", title: "Uv", dataIndex: "uv_t" },

      { key: "27", title: "Inventory Pcs", dataIndex: "tot_bal_pcs" },
      { key: "34", title: "Order Weight", dataIndex: "wt" },
    ];
  } else if (props.sizeData.type === "ostis") {
    columns = [
      { key: "11", title: "Order Code", dataIndex: "booking_ref" },
      { key: "14", title: "Item", dataIndex: "item_nm" },
      { key: "35", title: "Date", dataIndex: "date" },
      { key: "15", title: "Texture", dataIndex: "texture_name" },
      { key: "16", title: "Color", dataIndex: "color_name" },
      { key: "17", title: "Width", dataIndex: "val_wdth" },
      { key: "19", title: "Length", dataIndex: "val_len" },
      { key: "20", title: "Thick", dataIndex: "val_thik" },
      { key: "21", title: "Masking", dataIndex: "masking_name" },
      { key: "22", title: "Uv", dataIndex: "uv_t" },
      {
        key: "28",
        title: "Adj. Pcs",
        dataIndex: "prod_pcs",
        editable: true,
        render: (text, record, index) => (
          <InputNumber
            value={text}
            onChange={(e) => {
              onInputChange(e, index, record);
            }}
            style={{ textAlign: "left" }}
          />
        ),
      },
      { key: "33", title: "Adj. Kgs", dataIndex: "prod_qty" },
      { key: "27", title: "Order Pcs", dataIndex: "tot_pcs" },
      { key: "51", title: "Order kg", dataIndex: "tot_wt" },

      { key: "36", title: "Inv Kgs", dataIndex: "ret_bal" },
      { key: "37", title: "Inv Pcs", dataIndex: "ret_pcs" },
    ];
  } else if (props.sizeData.type === "oibs") {
    columns = [
      { key: "11", title: "Order Code", dataIndex: "booking_ref" },
      { key: "14", title: "Item", dataIndex: "item_nm" },
      { key: "35", title: "Date", dataIndex: "date" },
      { key: "15", title: "Texture", dataIndex: "texture_name" },
      { key: "16", title: "Color", dataIndex: "color_name" },
      { key: "17", title: "Width", dataIndex: "val_wdth" },
      { key: "19", title: "Length", dataIndex: "val_len" },
      { key: "20", title: "Thick", dataIndex: "val_thik" },
      { key: "21", title: "Masking", dataIndex: "masking_name" },
      { key: "22", title: "Uv", dataIndex: "uv_t" },
      { key: "27", title: "Order Pcs", dataIndex: "tot_pcs" },
      { key: "36", title: "Inv Kgs", dataIndex: "ret_bal" },
      { key: "37", title: "Inv Pcs", dataIndex: "ret_pcs" },
    ];
  }

  return (
    <Row>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  style={{
                    color: "#071E54",
                    fontWeight: "bold",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rows.map((record, index) => (
              <TableRow key={record.id}>
                {columns.map((column) => (
                  <TableCell key={column.key} style={{ whiteSpace: "nowrap" }}>
                    {column.render
                      ? column.render(record[column.dataIndex], record, index)
                      : record[column.dataIndex]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  );
};

export default AdminTable;
