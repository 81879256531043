// axios
//         .get(employeeData.URL + "/api/v1/salesInvoice/get-all-invoice", {
//           withCredentials: true,
//         })
//         .then((response) => {
//           console.log(response);
//           setColumns((columns) => {
//             let newCols = response.data.data.ledger.fields.map((col) => {
//               return {
//                 name: col.name,
//                 title: col.name.split("_").join(" ").toLowerCase(),
//               };
//             });

import classes from "../Pages.module.css";
import { Row, Col, Skeleton, Dropdown, Tooltip, Menu, message } from "antd";
import { useState, useEffect, useContext } from "react";
import classes1 from "./ToolRow.module.css";
import axios from "axios";
import { saveAs } from "file-saver";
import DataContext from "../../../Context/dataContext";
import SalesTable from "./SalesTable";
import { FaFileExport } from "react-icons/fa";

const SaleOrderRegister = (props) => {
  const mainData = useContext(DataContext);
  const [columns, setColumns] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [allRows, setAllRows] = useState(null);

  const [filters, setFilters] = useState([]); // State to store filters

  useEffect(() => {
    axios
      .get(mainData.URL + "/api/v1/salesInvoice/get-all-invoice", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setColumns((columns) => {
          let newCols = response.data.data.ledger.fields.map((col) => {
            return {
              name: col.name,
              title: col.name.split("_").join(" ").toLowerCase(),
            };
          });

          const newNewCols = [{ name: "SNO", title: "SNo" }, ...newCols];
          return newNewCols;
        });

        setAllRows((rows) => {
          let newRows = response.data.data.ledger.rows.map((row, index) => {
            return {
              SNO: index + 1,
              ...row,
            };
          });
          return newRows;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleFiltersChange = (newFilters) => {
    console.log(newFilters); // Log the new filters
    setFilters(newFilters);
  };

  const printPagePdf = (event) => {
    setExporting(true);

    // Ensure filters state is populated and correct
    console.log(filters);
    const filterString = filters
      .map(
        (filter) =>
          `${filter.columnName} LIKE '%${filter.value.toUpperCase()}%'`
      )
      .join(" AND ");

    // Log to verify filterString
    console.log(filterString);

    axios
      .post(
        mainData.URL + "/api/v1/salesInvoice/pdf/download/pdfmake",
        { filter: filterString },
        {
          responseType: "arraybuffer", // Response type (third parameter)
          withCredentials: true, // Include credentials
          credentials: "include", // Specify credentials type
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });

        // Use FileSaver.js saveAs function to trigger download
        saveAs(blob, "all_dispatch_register.pdf");
        message.success({
          content: "PDF Generated Successfully!!!!",
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        setExporting(false);
      })
      .catch((err) => {
        console.log(err);
        setExporting(false);
      });
  };

  const printPageExcel = (event) => {
    setExporting(true);

    // Ensure filters state is populated and correct
    console.log(filters);
    const filterString = filters
      .map(
        (filter) =>
          `${filter.columnName} LIKE '%${filter.value.toUpperCase()}%'`
      )
      .join(" AND ");

    // Log to verify filterString
    console.log(filterString);

    axios
      .post(
        mainData.URL + "/api/v1/salesInvoice/pdf/download/excelmake",
        { filter: filterString },
        {
          responseType: "arraybuffer", // Response type (third parameter)
          withCredentials: true, // Include credentials
          credentials: "include", // Specify credentials type
        }
      )
      .then((response) => {
        const data = response.data;
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "all_dispatch_register.xlsx");
        message.success({
          content: "Excel Sheet Generated Successfully!!!!",
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        setExporting(false);
      })
      .catch((err) => {
        console.log(err);
        setExporting(false);
      });
  };

  const exportMenu = (
    <Menu style={{ textAlign: "left" }}>
      <Menu.Item key="1">
        <button
          onClick={printPageExcel}
          className={classes1["StyledButtonX"]}
          style={{ color: "black" }}
        >
          Download as Excel
        </button>
      </Menu.Item>
      <Menu.Item key="2">
        <button
          onClick={printPagePdf}
          className={classes1["StyledButtonX"]}
          style={{ color: "black" }}
        >
          Download as PDF
        </button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row className={classes["Row"]}>
        <Col md={14}>
          <p className={classes["Title"]}>All Dispatch Register</p>
        </Col>

        <Col xl={3} lg={3} md={3} xs={{ span: 24 }} className={classes["Col"]}>
          <Dropdown overlay={exportMenu} placement="bottomCenter">
            <Tooltip title="" placement="bottom">
              <button
                className={classes1["StyledButtonPdf"]}
                style={{ color: "rgba(255,163,77,1)" }}
              >
                <FaFileExport className={classes1["StyledIcon"]} /> Export
              </button>
            </Tooltip>
          </Dropdown>
        </Col>
      </Row>
      <p></p>

      <p></p>
      {allRows && columns.length > 0 ? (
        <>
          <SalesTable
            data={allRows}
            columns={columns}
            onFiltersChange={handleFiltersChange}
          />
        </>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default SaleOrderRegister;
