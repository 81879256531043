//import {Row} from 'antd';
import {
  Row,
  Col,
  Form,
  Tabs,
  Input,
  Space,
  Button,
  DatePicker,
  Select,
  InputNumber,
} from "antd";
import axios from "axios";
import classes from "../Pages.module.css";
import DataField from "./DataField";
//import BoonkigSizeEdit from './BookingSizeEdit';
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState, useContext, useEffect } from "react";
import DataContext from "../../../Context/dataContext";
//import SalesNew from './SalesNew';
//import { useState, useEffect, useContext} from 'react';
const { Option } = Select;
const { TabPane } = Tabs;
const BookingSize = (props) => {
  const employeeData = useContext(DataContext);
  const [sizef, setSizef] = useState([]);
  const [gradef, setGradef] = useState([]);
  const [uom, setUom] = useState([]);
  const [edi, setEdi] = useState(false);
  const [form] = Form.useForm();
  const [itemindex, setItemindex] = useState();

  const handleChangee = (changedValues, allValues) => {
    props.setData((data) => {
      //  let newDetails = data.changedValues
      //  console.log(newDetails)
      //  console.log(changedValues.bookingSize.length)
      console.log("ytu");
      console.log(data);
      console.log(data.bookingSize1.length);
      var i = data.bookingSize.length;
      var j = data.bookingSize1.length;
      // console.log(changedValues.bookingSize.length)
      console.log(j);
      if (data.bookingSize.length == data.bookingSize1.length) {
        if (
          data.bookingSize[i - 1].discount_on == "r" &&
          data.bookingSize[i - 1].dis_type == "p"
        ) {
          const Totvalue =
            (data.bookingSize[i - 1].bk_rate *
              data.bookingSize[i - 1].discount_amount) /
            100;

          const ForderRate = data.bookingSize[i - 1].bk_rate - Totvalue;
          data.bookingSize[i - 1].booking_rate = ForderRate;
          data.bookingSize[i - 1].tot_item_amt =
            data.bookingSize[i - 1].qty * ForderRate;
        } else if (
          !data.bookingSize[i - 1].discount_on &&
          !data.bookingSize[i - 1].dis_type
        ) {
          data.bookingSize[i - 1].tot_item_amt =
            data.bookingSize[i - 1].qty * data.bookingSize[i - 1].bk_rate;
          // document.getElementById("qty").value = data.invoiceSize[i-1].qty;
        } else if (
          data.bookingSize[i - 1].discount_on == "r" &&
          data.bookingSize[i - 1].dis_type == "a"
        ) {
          const Totvalue1 =
            data.bookingSize[i - 1].bk_rate -
            data.bookingSize[i - 1].discount_amount;
          data.bookingSize[i - 1].booking_rate = Totvalue1;
          const Final1 = data.bookingSize[i - 1].qty * Totvalue1;
          data.bookingSize[i - 1].tot_item_amt = Final1;
        } else if (
          data.bookingSize[i - 1].discount_on == "ta" &&
          data.bookingSize[i - 1].dis_type == "p"
        ) {
          data.bookingSize[i - 1].booking_rate =
            data.bookingSize[i - 1].bk_rate;
          const Totvalue =
            (data.bookingSize[i - 1].bk_rate *
              data.bookingSize[i - 1].discount_amount *
              data.bookingSize[0].qty) /
            100;

          const ForderRate =
            data.bookingSize[i - 1].bk_rate * data.bookingSize[i - 1].qty -
            Totvalue;

          data.bookingSize[i - 1].tot_item_amt = ForderRate;
        } else if (
          data.bookingSize[i - 1].discount_on == "ta" &&
          data.bookingSize[i - 1].dis_type == "a"
        ) {
          data.bookingSize[i - 1].booking_rate =
            data.bookingSize[i - 1].bk_rate;
          const Totvalue1 =
            data.bookingSize[i - 1].bk_rate * data.bookingSize[i - 1].qty -
            data.bookingSize[i - 1].discount_amount;
          data.bookingSize[i - 1].tot_item_amt = Totvalue1;
        }
      } else {
        if (
          data.bookingSize[j - 1].discount_on == "r" &&
          data.bookingSize[j - 1].dis_type == "p"
        ) {
          const Totvalue =
            (data.bookingSize[j - 1].bk_rate *
              data.bookingSize[j - 1].discount_amount) /
            100;

          const ForderRate = data.bookingSize[j - 1].bk_rate - Totvalue;
          data.bookingSize[j - 1].booking_rate = ForderRate;
          data.bookingSize[j - 1].tot_item_amt =
            data.bookingSize[j - 1].qty * ForderRate;
        } else if (
          data.bookingSize[j - 1].discount_on == "r" &&
          data.bookingSize[j - 1].dis_type == "a"
        ) {
          const Totvalue1 =
            data.bookingSize[j - 1].bk_rate -
            data.bookingSize[j - 1].discount_amount;
          data.bookingSize[j - 1].booking_rate = Totvalue1;
          const Final1 = data.bookingSize[j - 1].qty * Totvalue1;
          data.bookingSize[j - 1].tot_item_amt = Final1;
        } else if (
          !data.bookingSize[j - 1].discount_on &&
          !data.bookingSize[j - 1].dis_type
        ) {
          data.bookingSize[j - 1].tot_item_amt =
            data.bookingSize[j - 1].qty * data.bookingSize[j - 1].bk_rate;
          // document.getElementById("qty").value = data.invoiceSize[i-1].qty;
        } else if (
          data.bookingSize[j - 1].discount_on == "ta" &&
          data.bookingSize[j - 1].dis_type == "p"
        ) {
          data.bookingSize[j - 1].booking_rate =
            data.bookingSize[j - 1].bk_rate;
          const Totvalue =
            (data.bookingSize[j - 1].bk_rate *
              data.bookingSize[j - 1].discount_amount *
              data.bookingSize[0].qty) /
            100;

          const ForderRate =
            data.bookingSize[j - 1].bk_rate * data.bookingSize[j - 1].qty -
            Totvalue;

          data.bookingSize[j - 1].tot_item_amt = ForderRate;
        } else if (
          data.bookingSize[j - 1].discount_on == "ta" &&
          data.bookingSize[j - 1].dis_type == "a"
        ) {
          data.bookingSize[j - 1].booking_rate =
            data.bookingSize[j - 1].bk_rate;
          const Totvalue1 =
            data.bookingSize[i - 1].bk_rate * data.bookingSize[j - 1].qty -
            data.bookingSize[j - 1].discount_amount;
          data.bookingSize[j - 1].tot_item_amt = Totvalue1;
        }
      }

      //for second item if we add

      // if (data.bookingSize[1].discount_on == 'r' && data.bookingSize[1].dis_type =='p') {

      //     const Totvalue = data.bookingSize[1].net_rate * data.bookingSize[1].discount_amount / 100;

      //     const ForderRate = data.bookingSize[1].net_rate - Totvalue

      //     data.bookingSize[1].net_size_rate = data.bookingSize[1].qty * ForderRate
      // }

      // else if (data.bookingSize[1].discount_on == 'r' && data.bookingSize[1].dis_type =='a') {
      //     const Totvalue1 = data.bookingSize[1].net_rate - data.bookingSize[1].discount_amount;
      //     const Final1 = data.bookingSize[1].qty * Totvalue1
      //     data.bookingSize[1].net_size_rate = Final1
      // }

      // else if (data.bookingSize[1].discount_on == 'ta' && data.bookingSize[1].dis_type =='p') {
      //     const Totvalue = data.bookingSize[1].net_rate * data.bookingSize[1].discount_amount*data.bookingSize[1].qty / 100;

      //     const ForderRate = data.bookingSize[1].net_rate*data.bookingSize[1].qty - Totvalue

      //     data.bookingSize[1].net_size_rate =  ForderRate
      // }

      // else if (data.bookingSize[1].discount_on == 'ta' && data.bookingSize[1].dis_type =='a') {
      //     const Totvalue1 = data.bookingSize[1].net_rate * data.bookingSize[1].qty- data.bookingSize[1].discount_amount;
      //     data.bookingSize[1].net_size_rate = Totvalue1
      // }
      return {
        ...data,
        //   bookingSize: changedValues.bookingSize
      };
    });

    //  console.log( allValues.bookingSize)
  };

  const handleChangee2 = (changedValues, allValues, i) => {
    props.setData((data) => {
      console.log("ytu");
      console.log(data);
      console.log(i);
      console.log(data.bookingSize[i].length);
      const { length, width, thickness, item_code, texture_code } =
        data.bookingSize[i];

      // Construct the URL with query parameters
      const url = `${employeeData.URL}/api/v1/salesOrder/level/?length=${length}&width=${width}&thickness=${thickness}&item_code=${item_code}&texture_code=${texture_code}`;

      // Make the Axios GET request
      axios
        .get(url, {
          withCredentials: true, // Optionally include additional configuration options
        })
        .then((response) => {
          console.log(response);
          // Split the response data by commas
          const values = response.data.data.size.split(",");
          console.log(values, "size333333333333333333333333333");
          // Assign the values to the respective properties
          const newData = { ...data };
          newData.bookingSize[i].sheet_area = parseFloat(values[2]);
          newData.bookingSize[i].per_sqm = parseFloat(values[3]);
          newData.bookingSize[i].sheet_per_roll = parseFloat(values[4]);

          return newData;
        });

      return {
        ...data,
      };
    });
  };

  const handleChangeepersqm2 = (changedValues, allValues, i) => {
    props.setData((data) => {
      console.log("ytu");
      console.log(data);
      console.log(i);
      // console.log(data.bookingSize[i].length);
      const {
        length,
        width,
        thickness,
        item_code,
        texture_code,
        per_sqm,
        sheet_area,
      } = data.bookingSize[i];

      // Construct the URL with query parameters
      const url = `${employeeData.URL}/api/v1/salesOrder/level2/?length=${length}&width=${width}&thickness=${thickness}&item_code=${item_code}&texture_code=${texture_code}&per_sqm=${per_sqm}&sheet_area=${sheet_area}`;

      // Make the Axios GET request
      axios
        .get(url, {
          withCredentials: true, // Optionally include additional configuration options
        })
        .then((response) => {
          console.log(response);
          // Split the response data by commas
          const values = response.data.data.size.split(",");
          console.log(values, "size333333333333333333333333333");
          // Assign the values to the respective properties
          const newData = { ...data };
          // newData.bookingSize[i].sheet_area = parseFloat(values[2]);
          // newData.bookingSize[i].per_sqm = parseFloat(values[3]);
          newData.bookingSize[i].sheet_per_roll = parseFloat(values[4]);

          return newData;
        });

      return {
        ...data,
      };
    });
  };

  const handleChangee3 = (changedValues, allValues, i) => {
    props.setData((data) => {
      console.log("ytu");
      console.log(data);
      console.log(i);
      console.log(data.bookingSize[i].length);
      const { length, billing_uom, no_pcs, item_code, rate, qty, total_sqmf } =
        data.bookingSize[i];

      // Construct the URL with query parameters
      const url = `${employeeData.URL}/api/v1/salesOrder/amount/?length=${length}&billing_uom=${billing_uom}&no_pcs=${no_pcs}&item_code=${item_code}&rate=${rate}&qty=${qty}&total_sqmf=${total_sqmf}`;

      // Make the Axios GET request
      axios
        .get(url, {
          withCredentials: true, // Optionally include additional configuration options
        })
        .then((response) => {
          console.log(response);
          // Split the response data by commas
          const values = response.data.data.size;
          console.log(values, "size333333333333333333333333333");
          // Assign the values to the respective properties
          const newData = { ...data };
          newData.bookingSize[i].amount = parseFloat(values);
          // newData.bookingSize[i].per_sqm = parseFloat(values[3]);
          // newData.bookingSize[i].sheet_per_roll = parseFloat(values[4]);

          // Update state with the new data after receiving the response
          props.setData(newData);
        });

      return {
        ...data,
      };
    });
  };

  const handleChangee4 = (changedValues, allValues, i) => {
    props.setData((data) => {
      console.log("ytu");
      console.log(data);
      console.log(i);
      console.log(data.bookingSize[i].length);
      const { length, billing_uom, no_pcs, item_code, width, thickness } =
        data.bookingSize[i];

      // Construct the URL with query parameters
      const url = `${employeeData.URL}/api/v1/salesOrder/rate/?length=${length}&billing_uom=${billing_uom}&no_pcs=${no_pcs}&item_code=${item_code}&width=${width}&thickness=${thickness}`;

      // Make the Axios GET request
      axios
        .get(url, {
          withCredentials: true, // Optionally include additional configuration options
        })
        .then((response) => {
          console.log(response);
          // Split the response data by commas
          const values = response.data.data.size;
          console.log(values, "size333333333333333333333333333");
          // Assign the values to the respective properties
          const newData = { ...data };
          newData.bookingSize[i].qty =
            newData.bookingSize[i].sheet_per_roll * no_pcs;
          newData.bookingSize[i].total_sqmf = parseFloat(values);

          return newData;
        });

      return {
        ...data,
      };
    });
  };

  const handleSelectChange = async (selectedValue, i) => {
    console.log(selectedValue);
    console.log(i, "after item change");
    // console.log(props.data[i].Mode, "this is mode");
    setItemindex(i);
    setEdi(true);
    // console.log(pcsRef.current.value);
    setSizef(sizef);
    const values = form.getFieldsValue(["bookingSize"]);

    console.log(values);
    // Check if Mode is true or present, then set it to false
    // if (props.data[i].Mode === true) {
    //   props.data[i].Mode = false;
    // }
    values.bookingSize[i].Mode = false;
    values.bookingSize[i].texture_code = null;
    values.bookingSize[i].color_code = null;

    form.setFieldsValue(values);
    props.setData((data) => {
      axios
        .get(
          employeeData.URL +
            "/api/v1/salesOrder/additional-data-of-hsn/" +
            selectedValue,
          {
            withCredentials: true,
          }
        )

        .then((response) => {
          console.log(response);
          // data.bookingSize[i].density=response.data.data.density.rows[0].density

          setSizef((prevSizef) => {
            console.log(prevSizef, " In the 83 line");
            const newSizef = [...prevSizef];
            newSizef[i] = response.data.data.size.rows.map((option) => (
              <Option
                style={{ textTransform: "capitalize", color: "#1777C4" }}
                key={option[response.data.data.size.fields[0].name]}
                value={option[response.data.data.size.fields[0].name]}
              >
                {option[response.data.data.size.fields[1].name]}
              </Option>
            ));
            console.log(newSizef);
            return newSizef;
          });

          setGradef((prevGradef) => {
            const newGradef = [...prevGradef];
            newGradef[i] = response.data.data.grade.rows.map((option) => (
              <Option
                style={{ textTransform: "capitalize", color: "#1777C4" }}
                key={option[response.data.data.grade.fields[0].name]}
                value={option[response.data.data.grade.fields[0].name]}
              >
                {option[response.data.data.grade.fields[1].name]}
              </Option>
            ));
            return newGradef;
          });

          // Assign the density value to data.bookingSize[i].density
          // const newData = { ...data };
          // newData.bookingSize[i].density =
          //   response.data.data.density.rows[0].density;

          // return newData;
        });

      return {
        ...data,
      };
    });
  };

  const handleDensityChange = async (selectedValue, i) => {
    console.log(selectedValue);
    console.log(i, "after item change");
    console.log(props.data[i].item_code);

    props.setData((data) => {
      axios
        .get(
          `${employeeData.URL}/api/v1/salesOrder/additional-item/${props.data[i].item_code}/${selectedValue}`,
          {
            withCredentials: true,
          }
        )

        .then((response) => {
          console.log(response);
          // data.bookingSize[i].density=response.data.data.density.rows[0].density

          // Assign the density value to data.bookingSize[i].density
          const newData = { ...data };
          newData.bookingSize[i].density =
            response.data.data.density.rows[0].density;
          newData.bookingSize[i].item_group_cd =
            response.data.data.group.rows[0].item_group_cd;
          return newData;
        });

      return {
        ...data,
      };
    });

    // // console.log(props.data[i].Mode, "this is mode");
    // setItemindex(i);
    // setEdi(true);
    // // console.log(pcsRef.current.value);
    // setSizef(sizef);
    // const values = form.getFieldsValue(["bookingSize"]);

    // console.log(values);
    // // Check if Mode is true or present, then set it to false
    // // if (props.data[i].Mode === true) {
    // //   props.data[i].Mode = false;
    // // }
    // values.bookingSize[i].Mode = false;
    // values.bookingSize[i].texture_code = null;
    // values.bookingSize[i].color_code = null;

    // form.setFieldsValue(values);
  };

  const handleValueChanges = (changedValues, allValues) => {
    props.setData((data) => {
      //  console.log(changedValues.bookingSize.length)
      return {
        ...data,
        bookingSize: allValues.bookingSize,
        bookingSize1: changedValues.bookingSize,
      };
    });
    // console.log( allValues.bookingSize)
    // console.log(changedValues.bookingSize)
    // console.log(changedValues.bookingSize.length)
  };

  return props.editMode ? (
    <Form
      layout="vertical"
      name="filter_form"
      onValuesChange={handleValueChanges}
      autoComplete="off"
      form={form}
    >
      <Form.List name="bookingSize" initialValue={props.data}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, i) => (
                <table
                  id="table_order"
                  className="table table-bordered"
                  style={{
                    tableLayout: "fixed",
                    width: "2150px",
                    marginBottom: "-2rem",
                  }}
                >
                  {i == 0 && (
                    <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
                      <tr
                        style={{
                          height: "0.4rem",
                          backgroundColor: "rgb(217 217 217 / 35%)",
                        }}
                      >
                        <th style={{ width: "8%" }}>
                          {" "}
                          Item{" "}
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>

                        {/* <th style={{}}>Uom</th> */}
                        <th style={{ width: "8%" }}>
                          Texture{" "}
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>
                        <th style={{ width: "8%" }}>
                          Color
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>
                        <th style={{}}>
                          Width{" "}
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>

                        <th style={{}}>
                          Profile Width
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>
                        <th style={{}}>
                          Length
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>
                        <th style={{}}>
                          Thickness
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>
                        <th style={{}}>
                          Density
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>
                        <th style={{ width: "8%" }}>
                          Masking{" "}
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>
                        <th style={{}}>Uv List</th>
                        <th style={{}}>
                          Thick Pack{" "}
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>
                        <th style={{}}>Sheet Area</th>
                        <th style={{}}>Per Sqm</th>
                        <th style={{}}>Sheet/Roll </th>
                        <th style={{}}>
                          Uom{" "}
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>
                        <th style={{}}>
                          Pcs{" "}
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>
                        <th style={{}}>
                          Rate{" "}
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>
                        <th style={{}}>
                          Wt{" "}
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>
                        <th style={{}}>Tot. Sqm</th>
                        <th style={{}}>Amount</th>
                        <th style={{}}>Remark</th>
                        <th style={{}}>
                          It. gp{" "}
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>
                        <th style={{}}>#</th>
                      </tr>
                    </thead>
                  )}

                  <tbody>
                    <tr>
                      <td
                        style={{
                          width: "8%",

                          border: "1px solid white",
                        }}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "item_code"]}
                          fieldKey={[fieldKey, "item_code"]}
                          rules={[{ required: true, message: "Missing Name" }]}
                        >
                          <Select
                            bordered={true}
                            style={{
                              width: "100%",
                              textAlign: "left",
                              float: "left",
                              backgroundColor: "white",
                              color: "#1777C4",
                              fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            placeholder="Select"
                            onChange={(valu) => handleSelectChange(valu, i)}
                            optionFilterProp="children"
                          >
                            {props.ad.ITEM_CODE.rows.map((option) => {
                              return (
                                <Option
                                  style={{
                                    textTransform: "capitalize",
                                    color: "#1777C4",
                                  }}
                                  key={
                                    option[props.ad.ITEM_CODE.fields[0].name]
                                  }
                                  value={
                                    option[props.ad.ITEM_CODE.fields[0].name]
                                  }
                                >
                                  {option[props.ad.ITEM_CODE.fields[1].name]}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </td>

                      {/* <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "uom"]}
                          fieldKey={[fieldKey, "uom"]}
                          rules={[{ required: true, message: "Missing Name" }]}
                        >
                          <Select
                            bordered={true}
                            style={{
                              width: "100%",
                              textAlign: "left",
                              float: "left",
                              backgroundColor: "white",
                              color: "#1777C4",
                              fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            placeholder="Select"
                            optionFilterProp="children"
                          >
                            {uom.length > 0 ? uom : null}
                          </Select>
                        </Form.Item>
                      </td> */}

                      <td style={{ width: "8%", border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "texture_code"]}
                          fieldKey={[fieldKey, "texture_code"]}
                          rules={[{ required: true, message: "Missing Name" }]}
                        >
                          <Select
                            bordered={true}
                            style={{
                              width: "100%",
                              textAlign: "left",
                              float: "left",
                              backgroundColor: "white",
                              color: "#1777C4",
                              fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            placeholder="Select"
                            onChange={(valu) => handleDensityChange(valu, i)}
                            optionFilterProp="children"
                          >
                            {console.log(i, "i in the size ")}
                            {console.log(sizef[i], " mode false ")}
                            {props.data[i]?.Mode !== undefined &&
                              (props.data[i]?.Mode
                                ? props.responseDataArray[i]?.size.rows.map(
                                    (option) => (
                                      <Option
                                        style={{
                                          textTransform: "capitalize",
                                          color: "#1777C4",
                                        }}
                                        key={
                                          option[
                                            props.responseDataArray[i].size
                                              .fields[0].name
                                          ]
                                        }
                                        value={
                                          option[
                                            props.responseDataArray[i].size
                                              .fields[0].name
                                          ]
                                        }
                                      >
                                        {
                                          option[
                                            props.responseDataArray[i].size
                                              .fields[1].name
                                          ]
                                        }
                                      </Option>
                                    )
                                  )
                                : edi
                                ? sizef[i]
                                : null)}
                          </Select>
                        </Form.Item>
                      </td>

                      <td style={{ width: "8%", border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "color_code"]}
                          fieldKey={[fieldKey, "color_code"]}
                          rules={[{ required: true, message: "Missing Name" }]}
                        >
                          <Select
                            bordered={true}
                            style={{
                              width: "100%",
                              textAlign: "left",
                              float: "left",
                              backgroundColor: "white",
                              color: "#1777C4",
                              fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            placeholder="Select"
                            optionFilterProp="children"
                          >
                            {/* {gradef.length > 0 ? gradef : null} */}
                            {props.data[i]?.Mode !== undefined &&
                              (props.data[i]?.Mode
                                ? props.responseDataArray[i]?.grade.rows.map(
                                    (option) => (
                                      <Option
                                        style={{
                                          textTransform: "capitalize",
                                          color: "#1777C4",
                                        }}
                                        key={
                                          option[
                                            props.responseDataArray[i].grade
                                              .fields[0].name
                                          ]
                                        }
                                        value={
                                          option[
                                            props.responseDataArray[i].grade
                                              .fields[0].name
                                          ]
                                        }
                                      >
                                        {
                                          option[
                                            props.responseDataArray[i].grade
                                              .fields[1].name
                                          ]
                                        }
                                      </Option>
                                    )
                                  )
                                : edi
                                ? gradef[i]
                                : null)}
                          </Select>
                        </Form.Item>
                      </td>

                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "width"]}
                          fieldKey={[fieldKey, "width"]}
                          rules={[
                            {
                              //   required: true,
                              //message: 'Field should not be blank!!'
                            },
                            {
                              //   type: 'number',
                              // message: "please enter only numeric value"
                            },
                          ]}
                        >
                          <Input
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Width"
                          />
                        </Form.Item>
                      </td>

                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "profile_width"]}
                          fieldKey={[fieldKey, "profile_width"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <InputNumber
                            // onChange={handleChangee}
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Profile Width"
                          />
                        </Form.Item>
                      </td>

                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "length"]}
                          fieldKey={[fieldKey, "length"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            // onChange={handleChangee}
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Length"
                          />
                        </Form.Item>
                      </td>

                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "thickness"]}
                          fieldKey={[fieldKey, "thickness"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            // onChange={handleChangee}
                            // onBlur={handleChangee2}
                            onBlur={(changedValues, allValues) =>
                              handleChangee2(changedValues, allValues, i)
                            }
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Thickness"
                          />
                        </Form.Item>
                      </td>

                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "density"]}
                          fieldKey={[fieldKey, "density"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            // onChange={handleChangee}
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Density"
                          />
                        </Form.Item>
                      </td>

                      <td style={{ width: "8%", border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "masking_code"]}
                          fieldKey={[fieldKey, "masking_code"]}
                          rules={[{ required: true, message: "Missing Name" }]}
                        >
                          <Select
                            // onChange={handleChangee}
                            bordered={true}
                            style={{
                              width: "100%",
                              textAlign: "left",
                              float: "left",
                              backgroundColor: "white",
                              color: "#1777C4",
                              fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            placeholder="Select"
                            optionFilterProp="children"
                          >
                            {props.ad.MASKING_DESC.rows.map((option) => {
                              return (
                                <Option
                                  style={{
                                    textTransform: "capitalize",
                                    color: "#1777C4",
                                  }}
                                  key={
                                    option[props.ad.MASKING_DESC.fields[1].name]
                                  }
                                  value={
                                    option[props.ad.MASKING_DESC.fields[1].name]
                                  }
                                >
                                  {option[props.ad.MASKING_DESC.fields[0].name]}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </td>
                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "uv"]}
                          fieldKey={[fieldKey, "uv"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            // onChange={handleChangee}
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Uv List Box"
                          />
                        </Form.Item>
                      </td>

                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "thick_pack"]}
                          fieldKey={[fieldKey, "thick_pack"]}
                          rules={[{ required: true, message: "Missing Name" }]}
                        >
                          <Input
                            // onChange={handleChangee}
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Thickness For Packing"
                          />
                        </Form.Item>
                      </td>
                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "sheet_area"]}
                          fieldKey={[fieldKey, "sheet_area"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            // onChange={handleChangee}
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Sheet Area"
                          />
                        </Form.Item>
                      </td>
                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "per_sqm"]}
                          fieldKey={[fieldKey, "per_sqm"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            onBlur={(changedValues, allValues) =>
                              handleChangeepersqm2(changedValues, allValues, i)
                            }
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Per Sqm"
                          />
                        </Form.Item>
                      </td>
                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "sheet_per_roll"]}
                          fieldKey={[fieldKey, "sheet_per_roll"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            // onChange={handleChangee}
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Sheet Per Roll"
                          />
                        </Form.Item>
                      </td>
                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "billing_uom"]}
                          fieldKey={[fieldKey, "billing_uom"]}
                          rules={[{ required: true, message: "Missing Name" }]}
                        >
                          <Select
                            // onChange={handleChangee}
                            bordered={true}
                            style={{
                              width: "100%",
                              textAlign: "left",
                              float: "left",
                              backgroundColor: "white",
                              color: "#1777C4",
                              fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            placeholder="Select"
                            optionFilterProp="children"
                          >
                            {props.ad.UOM.rows.map((option) => {
                              return (
                                <Option
                                  style={{
                                    textTransform: "capitalize",
                                    color: "#1777C4",
                                  }}
                                  key={option[props.ad.UOM.fields[0].name]}
                                  value={option[props.ad.UOM.fields[0].name]}
                                >
                                  {option[props.ad.UOM.fields[1].name]}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </td>

                      {/* <Col lg = {6} md = {12}>
                            <Form.Item
                                {...restField}
                                name={[name, 'discount_on']}
                                fieldKey={[fieldKey, 'discount_on']}
                                label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>DISCOUNT ON</div>}
                                rules={[{ required: true, message: 'Missing Name' }]}
                            >
                                <Select
                                    bordered = {false}
                                    style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    
                                >
                                {
                                    props.options.rows.map((option) => {
                                        return(
                                            <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.options.fields[0].name]} value={option[props.options.fields[0].name]}>{option[props.options.fields[1].name]}</Option>
                                        );
                                    })
                                }
                                </Select>
                            </Form.Item>
                        </Col> */}

                      {/* <Col lg = {6} md = {12}>
                    <Form.Item
                    {...restField}
                    name={[name, 'dis_type']}
                    fieldKey={[fieldKey, 'dis_type']}
                    label = {<div style = {{padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold"}} className={classes['Label']}>DISCOUNT TYPE</div>}
                    rules={[{ required: true, message: 'Missing Name' }]}
                >
                    <Select
                        bordered = {false}
                        style={{ width: "100%" , textAlign: "left", float: "left", backgroundColor : "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} 
                        placeholder="Select"
                        optionFilterProp="children" 
                    >
                    {
                        props.options.rows.map((option) => {
                            return(
                                <Option style = {{textTransform: "capitalize", color: "#1777C4"}} key = {option[props.options.fields[0].name]} value={option[props.options.fields[0].name]}>{option[props.options.fields[1].name]}</Option>
                            );
                        })
                    }
                    </Select>
                </Form.Item>
                </Col> */}

                      {/* <Col lg={8} md={24}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'book_rate_gauge']}
                                                    fieldKey={[fieldKey, 'book_rate_gauge']}
                                                    label={<div style={{ padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold" }} className={classes['Label']}>Rate After Discount</div>}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Field should not be blank!!'
                                                        },
                                                        {
                                                            type: 'number',
                                                            message: "please enter only numeric value"
                                                        },
    
                                                    ]}
                                                >
                                                    <Input
                                                        style={{ width: "100%", float: "left", backgroundColor: "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
                                                        bordered={false}
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                            </Col> */}
                      {/* <Col lg={8} md={24}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'net_size_rate']}
                                                    fieldKey={[fieldKey, 'net_size_rate']}
                                                    label={<div style={{ padding: "0rem 0.5rem", fontSize: "0.6rem", fontWeight: "bold" }} className={classes['Label']}>Amount</div>}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Field should not be blank!!'
                                                        },
                                                        {
                                                            type: 'number',
                                                            message: "please enter only numeric value"
                                                        },
    
                                                    ]}
                                                >
                                                    <Input
                                                        style={{ width: "100%", float: "left", backgroundColor: "white", color: "#1777C4", fontWeight: "bold", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
                                                        bordered={false}
                                                        placeholder="Enter Net Amount"
                                                    />
                                                </Form.Item>
                                            </Col>
    
    
    
                                            <Col lg={21} md={0}>
    
                                            </Col> */}
                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "no_pcs"]}
                          fieldKey={[fieldKey, "no_pcs"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            onBlur={(changedValues, allValues) =>
                              handleChangee4(changedValues, allValues, i)
                            }
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter No Of  Pcs"
                          />
                        </Form.Item>
                      </td>
                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "rate"]}
                          fieldKey={[fieldKey, "rate"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            onBlur={(changedValues, allValues) =>
                              handleChangee3(changedValues, allValues, i)
                            }
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Order Rate"
                          />
                        </Form.Item>
                      </td>

                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "qty"]}
                          fieldKey={[fieldKey, "qty"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            // onChange={handleChangee}
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Weight"
                          />
                        </Form.Item>
                      </td>

                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "total_sqmf"]}
                          fieldKey={[fieldKey, "total_sqmf"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            // onChange={handleChangee}
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Total Sqmf"
                          />
                        </Form.Item>
                      </td>
                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "amount"]}
                          fieldKey={[fieldKey, "amount"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            style={{
                              width: "100%",
                              float: "left",
                              // backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Amount"
                          />
                        </Form.Item>
                      </td>

                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "remarks1"]}
                          fieldKey={[fieldKey, "remarks1"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Remarks"
                          />
                        </Form.Item>
                      </td>

                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "item_group_cd"]}
                          fieldKey={[fieldKey, "item_group_cd"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            style={{
                              width: "100%",
                              float: "left",
                              backgroundColor: "white",
                              // color: "#1777C4",
                              // fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Item Group Code"
                          />
                        </Form.Item>
                      </td>

                      <td style={{ border: "1px solid white" }}>
                        <MinusCircleOutlined
                          className={classes["Remove"]}
                          onClick={() => {
                            remove(name);
                          }}
                        />

                        <PlusCircleOutlined
                          className={classes["Add"]}
                          onClick={(i) => {
                            console.log(i, "in add button");
                            setEdi(true); // Set props.setEditDode to false when the plus icon is clicked

                            add();
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  style={{ display: "none" }}
                  onClick={() => {
                    add();
                  }}
                >
                  Add Booking
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </Form>
  ) : (
    <>
      {props.data.map((data, index) => {
        return (
          <div key={index}>
            <p></p>
            <Row
              className={props.editMode ? classes["RowDEX"] : classes["RowD"]}
            >
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.ITEM_CODE}
                type="Select"
                name="Item"
                value={data.item_code}
              />
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.SIZE_CODE}
                type="Select"
                name="Size"
                value={data.size_code}
              />
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.UOM}
                type="Select"
                name="Uom"
                value={data.uom}
              />
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.QUALITY}
                type="Select"
                name="Grade"
                value={data.quality}
              />
              <DataField
                editMode={false}
                lg={12}
                md={24}
                name="Pcs"
                value={data.no_pcs}
              />
              <DataField
                editMode={false}
                lg={12}
                md={24}
                name="Qty"
                value={data.qty}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                name="Rate"
                value={data.bk_rate}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                options={props.ad.DISCOUNT_ON}
                type="Select"
                name="Discount On"
                value={data.discount_on}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                name="Discount value"
                value={data.discount_amount}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                options={props.ad.DIS_TYPE}
                type="Select"
                name="Discount Type"
                value={data.dis_type ? data.dis_type : null}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                name="Rate After Discount"
                value={data.net_rate}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                name="ORDER AMT"
                value={data.tot_item_amt}
              />

              <DataField
                editMode={false}
                lg={8}
                md={24}
                name="NET AMOUNT"
                value={data.net_size_rate}
              />
            </Row>
            <p></p>
          </div>
        );
      })}
    </>
  );
};

export default BookingSize;
