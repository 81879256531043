//import {Row} from 'antd';
import React from "react";
import {
  Row,
  Col,
  Form,
  Tabs,
  Input,
  Space,
  Button,
  Tooltip,
  DatePicker,
  Select,
  InputNumber,
  Modal,
  Skeleton,
} from "antd";
import { Link } from "react-router-dom";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import axios from "axios";
import dayjs from "dayjs";
import classes from "../Pages.module.css";
import DataField from "./DataField";
//import BoonkigSizeEdit from './BookingSizeEdit';
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState, useContext, useEffect } from "react";
import EmployeeTable from "../EmployeeTable/EmployeeTable";
import AdminTable from "./AdminTable";
import TaxDetail from "./TaxDetail";
import DataContext from "../../../Context/dataContext";
//import SalesNew from './SalesNew';
//import { useState, useEffect, useContext} from 'react';
const { Option } = Select;
const { TabPane } = Tabs;
const BookingSize = (props) => {
  const employeeData = useContext(DataContext);
  const [sizef, setSizef] = useState([]);
  const [gradef, setGradef] = useState([]);
  const [uom, setUom] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rowsedi, setRowsedi] = useState(null);
  const [columnsedi, setColumnsedi] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [sizeData, setSizeData] = useState(null);
  const [form] = Form.useForm(); // Create a form instance

  const showModal = () => {
    setIsModalVisible(true);
    // Place to call your API if needed
  };

  // Function to close modal
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      setRows(null);
      setColumns([]);
      //console.log("useEffect")
      axios
        .get(employeeData.URL + "/api/v1/salesinvoicelist/allpackageListing", {
          withCredentials: true,
        })
        .then((response) => {
          console.log(response);
          setColumns((columns) => {
            let newCols = response.data.data.order.fields.map((col) => {
              return {
                name: col.name,
                title: col.name.split("_").join(" ").toLowerCase(),
                editable: true,
              };
            });

            const newNewCols = [
              { name: "SNO", title: "SNo" },
              { name: "Select", title: "Select" },
              ...newCols,
            ];
            console.log(newNewCols);
            return newNewCols;
          });

          setRows((rows) => {
            let newRows = response.data.data.order.rows.map((row, index) => {
              return {
                SNO: index + 1,
                Select: (
                  <Tooltip
                    placement="bottom"
                    title="Select Planning Here"
                    color="#1777C4"
                  >
                    {/* <Link
                    to={"/sales/transaction/invoice-create"}
                    style={{ color: "#1777C4", fontWeight: "bolder" }}
                  > */}
                    <BsFillArrowRightSquareFill
                      onClick={(event) =>
                        deleteHandler(
                          event,
                          index,
                          row["packing_code"],
                          row["customer_code"],
                          row["truck_no"]
                        )
                      }
                      style={{
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "0.8rem",
                        id: "hhh",
                      }}
                    />
                  </Tooltip>
                ),
                ...row,
              };
            });
            return newRows;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isModalVisible === true]);

  const deleteHandler = (event, index, code, distributor, Vehicle) => {
    console.log(index);
    console.log(code);
    setSizeData(null);
    setIsModalVisible(false);

    axios
      .get(
        employeeData.URL +
          "/api/v1/salesinvoicelist/package-listing-item-detail/" +
          code +
          "/" +
          distributor,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        const { del_site_code, locality_code, city_code } =
          response.data.data.city.rows[0];
        const { booking_no } = response.data.data.custdetail.rows[0];
        setSizeData(response.data.data);

        axios
          .get(
            employeeData.URL +
              "/api/v1/salesinvoicelist/package-listing-order-detail/" +
              booking_no,
            {
              withCredentials: true,
            }
          )
          .then((secondResponse) => {
            console.log(secondResponse);
            const { order_type, type_code } =
              secondResponse.data.data.orderdetail.rows[0];

            props.setData((prevData) => {
              const newData = {
                ...prevData,
                bookingSize: prevData.bookingSize.map((item, idx) =>
                  idx === 0
                    ? {
                        ...item,
                        packing_no: code,
                        truck_number: Vehicle,
                        del_add: del_site_code,
                        locality_code: locality_code,
                        city_code: city_code,
                        distributor_code: distributor,
                        order_type: order_type,
                        invoice_type: type_code,
                      }
                    : item
                ),
              };

              // Update the form values
              form.setFieldsValue({
                bookingSize: newData.bookingSize,
              });

              return newData;
            });

            // Update the allData state
            // Update the allData state
            // props.setData((prevData) => {
            //   const newData = {
            //     ...prevData,
            //     bookingSize: prevData.bookingSize.map((item, idx) =>
            //       idx === 0
            //         ? {
            //             ...item,
            //             prod_code: planningCode,
            //             request_date: planningDate,
            //           }
            //         : item
            //     ),
            //   };

            //   // Update the form values
            //   form.setFieldsValue({
            //     bookingSize: newData.bookingSize,
            //   });

            //   return newData;
          });
      });
  };

  const handleValueChanges = (changedValues, allValues) => {
    props.setData((data) => {
      //  console.log(changedValues.bookingSize.length)
      return {
        ...data,
        bookingSize: allValues.bookingSize,
        bookingSize1: changedValues.bookingSize,
      };
    });
    // console.log( allValues.bookingSize)
    // console.log(changedValues.bookingSize)
    // console.log(changedValues.bookingSize.length)
  };

  return props.editMode ? (
    <Form
      form={form} // Attach the form instance
      layout="vertical"
      name="filter_form"
      onValuesChange={handleValueChanges}
      autoComplete="off"
    >
      <Form.List name="bookingSize" initialValue={props.data}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, i) => (
              <React.Fragment key={key}>
                {/* Dynamic form fields can be added here */}
                {/* Example: A text input for booking name */}
                <Row gutter={16}>
                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "packing_no"]}
                      fieldKey={[fieldKey, "packing_no"]}
                      rules={[{ required: true, message: "Missing Name" }]}
                      label={
                        <span style={{ fontWeight: "bold" }}>Packing No </span>
                      }
                    >
                      <Select
                        // onChange={handleChangee}
                        onClick={showModal} // Use onClick or onFocus based on your requirement
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        {/* {props.ad.MASKING_DESC.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.MASKING_DESC.fields[1].name]}
                              value={
                                option[props.ad.MASKING_DESC.fields[1].name]
                              }
                            >
                              {option[props.ad.MASKING_DESC.fields[0].name]}
                            </Option>
                          );
                        })} */}
                      </Select>
                    </Form.Item>

                    {/* Modal Component */}
                    <Modal
                      title="List Of Package Listing"
                      visible={isModalVisible}
                      // onOk={handleOk}
                      onCancel={handleCancel}
                      footer={null}
                      width={800} // Adjust the width as needed
                      style={{ maxHeight: "80vh" }} // Adjust the height as needed
                    >
                      {rows && columns.length > 0 ? (
                        <>
                          <EmployeeTable
                            data={rows}
                            columns={columns}
                            val={true}
                          />
                        </>
                      ) : (
                        <>
                          <Skeleton loading={false} />
                          <Skeleton loading={false} />
                          <Skeleton loading={false} />
                          <Skeleton loading={false} />
                        </>
                      )}
                      {/* Place for your API call result or any other content */}
                    </Modal>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "invoice_date"]}
                      fieldKey={[fieldKey, "invoice_date"]}
                      rules={[{ required: true, message: "Missing Date" }]}
                      label={
                        <span style={{ fontWeight: "bold" }}>
                          Invoice Date{" "}
                        </span>
                      }
                    >
                      <DatePicker
                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        format="DD-MM-YYYY"
                        bordered={true}
                        placeholder="Select Date"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "distributor_code"]}
                      fieldKey={[fieldKey, "distributor_code"]}
                      // rules={[{ required: true, message: "Missing Name" }]}
                      label={
                        <span style={{ fontWeight: "bold" }}>Customer</span>
                      }
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        // Adding a label here
                        placeholder="Select"
                        // onChange={(valu) => handleSelectChange(valu, i)}
                        optionFilterProp="children"
                      >
                        {props.ad.DISTRIBUTOR_CODE.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={
                                option[props.ad.DISTRIBUTOR_CODE.fields[0].name]
                              }
                              value={
                                option[props.ad.DISTRIBUTOR_CODE.fields[0].name]
                              }
                            >
                              {option[props.ad.DISTRIBUTOR_CODE.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "order_type"]}
                      fieldKey={[fieldKey, "order_type"]}
                      // rules={[{ required: true, message: "Missing Name" }]}
                      label={
                        <span style={{ fontWeight: "bold" }}>Order Type</span>
                      }
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        // Adding a label here
                        placeholder="Select"
                        // onChange={(valu) => handleSelectChange(valu, i)}
                        optionFilterProp="children"
                      >
                        {props.ad.ORDER_TYPE.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.ORDER_TYPE.fields[0].name]}
                              value={option[props.ad.ORDER_TYPE.fields[0].name]}
                            >
                              {option[props.ad.ORDER_TYPE.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "invoice_type"]}
                      fieldKey={[fieldKey, "invoice_type"]}
                      // rules={[{ required: true, message: "Missing Name" }]}
                      label={
                        <span style={{ fontWeight: "bold" }}>Invoice Type</span>
                      }
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        // Adding a label here
                        placeholder="Select"
                        // onChange={(valu) => handleSelectChange(valu, i)}
                        optionFilterProp="children"
                      >
                        {props.ad.INVOICE_TYPE_CODE.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={
                                option[
                                  props.ad.INVOICE_TYPE_CODE.fields[0].name
                                ]
                              }
                              value={
                                option[
                                  props.ad.INVOICE_TYPE_CODE.fields[0].name
                                ]
                              }
                            >
                              {
                                option[
                                  props.ad.INVOICE_TYPE_CODE.fields[1].name
                                ]
                              }
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "truck_number"]}
                      fieldKey={[fieldKey, "truck_number"]}
                      label={
                        <span style={{ fontWeight: "bold" }}>Vehicle No</span>
                      }
                      rules={[
                        {
                          //   required: true,
                          //message: 'Field should not be blank!!'
                        },
                        {
                          //   type: 'number',
                          // message: "please enter only numeric value"
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                          textAlign: "right",
                          backgroundColor: "white",
                          // color: "#1777C4",
                          // fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        // type="number"
                        placeholder="Enter Truck No"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "city_code"]}
                      fieldKey={[fieldKey, "city_code"]}
                      // rules={[{ required: true, message: "Missing Name" }]}
                      label={
                        <span style={{ fontWeight: "bold" }}>
                          Customer City
                        </span>
                      }
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        // Adding a label here
                        placeholder="Select"
                        // onChange={(valu) => handleSelectChange(valu, i)}
                        optionFilterProp="children"
                      >
                        {props.ad.CITY_CODE.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.CITY_CODE.fields[0].name]}
                              value={option[props.ad.CITY_CODE.fields[0].name]}
                            >
                              {option[props.ad.CITY_CODE.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "locality_code"]}
                      fieldKey={[fieldKey, "locality_code"]}
                      // rules={[{ required: true, message: "Missing Name" }]}
                      label={
                        <span style={{ fontWeight: "bold" }}>
                          Customer Locality
                        </span>
                      }
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        // Adding a label here
                        placeholder="Select"
                        // onChange={(valu) => handleSelectChange(valu, i)}
                        optionFilterProp="children"
                      >
                        {props.ad.LOCALITY_CODE.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={
                                option[props.ad.LOCALITY_CODE.fields[0].name]
                              }
                              value={
                                option[props.ad.LOCALITY_CODE.fields[0].name]
                              }
                            >
                              {option[props.ad.LOCALITY_CODE.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "del_add"]}
                      fieldKey={[fieldKey, "del_add"]}
                      // rules={[{ required: true, message: "Missing Name" }]}
                      label={
                        <span style={{ fontWeight: "bold" }}>Delivery Add</span>
                      }
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        // Adding a label here
                        placeholder="Select"
                        // onChange={(valu) => handleSelectChange(valu, i)}
                        optionFilterProp="children"
                      >
                        {props.ad.DEL_SITE_CODE.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={
                                option[props.ad.DEL_SITE_CODE.fields[0].name]
                              }
                              value={
                                option[props.ad.DEL_SITE_CODE.fields[0].name]
                              }
                            >
                              {option[props.ad.DEL_SITE_CODE.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24} className={classes["Col"]}>
                    <div>
                      {/* Other components or content */}
                      {sizeData !== null ? (
                        <Tabs
                          defaultActiveKey="0"
                          centered // Center the Tabs
                          type="line" // Set the type to "card"
                        >
                          <TabPane
                            tab={
                              <span
                                style={{ fontWeight: "bold", color: "000000" }}
                              >
                                Item Details
                              </span>
                            }
                            key="0"
                            style={{
                              // border: "none !important",
                              // border: "2px solid black",
                              marginTop: "-16px",
                            }}
                          >
                            <div
                              style={{
                                overflowX: "scroll",
                                // padding: "10px",

                                // border: "2px solid red",
                              }}
                            >
                              <AdminTable
                                sizeData={sizeData}
                                setMata={props.setData}
                                // data1={props.data1}
                                // data2={props.data2}
                              />
                            </div>
                          </TabPane>
                          <TabPane
                            tab={
                              <span
                                style={{ fontWeight: "bold", color: "000000" }}
                              >
                                Tax Detail
                              </span>
                            }
                            key="1"
                            style={{
                              // border: "none !important",
                              // border: "2px solid black",
                              marginTop: "-16px",
                            }}
                          >
                            <div
                              style={{
                                overflowX: "scroll",
                                // padding: "10px",

                                // border: "2px solid red",
                              }}
                            >
                              <TaxDetail
                                sizeData={sizeData}
                                setMata={props.setData}
                                // data1={props.data1}
                                // data2={props.data2}
                              />
                            </div>
                          </TabPane>
                        </Tabs>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            ))}
          </>
        )}
      </Form.List>
    </Form>
  ) : (
    <>
      {props.data.map((data, index) => {
        return (
          <div key={index}>
            <p></p>
            <Row
              className={props.editMode ? classes["RowDEX"] : classes["RowD"]}
            >
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.ITEM_CODE}
                type="Select"
                name="Item"
                value={data.item_code}
              />

              <DataField
                editMode={false}
                lg={8}
                md={24}
                name="NET AMOUNT"
                value={data.net_size_rate}
              />
            </Row>
            <p></p>
          </div>
        );
      })}
    </>
  );
};

export default BookingSize;
