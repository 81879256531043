import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message, notification } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import BasicDetails from "./BasicDetails";
import ContactDetails from "./ContactDetails";
import DeliveryDetails from "./DeliveryDetails";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

const identifiers = ["contactDetails", "deliveryDetails"];

const CustomerNew = (props) => {
  const employeeData = useContext(DataContext);
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [allData, setAllData] = useState({
    customerMaster: [
      {
        distributor_name: null,
        address: null,
        pan_no: null,
        s_tax_no: null,
        payment_days: null,
        sales_uniq: null,
        account_code: null,
        ecc_no: null,
        pin_no: null,
        cst_no: null,
        acc_group_code: null,
        ext_entity_type_code: null,
        city: null,
        locality_code: null,
      },
    ],

    contactDetails: [
      {
        contact_person: null,
        contact_no: null,
        email_id: null,
        department: null,
      },
    ],
    deliveryDetails: [
      {
        name: null,
        city_code: null,
        add_1: null,
        pin: null,
        locality_code: null,
        gst_number: null,
      },
    ],
  });
  const [ad, setAD] = useState(null);

  useEffect(() => {
    setEditMode(true);
    setAD(null);
    setLoading(false);
    {
      /*
        axios
        .get(employeeData.URL + '/api/v1/customerm/acc-group',{
            withCredentials: true
        })
        .then((response) => {

            console.log(response);
            setAD(ad => {
                let newad = response.data.data
                return ({
                    ...newad,
                  
                });
            })
        })
        .catch((error) => {
            console.log(error);
        })

    */
    }
    axios
      .get(employeeData.URL + "/api/v1/customerm/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setAD((ad) => {
          let newad = response.data.data;

          return {
            ...newad,
          };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSave = (event) => {
    console.log(allData);
    var formValid = true;
    const customerMasterData = allData.customerMaster[0];
    const emptyArrays = [];
    const invalidFields = [];

    // Check if any of the required fields in itemMaster is null
    if (
      !customerMasterData.distributor_name ||
      !customerMasterData.address ||
      !customerMasterData.acc_group_code ||
      !customerMasterData.s_tax_no ||
      !customerMasterData.city ||
      !customerMasterData.locality_code ||
      !customerMasterData.pin_no ||
      !customerMasterData.pan_no
    ) {
      notification.error({
        message: "Missing Required Fields",
        description:
          "Please fill in all the required fields in the Basic Details tab.",
        duration: 5,
      });
      formValid = false;
      return;
    }

    // Check if any of the arrays is empty or has invalid fields
    if (
      allData.contactDetails.length === 0 ||
      !allData.contactDetails[0].contact_person ||
      !allData.contactDetails[0].contact_no ||
      !allData.contactDetails[0].email_id ||
      !allData.contactDetails[0].department
    ) {
      emptyArrays.push("Contact Details");
      invalidFields.push("contact_person");
      invalidFields.push("contact_no");
      invalidFields.push("email_id");
      invalidFields.push("department");

      formValid = false;
    }
    if (
      allData.deliveryDetails.length === 0 ||
      !allData.deliveryDetails[0].name ||
      !allData.deliveryDetails[0].city_code ||
      !allData.deliveryDetails[0].add_1 ||
      !allData.deliveryDetails[0].pin ||
      !allData.deliveryDetails[0].locality_code ||
      !allData.deliveryDetails[0].gst_number
    ) {
      emptyArrays.push("Delivery Details");
      invalidFields.push("name");
      invalidFields.push("city_code");
      invalidFields.push("add_1");
      invalidFields.push("pin");
      invalidFields.push("locality_code");
      invalidFields.push("gst_number");
      formValid = false;
    }

    if (!formValid) {
      const message = `Please fill in at least one value for ${emptyArrays.join(
        ", "
      )} and ensure that the following fields are not empty: ${invalidFields.join(
        ", "
      )}.`;
      notification.error({
        message: "Incomplete Data",
        description: message,
        duration: 5,
      });
      setLoading(false);
      return;
    }

    if (formValid) {
      setLoading(true);
      const postData = {
        ...allData,
        contactDetails: allData.contactDetails.map((dep) => {
          return {
            ...dep,
          };
        }),
        deliveryDetails: allData.deliveryDetails.map((dep) => {
          return {
            ...dep,
          };
        }),
      };

      console.log(postData);

      axios
        .post(employeeData.URL + "/api/v1/customerm", postData, {
          withCredentials: true,
          credentials: "include",
        })
        .then((response) => {
          if (response.data.status === "fail") {
            message.error({
              content: response.data.message,
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });

            setLoading(false);
          } else {
            message.success({
              content: "customer master Created Successfully!!!",
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });
            setLoading(false);
            history("/sales/customerMaster/customer-details");
          }
        })
        .catch((err) => {
          message.error({
            content: "An Error Occurred!!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
        });
    }
  };

  const handleCancel = (event) => {
    setLoading(false);
    history.replace("/sales/customerMaster/customer-details");
  };

  return (
    <>
      {ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes["Col"]}>
            <div className={classes["EditView"]}>
              <Tabs
                defaultActiveKey="0"
                centered
                style={{ height: "100%" }}
                tabPosition={"left"}
                type="line"
                tabBarGutter="0"
              >
                <TabPane tab={<span>Basic Details</span>} key="0">
                  <BasicDetails
                    create={true}
                    ad={ad}
                    setData={setAllData}
                    data={allData.customerMaster[0]}
                    editMode={editMode}
                  />
                </TabPane>
                <TabPane tab={<span>Contact Details</span>} key="1">
                  <ContactDetails
                    ad={ad}
                    setData={setAllData}
                    data={allData.contactDetails}
                    editMode={editMode}
                  />
                </TabPane>
                <TabPane tab={<span>Delivery Details</span>} key="2">
                  <DeliveryDetails
                    ad={ad}
                    setData={setAllData}
                    data={allData.deliveryDetails}
                    editMode={editMode}
                  />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default CustomerNew;
