import { Row } from "antd";
import classes from "../Pages.module.css";
import DataField from "./DataField";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";

const BasicDetails = (props) => {
  const employeeData = useContext(DataContext);
  const [localityy, setLocalityy] = useState(null);
  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["customerMaster"]];
      newdata[0][param] = e.target.value;
      return {
        ...data,
        customerMaster: newdata,
      };
    });
  };

  const handleNChange = (value, param) => {
    props.setData((data) => {
      const newdata = [...data["customerMaster"]];
      newdata[0][param] = value; // Use the 'value' directly
      return {
        ...data,
        customerMaster: newdata,
      };
    });
  };

  const handleSChange = (val, param) => {
    props.setData((data) => {
      const newdata = [...data["customerMaster"]];
      if (param == "city") {
        console.log(val);
        // Set previous values to null when the city changes
        newdata[0]["locality_code"] = null;

        axios
          .get(
            employeeData.URL + "/api/v1/customerm/additional-data-city/" + val,
            {
              withCredentials: true,
            }
          )

          .then((response) => {
            console.log(response);
            // console.log(response.data.data.custdetail.rows[0].add_1);

            const locality = response.data.data.locality.rows;
            // const count = response.data.data.city.rows;
            setLocalityy(locality);
            // setCount(count);
          });
      }
      newdata[0][param] = val;
      return {
        ...data,
        customerMaster: newdata,
      };
    });
  };
  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["customerMaster"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        customerMaster: newdata,
      };
    });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            name="CUSTOMER CODE"
            param="distributor_code"
            value={props.data.distributor_code}
          />
        )}
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Customer Name"
          id="customer_name"
          param="distributor_name"
          required="True"
          value={props.data.distributor_name}
        />
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.ACC_GROUP_CODE} type = "Select" id="debitor_group" name = "DEBITOR GROUP" param = "acc_group_code" value = {props.data.acc_group_code}/>   */}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.EXT_ENTITY_TYPE_CODE } type = "Select" id="customer_type" required="True" name = "CUSTOMER TYPE" param = "ext_entity_type_code" value = {props.data.ext_entity_type_code}/>  */}

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleSChange}
          options={props.ad.ACC_GROUP_CODE}
          type="Select"
          id="acc_group_code"
          name="Debitor Group"
          param="acc_group_code"
          required="True"
          value={props.data.acc_group_code}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Address"
          id="address"
          param="address"
          required="True"
          value={props.data.address}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleNChange}
          name="Pin"
          type="InputNumber"
          param="pin_no"
          id="pin_no"
          required="True"
          value={props.data.pin_no}
          // numberVal={props.onlyNumber}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Gst No"
          id="s_tax_no"
          param="s_tax_no"
          required="True"
          maxLength={15}
          value={props.data.s_tax_no}
          validationRules={[
            {
              required: true,
              message: "Field should not be blank!!",
            },
            {
              pattern: /^[A-Z0-9]{15}$/,
              message: "Must be 15 capital letters/numbers",
              validator: (_, value) =>
                value.length === 15 ? Promise.resolve() : Promise.reject(),
            },
          ]}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleSChange}
          options={props.ad.CITY}
          type="Select"
          id="city"
          name="City"
          param="city"
          required="True"
          value={props.data.city}
        />

        {/* <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleSChange}
          options={props.ad.CITY}
          type="Select"
          id="city"
          name="State"
          param="city"
          required="True"
          value={props.data.city}
        /> */}
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleSChange}
          options={props.ad.LOCALITY_CODE}
          local={props.editMode1 ? props.localityy : localityy}
          type="Select"
          id="locality"
          name="Locality"
          param="locality_code"
          required="True"
          value={props.data.locality_code}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleSChange}
          options={props.ad.REPRESENT_CODE}
          type="Select"
          name="Sales Represent"
          param="sales_uniq"
          required="True"
          value={props.data.sales_uniq}
        />
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "HSN NO" param = "hsn" value = {props.data.hsn}/>  */}

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Pan No"
          id="pan_no"
          required="True"
          param="pan_no"
          maxLength={10}
          value={props.data.pan_no}
          validationRules={[
            {
              type: "string",
              pattern: /^[A-Za-z0-9]{10}$/,
              message: "PAN number must be 10 alphanumeric characters.",
            },
            {
              required: true,
              message: "Please enter PAN number.",
            },
          ]}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleNChange}
          name="Payement Days"
          type="InputNumber"
          id="payment_days"
          param="payment_days"
          value={props.data.payment_days}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleNChange}
          name="Ecc No"
          type="InputNumber"
          param="ecc_no"
          id="ecc_no"
          value={props.data.ecc_no}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Cst Number"
          id="cst_number"
          param="cst_no"
          value={props.data.cst_no}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleNChange}
          name="Account Code"
          param="account_code"
          type="InputNumber"
          id="account_code"
          value={props.data.account_code}
        />
      </Row>
    </div>
  );
};

export default BasicDetails;
