import { Row } from "antd";
import classes from "../Pages.module.css";
import DataField from "./DataField";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import DataContext from "../../../Context/dataContext";

const BasicDetails = (props) => {
  const employeeData = useContext(DataContext);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [addres, setAddres] = useState(null);
  const [count, setCount] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [deliverySiteOptions, setDeliverySiteOptions] = useState(null);

  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["salesOrder"]];
      newdata[0][param] = e.target.value;
      //  console.log(newdata)
      return {
        ...data,
        salesOrder: newdata,
      };
    });
  };

  const handleSChange = (val, param) => {
    props.setData((data) => {
      const newdata = [...data["salesOrder"]];
      // if (param === "dealer_name") {
      //   setSelectedDealer(val);

      //   // Make API call to fetch customer list based on dealer_code
      //   axios
      //     .get(
      //       employeeData.URL +
      //         "/api/v1/salesInvoice/additional-data-of-customer/" +
      //         val,
      //       {
      //         withCredentials: true,
      //       }
      //     )
      //     .then((response) => {
      //       console.log(response);
      //       const customer = response.data.data.customer.rows; // Replace with the actual response structure
      //       setCustomers(customer);
      //       // Update state with the new customer list
      //     })
      //     .catch((error) => {
      //       console.error("Error fetching customer list:", error);
      //     });
      // }

      if (param == "distributor_code") {
        setSelectedDealer(val);
        console.log(val);
        console.log(props);
        const dis = props["data"];
        console.log(dis);
        console.log(dis["distributor_code"]);
        axios
          .get(
            employeeData.URL +
              "/api/v1/salesOrder/additional-data-of-cust/" +
              val,
            {
              withCredentials: true,
            }
          )

          .then((response) => {
            console.log(response);
            console.log(response.data.data.custdetail.rows[0].add_1);
            // const deliverySiteOptions = [];
            const add = response.data.data.custdetail.rows;
            const count = response.data.data.city.rows;
            setAddres(add);
            setCount(count);
          });
      }

      newdata[0][param] = val;
      return {
        ...data,
        salesOrder: newdata,
      };
    });
  };

  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["salesOrder"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        salesOrder: newdata,
      };
    });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            name="CUSTOMER CODE"
            param="order_code"
            value={props.data.order_code}
          />
        )}

        {/* <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          options={props.ad.DEALER_NAME}
          type="Select"
          name="Dealer Name"
          param="dealer_name"
          value={props.data.dealer_name}
        /> */}
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.DISTRIBUTOR_CODE}
          type="Select"
          required="True"
          name="Customer name"
          param="distributor_code"
          customers={props.editMode1 ? props.customers : customers}
          value={props.data.distributor_code}
        />
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.ORDER_TYPE} type = "Select" name = "ORDER TYPE" param = "order_type" value = {props.data.order_type}/>   */}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.INVOICE_TYPE_CODE} type = "Select" name = "INVOICE TYPE" param = "invoice_type_code" value = {props.data.invoice_type_code}/>  */}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PAYMENT DAYS" param = "payment_days" value = {props.data.payment_days}/>    */}
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.DEL_SITE_CODE}
          type="Select"
          name="Delivery Site"
          param="del_site_code"
          // addres={addres}
          addres={props.editMode1 ? props.addres1 : addres}
          required="True"
          value={props.data.del_site_code}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="Ship To"
          type="str"
          param="ship_to"
          value={props.data.ship_to}
        />
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.AUTH_STATUS} type = "Select" name = "AUTH STATUS" param = "auth_status" value = {props.data.auth_status}/>   */}
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleDChange}
          name="Order date"
          required="True"
          type="Date"
          param="booking_date"
          value={props.data.booking_date}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.ORDER_TYPE}
          type="Select"
          name="Order Type"
          required="True"
          param="order_type"
          value={props.data.order_type}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.FREIGHT_TYPE_CODE}
          type="Select"
          name="Freight Type"
          param="freight_type_code"
          value={props.data.freight_type_code}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.INVOICE_TYPE_CODE}
          type="Select"
          name="Deal Type"
          required="True"
          param="invoice_type_code"
          value={props.data.invoice_type_code}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="Sales Representative"
          type="str"
          required="True"
          param="sale_representative_code"
          value={props.data.sale_representative_code}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="Remarks"
          type="str"
          param="remarks"
          value={props.data.remarks}
        />
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleDChange} name = "CUSTOMER PO DATE" type="Date" param = "customer_po_date" value = {props.data.customer_po_date}/>   */}
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="Transporter Number"
          type="str"
          param="transporter_no"
          value={props.data.transporter_no}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.COUNTRY_CODE}
          type="Select"
          name="Country"
          param="country_code"
          // count={count}
          count={props.editMode1 ? props.count1 : count}
          value={props.data.country_code}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.CUR_CODE}
          type="Select"
          name="Currency"
          param="cur_code"
          value={props.data.cur_code}
        />
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.PAYMENT_CODE} type = "Select" name = "PAYMENT" param = "payment_code" value = {props.data.payment_code}/>  */}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "TOLERANCE"  param = "tolerance" value = {props.data.tolerance}/>   */}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PAYMENT AMOUNT"  param = "payment_amt" value = {props.data.payment_amt}/>   */}
      </Row>
    </div>
  );
};

export default BasicDetails;
