import classes from "./SiderContentC.module.css";
import { Menu, message } from "antd";
import {
  FaSignOutAlt,
  FaFileInvoice,
  FaReceipt,
  FaHandsHelping,
  FaMoneyCheckAlt,
} from "react-icons/fa";
import signInSignUpLogo from "../../../assets/signInSignUp.png";
import { MdDashboard } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { GiSellCard } from "react-icons/gi";
import { GiBuyCard } from "react-icons/gi";
import { AiOutlineDownSquare } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MdWatchLater, MdAssignmentTurnedIn } from "react-icons/md";
import { BsFillGrid3X3GapFill, BsFillPeopleFill } from "react-icons/bs";
import { AiFillFileMarkdown, AiFillPropertySafety } from "react-icons/ai";
import { GiRuleBook } from "react-icons/gi";
import { AiTwotoneRest, AiOutlineTeam } from "react-icons/ai";
import {
  FaPaintRoller,
  FaBalanceScale,
  FaPalette,
  FaMask,
} from "react-icons/fa"; // Example icons

const { SubMenu } = Menu;

const SiderContentS = (props) => {
  const history = useNavigate();

  const handleLogout = (event) => {
    axios
      .get(props.url + "/api/v1/users/logout", {
        withCredentials: true,
      })
      .then((response) => {
        message.success({
          content: "Goodbye!!!! See You Again!!!",
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        history("/acrylic");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <img
        className={classes["SignInSignUpLogo"]}
        src={signInSignUpLogo}
        alt="Logo"
      />
      <Menu
        mode="inline"
        defaultSelectedKeys={["empDashboard"]}
        // defaultOpenKeys={["misc"]}
        style={{
          height: "100%",
          borderRight: 0,
          overflowY: "auto",
        }}
        theme="light"
      >
        <Menu.Item key="empDashboard" icon={<MdDashboard />}>
          <Link to="/sales/dashboard" style={{ textDecoration: "none" }}>
            Dashboard
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="custInvoices" icon={<FaFileInvoice />}>
          <Link
            to="/sales/invoices-register"
            style={{ textDecoration: "none" }}
          >
            Reports
          </Link>
        </Menu.Item> */}

        <SubMenu
          key="AllReport"
          title="Reports"
          icon={<FaFileInvoice />}
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            width: "auto",
          }}
        >
          <Menu.Item key="allreports">
            <Link
              to="/sales/transaction/production-register"
              style={{ textDecoration: "none" }}
            >
              Daily Production Register
            </Link>
          </Menu.Item>

          <Menu.Item key="pendingApproval">
            <Link
              to="/sales/transaction/pending-for-approval"
              style={{ textDecoration: "none" }}
            >
              Pending for approval
            </Link>
          </Menu.Item>
          <Menu.Item key="packingActual">
            <Link
              to="/sales/transaction/actual-order-details"
              style={{ textDecoration: "none" }}
            >
              Actual Order Status
            </Link>
          </Menu.Item>
          <Menu.Item key="packingconsolidate">
            <Link
              to="/sales/transaction/consolidate-packing-details"
              style={{ textDecoration: "none" }}
            >
              Consolidate Packing List Report
            </Link>
          </Menu.Item>

          <Menu.Item key="orderTermi">
            <Link
              to="/sales/transaction/order-termination-details"
              style={{ textDecoration: "none" }}
            >
              Order Termination Report
            </Link>
          </Menu.Item>
        </SubMenu>

        {/* <Menu.Item key="custOrder" icon={<FaHandsHelping />}>
          <Link to="/sales/order-register" style={{ textDecoration: "none" }}>
            Order Register
          </Link>
        </Menu.Item> */}

        {/* <Menu.Item key="custPendingSales" icon={<GiSellCard />}>
          <Link
            to="/sales/pending-sales-register"
            style={{ textDecoration: "none" }}
          >
            Pending Sales Register
          </Link>
        </Menu.Item> */}

        <SubMenu
          key="ProductionMaster"
          title="Production Master"
          icon={<GiSellCard />}
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            width: "auto",
          }}
        >
          <Menu.Item key="productionPlanning">
            <Link
              to="/sales/transaction/production-details"
              style={{ textDecoration: "none" }}
            >
              Production Planning
            </Link>
          </Menu.Item>

          <Menu.Item key="dailyProduction">
            <Link
              to="/sales/transaction/daily-production-details"
              style={{ textDecoration: "none" }}
            >
              Daily Production
            </Link>
          </Menu.Item>
          <Menu.Item key="packingList">
            <Link
              to="/sales/transaction/packing-list-details"
              style={{ textDecoration: "none" }}
            >
              Packing List
            </Link>
          </Menu.Item>

          <Menu.Item key="invoiceList">
            <Link
              to="/sales/transaction/invoice-list-details"
              style={{ textDecoration: "none" }}
            >
              Invoice
            </Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="StockMaster"
          title="Stock Master"
          icon={<FaReceipt />}
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            width: "auto",
          }}
        >
          <Menu.Item key="openningDetails">
            <Link
              to="/sales/transaction/openning-details"
              style={{ textDecoration: "none" }}
            >
              Opening Balance
            </Link>
          </Menu.Item>

          <Menu.Item key="stockStatus">
            <Link
              to="/sales/transaction/stock-create"
              style={{ textDecoration: "none" }}
            >
              Stock Status
            </Link>
          </Menu.Item>

          <Menu.Item key="dispatchDetails">
            <Link
              to="/sales/transection/dispatch-order"
              style={{ textDecoration: "none" }}
            >
              Dispatch Planning
            </Link>
          </Menu.Item>
        </SubMenu>

        {/* <Menu.Item key="custledger" icon={<FaReceipt />}>
          <Link to="/sales/ledger" style={{ textDecoration: "none" }}>
            Ledger
          </Link>
        </Menu.Item> */}

        <SubMenu
          key="GeographicalMaster"
          title="Geographical Master"
          icon={<AiTwotoneRest />}
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            width: "auto",
          }}
        >
          <Menu.Item key="customerMaster">
            <Link
              to="/sales/customerMaster/customer-details"
              style={{ textDecoration: "none" }}
            >
              Customer Master
            </Link>
          </Menu.Item>
          <Menu.Item key="countryMaster">
            <Link
              to="/sales/countryMaster/country-details"
              style={{ textDecoration: "none" }}
            >
              Country Master
            </Link>
          </Menu.Item>

          <Menu.Item key="stateMaster">
            <Link
              to="/sales/stateMaster/state-details"
              style={{ textDecoration: "none" }}
            >
              State Master
            </Link>
          </Menu.Item>
          <Menu.Item key="cityMaster">
            <Link
              to="/sales/cityMaster/city-details"
              style={{ textDecoration: "none" }}
            >
              City Master
            </Link>
          </Menu.Item>
          <Menu.Item key="localityMaster">
            <Link
              to="/sales/localityMaster/locality-details"
              style={{ textDecoration: "none" }}
            >
              Locality Master
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="ItemMaster"
          title="Item Master"
          icon={<FaPaintRoller />}
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            width: "auto",
          }}
        >
          <Menu.Item key="itemDetails">
            <Link
              to="/sales/itemMaster/item-details"
              style={{ textDecoration: "none" }}
            >
              Item Details
            </Link>
          </Menu.Item>

          <Menu.Item key="uomMaster">
            <Link
              to="/sales/uomMaster/uom-details"
              style={{ textDecoration: "none" }}
            >
              UOM Master
            </Link>
          </Menu.Item>
          <Menu.Item key="textureMaster">
            <Link
              to="/sales/texture/texture-details"
              style={{ textDecoration: "none" }}
            >
              Texture Master
            </Link>
          </Menu.Item>
          <Menu.Item key="colorMaster">
            <Link
              to="/sales/color/color-details"
              style={{ textDecoration: "none" }}
            >
              Color Master
            </Link>
          </Menu.Item>
          <Menu.Item key="maskingMaster">
            <Link
              to="/sales/masking/masking-details"
              style={{ textDecoration: "none" }}
            >
              Masking Master
            </Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu key="tax" title="Tax" icon={<GiRuleBook />}>
          {/* <Menu.Item key="DiscountNorms" >
                        <NavLink to = '/sales/norms/leave-norms'>
                            Discount Norms
                        </NavLink>
                    </Menu.Item> 
         <Menu.Item key="GaugeNorms">
            <Link
              to="/sales/norms/gauge-detail"
              style={{ textDecoration: "none" }}
            >
              Gauge Policy
            </Link>
          </Menu.Item>  */}
          <Menu.Item key="itemWiseTax">
            <Link
              to="/sales/tax/tax-details"
              style={{ textDecoration: "none" }}
            >
              Item Wise Tax
            </Link>
          </Menu.Item>

          <Menu.Item key="orderTax">
            <Link
              to="/sales/order/order-action"
              style={{ textDecoration: "none" }}
            >
              Order Termination
            </Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="transactions"
          title="Transactions"
          icon={<MdAssignmentTurnedIn />}
        >
          <Menu.Item key="SalesRep">
            {/* <Link
              to="/sales/transaction/salesForm"
              style={{ textDecoration: "none" }}
            >
              Sales Contract
            </Link> */}
            <Link
              to="/sales/master/salesrepresent"
              style={{ textDecoration: "none" }}
            >
              Sales Representative
            </Link>
          </Menu.Item>
          <Menu.Item key="Salesform">
            {/* <Link
              to="/sales/transaction/salesForm"
              style={{ textDecoration: "none" }}
            >
              Sales Contract
            </Link> */}
            <Link
              to="/sales/master/authorizedlevel"
              style={{ textDecoration: "none" }}
            >
              Authorized Level
            </Link>
          </Menu.Item>
          <Menu.Item key="SalesOrder">
            <Link
              to="/sales/transaction/salesOrder"
              style={{ textDecoration: "none" }}
            >
              Sales Order Form
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="SalesInvoice">
            <Link
              to="/sales/transaction/salesInvoice"
              style={{ textDecoration: "none" }}
            >
              Sales Invoice
            </Link>
          </Menu.Item> */}

          <Menu.Item key="AuthorisedApproved">
            <Link
              to="/sales/transaction/AuthorisedApproved"
              style={{ textDecoration: "none" }}
            >
              Authorize Level List
            </Link>
          </Menu.Item>
          <Menu.Item key="Voucher">
            <Link
              to="/sales/transaction/Voucher"
              style={{ textDecoration: "none" }}
            >
              Voucher
            </Link>
          </Menu.Item>
        </SubMenu>

        {/* <SubMenu
          key="misc"
          title="Miscelleneous"
          icon={<BsFillGrid3X3GapFill />}
        >
          {props.miscList && props.miscList.length > 0 ? (
            <>
              {props.miscList.map((misc) => {
                return (
                  <Menu.Item key={misc.table_code}>
                    <Link
                      to={"/sales/misc/" + misc.slug}
                      style={{ textDecoration: "none" }}
                    >
                      {misc.form_name}
                    </Link>
                  </Menu.Item>
                );
              })}
            </>
          ) : null}
        </SubMenu> */}

        <Menu.Item
          key="empLogout"
          onClick={(event) => handleLogout(event)}
          icon={<FaSignOutAlt style={{ color: "red", opacity: "0.7" }} />}
        >
          <Link to="/customer-portal" style={{ textDecoration: "none" }}>
            Logout
          </Link>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default SiderContentS;
