//import {Row} from 'antd';
import React from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Space,
  Button,
  DatePicker,
  Select,
  InputNumber,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import classes from "../Pages.module.css";
import DataField from "./DataField";
import AdminTable from "./AdminTable";
//import BoonkigSizeEdit from './BookingSizeEdit';
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState, useContext, useEffect } from "react";
import DataContext from "../../../Context/dataContext";
//import SalesNew from './SalesNew';
//import { useState, useEffect, useContext} from 'react';
const { Option } = Select;

const BookingSize = (props) => {
  const employeeData = useContext(DataContext);
  const [sizef, setSizef] = useState([]);
  const [gradef, setGradef] = useState([]);
  const [uom, setUom] = useState([]);
  const [sizeData, setSizeData] = useState(null);

  const handleButtonClick = () => {
    setSizeData(null);
    // console.log(props.data[0].stock_type);

    // Call your API using axios
    axios
      .get(
        `${employeeData.URL}/api/v1/dispatch/alldispatch/${props.data[0].item_code}/${props.data[0].texture_code}/${props.data[0].color_code}/${props.data[0].width}/${props.data[0].length}/${props.data[0].thickness}/${props.data[0].masking_code}`,
        {
          withCredentials: true,
        }
      )

      .then((response) => {
        console.log(response);
        setSizeData(response.data.data); // Update state with response data
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching data:", error);
      });
  };

  const handleChangee = (changedValues, allValues) => {
    props.setData((data) => {
      //  let newDetails = data.changedValues
      //  console.log(newDetails)
      //  console.log(changedValues.bookingSize.length)
      console.log("ytu");
      console.log(data);
      console.log(data.bookingSize1.length);
      var i = data.bookingSize.length;
      var j = data.bookingSize1.length;
      // console.log(changedValues.bookingSize.length)
      console.log(j);
      if (data.bookingSize.length == data.bookingSize1.length) {
        if (
          data.bookingSize[i - 1].discount_on == "r" &&
          data.bookingSize[i - 1].dis_type == "p"
        ) {
          const Totvalue =
            (data.bookingSize[i - 1].bk_rate *
              data.bookingSize[i - 1].discount_amount) /
            100;

          const ForderRate = data.bookingSize[i - 1].bk_rate - Totvalue;
          data.bookingSize[i - 1].booking_rate = ForderRate;
          data.bookingSize[i - 1].tot_item_amt =
            data.bookingSize[i - 1].qty * ForderRate;
        } else if (
          !data.bookingSize[i - 1].discount_on &&
          !data.bookingSize[i - 1].dis_type
        ) {
          data.bookingSize[i - 1].tot_item_amt =
            data.bookingSize[i - 1].qty * data.bookingSize[i - 1].bk_rate;
          // document.getElementById("qty").value = data.invoiceSize[i-1].qty;
        } else if (
          data.bookingSize[i - 1].discount_on == "r" &&
          data.bookingSize[i - 1].dis_type == "a"
        ) {
          const Totvalue1 =
            data.bookingSize[i - 1].bk_rate -
            data.bookingSize[i - 1].discount_amount;
          data.bookingSize[i - 1].booking_rate = Totvalue1;
          const Final1 = data.bookingSize[i - 1].qty * Totvalue1;
          data.bookingSize[i - 1].tot_item_amt = Final1;
        } else if (
          data.bookingSize[i - 1].discount_on == "ta" &&
          data.bookingSize[i - 1].dis_type == "p"
        ) {
          data.bookingSize[i - 1].booking_rate =
            data.bookingSize[i - 1].bk_rate;
          const Totvalue =
            (data.bookingSize[i - 1].bk_rate *
              data.bookingSize[i - 1].discount_amount *
              data.bookingSize[0].qty) /
            100;

          const ForderRate =
            data.bookingSize[i - 1].bk_rate * data.bookingSize[i - 1].qty -
            Totvalue;

          data.bookingSize[i - 1].tot_item_amt = ForderRate;
        } else if (
          data.bookingSize[i - 1].discount_on == "ta" &&
          data.bookingSize[i - 1].dis_type == "a"
        ) {
          data.bookingSize[i - 1].booking_rate =
            data.bookingSize[i - 1].bk_rate;
          const Totvalue1 =
            data.bookingSize[i - 1].bk_rate * data.bookingSize[i - 1].qty -
            data.bookingSize[i - 1].discount_amount;
          data.bookingSize[i - 1].tot_item_amt = Totvalue1;
        }
      } else {
        if (
          data.bookingSize[j - 1].discount_on == "r" &&
          data.bookingSize[j - 1].dis_type == "p"
        ) {
          const Totvalue =
            (data.bookingSize[j - 1].bk_rate *
              data.bookingSize[j - 1].discount_amount) /
            100;

          const ForderRate = data.bookingSize[j - 1].bk_rate - Totvalue;
          data.bookingSize[j - 1].booking_rate = ForderRate;
          data.bookingSize[j - 1].tot_item_amt =
            data.bookingSize[j - 1].qty * ForderRate;
        } else if (
          data.bookingSize[j - 1].discount_on == "r" &&
          data.bookingSize[j - 1].dis_type == "a"
        ) {
          const Totvalue1 =
            data.bookingSize[j - 1].bk_rate -
            data.bookingSize[j - 1].discount_amount;
          data.bookingSize[j - 1].booking_rate = Totvalue1;
          const Final1 = data.bookingSize[j - 1].qty * Totvalue1;
          data.bookingSize[j - 1].tot_item_amt = Final1;
        } else if (
          !data.bookingSize[j - 1].discount_on &&
          !data.bookingSize[j - 1].dis_type
        ) {
          data.bookingSize[j - 1].tot_item_amt =
            data.bookingSize[j - 1].qty * data.bookingSize[j - 1].bk_rate;
          // document.getElementById("qty").value = data.invoiceSize[i-1].qty;
        } else if (
          data.bookingSize[j - 1].discount_on == "ta" &&
          data.bookingSize[j - 1].dis_type == "p"
        ) {
          data.bookingSize[j - 1].booking_rate =
            data.bookingSize[j - 1].bk_rate;
          const Totvalue =
            (data.bookingSize[j - 1].bk_rate *
              data.bookingSize[j - 1].discount_amount *
              data.bookingSize[0].qty) /
            100;

          const ForderRate =
            data.bookingSize[j - 1].bk_rate * data.bookingSize[j - 1].qty -
            Totvalue;

          data.bookingSize[j - 1].tot_item_amt = ForderRate;
        } else if (
          data.bookingSize[j - 1].discount_on == "ta" &&
          data.bookingSize[j - 1].dis_type == "a"
        ) {
          data.bookingSize[j - 1].booking_rate =
            data.bookingSize[j - 1].bk_rate;
          const Totvalue1 =
            data.bookingSize[i - 1].bk_rate * data.bookingSize[j - 1].qty -
            data.bookingSize[j - 1].discount_amount;
          data.bookingSize[j - 1].tot_item_amt = Totvalue1;
        }
      }

      //for second item if we add

      // if (data.bookingSize[1].discount_on == 'r' && data.bookingSize[1].dis_type =='p') {

      //     const Totvalue = data.bookingSize[1].net_rate * data.bookingSize[1].discount_amount / 100;

      //     const ForderRate = data.bookingSize[1].net_rate - Totvalue

      //     data.bookingSize[1].net_size_rate = data.bookingSize[1].qty * ForderRate
      // }

      // else if (data.bookingSize[1].discount_on == 'r' && data.bookingSize[1].dis_type =='a') {
      //     const Totvalue1 = data.bookingSize[1].net_rate - data.bookingSize[1].discount_amount;
      //     const Final1 = data.bookingSize[1].qty * Totvalue1
      //     data.bookingSize[1].net_size_rate = Final1
      // }

      // else if (data.bookingSize[1].discount_on == 'ta' && data.bookingSize[1].dis_type =='p') {
      //     const Totvalue = data.bookingSize[1].net_rate * data.bookingSize[1].discount_amount*data.bookingSize[1].qty / 100;

      //     const ForderRate = data.bookingSize[1].net_rate*data.bookingSize[1].qty - Totvalue

      //     data.bookingSize[1].net_size_rate =  ForderRate
      // }

      // else if (data.bookingSize[1].discount_on == 'ta' && data.bookingSize[1].dis_type =='a') {
      //     const Totvalue1 = data.bookingSize[1].net_rate * data.bookingSize[1].qty- data.bookingSize[1].discount_amount;
      //     data.bookingSize[1].net_size_rate = Totvalue1
      // }
      return {
        ...data,
        //   bookingSize: changedValues.bookingSize
      };
    });

    //  console.log( allValues.bookingSize)
  };

  const handleChangee2 = (changedValues, allValues, i) => {
    props.setData((data) => {
      console.log("ytu");
      console.log(data);
      console.log(i);
      console.log(data.bookingSize[i].length);
      const { length, width, thickness, item_code, texture_code } =
        data.bookingSize[i];

      // Construct the URL with query parameters
      const url = `${employeeData.URL}/api/v1/salesOrder/level/?length=${length}&width=${width}&thickness=${thickness}&item_code=${item_code}&texture_code=${texture_code}`;

      // Make the Axios GET request
      axios
        .get(url, {
          withCredentials: true, // Optionally include additional configuration options
        })
        .then((response) => {
          console.log(response);
          // Split the response data by commas
          const values = response.data.data.size.split(",");
          console.log(values, "size333333333333333333333333333");
          // Assign the values to the respective properties
          const newData = { ...data };
          newData.bookingSize[i].sheet_area = parseFloat(values[2]);
          newData.bookingSize[i].per_sqm = parseFloat(values[3]);
          newData.bookingSize[i].sheet_per_roll = parseFloat(values[4]);

          return newData;
        });

      return {
        ...data,
      };
    });
  };

  const handleChangee3 = (changedValues, allValues, i) => {
    props.setData((data) => {
      console.log("ytu");
      console.log(data);
      console.log(i);
      console.log(data.bookingSize[i].length);
      const { length, billing_uom, no_pcs, item_code, rate } =
        data.bookingSize[i];

      // Construct the URL with query parameters
      const url = `${employeeData.URL}/api/v1/salesOrder/amount/?length=${length}&billing_uom=${billing_uom}&no_pcs=${no_pcs}&item_code=${item_code}&rate=${rate}`;

      // Make the Axios GET request
      axios
        .get(url, {
          withCredentials: true, // Optionally include additional configuration options
        })
        .then((response) => {
          console.log(response);
          // Split the response data by commas
          const values = response.data.data.size;
          console.log(values, "size333333333333333333333333333");
          // Assign the values to the respective properties
          const newData = { ...data };
          newData.bookingSize[i].amount = parseFloat(values);
          // newData.bookingSize[i].per_sqm = parseFloat(values[3]);
          // newData.bookingSize[i].sheet_per_roll = parseFloat(values[4]);

          return newData;
        });

      return {
        ...data,
      };
    });
  };

  const handleChangee4 = (changedValues, allValues, i) => {
    props.setData((data) => {
      console.log("ytu");
      console.log(data);
      console.log(i);
      console.log(data.bookingSize[i].length);
      const { length, billing_uom, no_pcs, item_code, width, thickness } =
        data.bookingSize[i];

      // Construct the URL with query parameters
      const url = `${employeeData.URL}/api/v1/salesOrder/rate/?length=${length}&billing_uom=${billing_uom}&no_pcs=${no_pcs}&item_code=${item_code}&width=${width}&thickness=${thickness}`;

      // Make the Axios GET request
      axios
        .get(url, {
          withCredentials: true, // Optionally include additional configuration options
        })
        .then((response) => {
          console.log(response);
          // Split the response data by commas
          const values = response.data.data.size;
          console.log(values, "size333333333333333333333333333");
          // Assign the values to the respective properties
          const newData = { ...data };
          newData.bookingSize[i].qty =
            newData.bookingSize[i].sheet_per_roll * no_pcs;
          newData.bookingSize[i].total_sqmf = parseFloat(values);

          return newData;
        });

      return {
        ...data,
      };
    });
  };

  const handleSelectChange = async (selectedValue, i) => {
    console.log(selectedValue);
    props.setData((data) => {
      axios
        .get(
          employeeData.URL +
            "/api/v1/salesOrder/additional-data-of-hsn/" +
            selectedValue,
          {
            withCredentials: true,
          }
        )

        .then((response) => {
          console.log(response);
          // data.bookingSize[i].density=response.data.data.density.rows[0].density
          setSizef([]);
          setSizef(
            response.data.data.size.rows.map((option) => {
              return (
                <Option
                  style={{ textTransform: "capitalize", color: "#1777C4" }}
                  key={option[response.data.data.size.fields[0].name]}
                  value={option[response.data.data.size.fields[0].name]}
                >
                  {option[response.data.data.size.fields[1].name]}
                </Option>
              );
            })
          );

          setGradef(
            response.data.data.grade.rows.map((option) => {
              return (
                <Option
                  style={{ textTransform: "capitalize", color: "#1777C4" }}
                  key={option[response.data.data.grade.fields[0].name]}
                  value={option[response.data.data.grade.fields[0].name]}
                >
                  {option[response.data.data.grade.fields[1].name]}
                </Option>
              );
            })
          );

          // Assign the density value to data.bookingSize[i].density
        });

      return {
        ...data,
      };
    });
  };

  const handleValueChanges = (changedValues, allValues) => {
    props.setData((data) => {
      //  console.log(changedValues.bookingSize.length)
      return {
        ...data,
        bookingSize: allValues.bookingSize,
        bookingSize1: changedValues.bookingSize,
      };
    });
    // console.log( allValues.bookingSize)
    // console.log(changedValues.bookingSize)
    // console.log(changedValues.bookingSize.length)
  };

  return props.editMode ? (
    <Form
      layout="vertical"
      name="filter_form"
      onValuesChange={handleValueChanges}
      autoComplete="off"
    >
      <Form.List name="bookingSize" initialValue={props.data}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, i) => (
              <React.Fragment key={key}>
                {/* Dynamic form fields can be added here */}
                {/* Example: A text input for booking name */}
                <Row gutter={16}>
                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "item_code"]}
                      fieldKey={[fieldKey, "item_code"]}
                      // rules={[{ required: true, message: "Missing Name" }]}
                      label={<span style={{ fontWeight: "bold" }}>Item</span>}
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        // Adding a label here
                        placeholder="Select"
                        onChange={(valu) => handleSelectChange(valu, i)}
                        optionFilterProp="children"
                      >
                        {props.ad.ITEM_CODE.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.ITEM_CODE.fields[0].name]}
                              value={option[props.ad.ITEM_CODE.fields[0].name]}
                            >
                              {option[props.ad.ITEM_CODE.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "texture_code"]}
                      fieldKey={[fieldKey, "texture_code"]}
                      label={
                        <span style={{ fontWeight: "bold" }}>Texture</span>
                      }
                      // rules={[{ message: "Missing Name" }]}
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        {console.log("size", sizef)}
                        {/* {console.log("dert",sizef.length)} */}
                        {sizef.length > 0 ? sizef : null}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "color_code"]}
                      fieldKey={[fieldKey, "color_code"]}
                      // rules={[{ required: true, message: "Missing Name" }]}
                      label={<span style={{ fontWeight: "bold" }}>Color</span>}
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        {gradef.length > 0 ? gradef : null}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "width"]}
                      fieldKey={[fieldKey, "width"]}
                      label={<span style={{ fontWeight: "bold" }}>Width</span>}
                      rules={[
                        {
                          //   required: true,
                          //message: 'Field should not be blank!!'
                        },
                        {
                          //   type: 'number',
                          // message: "please enter only numeric value"
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        placeholder="Enter Width"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "length"]}
                      fieldKey={[fieldKey, "length"]}
                      label={<span style={{ fontWeight: "bold" }}>Length</span>}
                      rules={
                        [
                          // {
                          //     required: true,
                          //     message: 'Field should not be blank!!'
                          // },
                          // {
                          //     type: 'number',
                          //     message: "please enter only numeric value"
                          // },
                        ]
                      }
                    >
                      <Input
                        // onChange={handleChangee}
                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        placeholder="Enter Length"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "thickness"]}
                      fieldKey={[fieldKey, "thickness"]}
                      rules={
                        [
                          // {
                          //     required: true,
                          //     message: 'Field should not be blank!!'
                          // },
                          // {
                          //     type: 'number',
                          //     message: "please enter only numeric value"
                          // },
                        ]
                      }
                      label={
                        <span style={{ fontWeight: "bold" }}>Thickness</span>
                      }
                    >
                      <Input
                        // onChange={handleChangee}
                        // onBlur={handleChangee2}
                        onBlur={(changedValues, allValues) =>
                          handleChangee2(changedValues, allValues, i)
                        }
                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        placeholder="Enter Thickness"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "masking_code"]}
                      fieldKey={[fieldKey, "masking_code"]}
                      // rules={[{ required: true, message: "Missing Name" }]}
                      label={
                        <span style={{ fontWeight: "bold" }}>Masking</span>
                      }
                    >
                      <Select
                        // onChange={handleChangee}
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        {props.ad.MASKING_DESC.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.MASKING_DESC.fields[1].name]}
                              value={
                                option[props.ad.MASKING_DESC.fields[1].name]
                              }
                            >
                              {option[props.ad.MASKING_DESC.fields[0].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "dispatch_date"]}
                      fieldKey={[fieldKey, "dispatch_date"]}
                      rules={[{ required: true, message: "Missing Date" }]}
                      label={
                        <span style={{ fontWeight: "bold" }}>
                          Dispatch Date
                        </span>
                      }
                    >
                      <DatePicker
                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        // defaultValue={dayjs().toDate()}
                        bordered={true}
                        placeholder="Select Date"
                        format="DD-MM-YYYY" // Set the desired date format

                        // value={dayjs().toDate()}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <p></p>
                    <Button
                      type="primary"
                      style={{ marginTop: "14px" }}
                      onClick={handleButtonClick}
                    >
                      Refresh
                    </Button>
                  </Col>

                  <Col span={24} className={classes["Col"]}>
                    <div>
                      {/* Other components or content */}
                      {sizeData !== null ? (
                        <AdminTable
                          sizeData={sizeData}
                          setMata={props.setData}
                          // data1={props.data1}
                          // data2={props.data2}
                        />
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </Col>

                  {/* <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "distributor_code"]}
                      fieldKey={[fieldKey, "distributor_code"]}
                      rules={[{ required: true, message: "Missing Name" }]}
                      label={
                        <span style={{ fontWeight: "bold" }}>Customer </span>
                      }
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        onChange={(valu) => handleSelectChange(valu, i)}
                        optionFilterProp="children"
                      >
                        {props.ad.DISTRIBUTOR_CODE.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={
                                option[props.ad.DISTRIBUTOR_CODE.fields[0].name]
                              }
                              value={
                                option[props.ad.DISTRIBUTOR_CODE.fields[0].name]
                              }
                            >
                              {option[props.ad.DISTRIBUTOR_CODE.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col> */}
                </Row>
              </React.Fragment>
            ))}
          </>
        )}
      </Form.List>
    </Form>
  ) : (
    <>
      {props.data.map((data, index) => {
        return (
          <div key={index}>
            <p></p>
            <Row
              className={props.editMode ? classes["RowDEX"] : classes["RowD"]}
            >
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.ITEM_CODE}
                type="Select"
                name="Item"
                value={data.item_code}
              />
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.SIZE_CODE}
                type="Select"
                name="Size"
                value={data.size_code}
              />
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.UOM}
                type="Select"
                name="Uom"
                value={data.uom}
              />
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.QUALITY}
                type="Select"
                name="Grade"
                value={data.quality}
              />
              <DataField
                editMode={false}
                lg={12}
                md={24}
                name="Pcs"
                value={data.no_pcs}
              />
              <DataField
                editMode={false}
                lg={12}
                md={24}
                name="Qty"
                value={data.qty}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                name="Rate"
                value={data.bk_rate}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                options={props.ad.DISCOUNT_ON}
                type="Select"
                name="Discount On"
                value={data.discount_on}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                name="Discount value"
                value={data.discount_amount}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                options={props.ad.DIS_TYPE}
                type="Select"
                name="Discount Type"
                value={data.dis_type ? data.dis_type : null}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                name="Rate After Discount"
                value={data.net_rate}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                name="ORDER AMT"
                value={data.tot_item_amt}
              />

              <DataField
                editMode={false}
                lg={8}
                md={24}
                name="NET AMOUNT"
                value={data.net_size_rate}
              />
            </Row>
            <p></p>
          </div>
        );
      })}
    </>
  );
};

export default BookingSize;
