import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import BasicDetails from "./BasicDetails";
import BookingSize from "./BookingSize";
import dayjs from "dayjs";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
const { TabPane } = Tabs;

const SalesView = (props) => {
  const { id } = useParams();
  const employeeData = useContext(DataContext);

  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [editMode1, setEditMode1] = useState(true);
  const [editDode, setEditDode] = useState(true);
  const [allData, setAllData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [ad, setAD] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [addres1, setAddres1] = useState(null);
  const [count1, setCount1] = useState(null);
  const [itemm, setItemm] = useState(null);
  const [responseDataArray, setResponseDataArray] = useState([]);
  const [dataFetchingCompleted, setDataFetchingCompleted] = useState();

  useEffect(() => {
    setAllData(null);
    setStoreData(null);
    setAD(null);

    axios
      .get(employeeData.URL + "/api/v1/salesOrder/" + id, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setItemm(response.data.data);
        setDataFetchingCompleted(response.data.data.bookingSize.length);
        // Extract distributor_code from the first API response
        const distributorCode =
          response.data.data.salesOrder[0].distributor_code;

        // Make a second API call using distributorCode as a parameter
        axios
          .get(
            employeeData.URL +
              "/api/v1/salesOrder/additional-data-of-cust/" +
              distributorCode,
            {
              withCredentials: true,
            }
          )
          .then((secondResponse) => {
            console.log(secondResponse);
            const add = secondResponse.data.data.custdetail.rows;
            const count = secondResponse.data.data.city.rows;
            setAddres1(add);
            setCount1(count);

            setAllData((allData) => {
              let newDetails = response.data.data;
              // Modify reqDetail array to include Mode property with value true for each object
              newDetails.bookingSize.forEach((detail) => {
                detail.Mode = true;
              });
              return {
                ...newDetails,
              };
            });

            setStoreData((storeData) => {
              let newDetails = response.data.data;
              return {
                ...newDetails,
              };
            });
          });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(employeeData.URL + "/api/v1/salesOrder/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
            DISCOUNT_ON: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "r", value: "rate" },
                { key: "ta", value: "total amount" },
              ],
            },
            DIS_TYPE: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "p", value: "%" },
                { key: "a", value: "amount" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async (item, index) => {
      try {
        console.log(item.item_code);
        const response = await axios.get(
          `${employeeData.URL}/api/v1/salesOrder/additional-data-of-hsn/${item.item_code}`,
          {
            withCredentials: true,
          }
        );
        console.log(response.data.data);
        const data = response.data.data;
        setResponseDataArray((prevDataArray) => {
          const newArray = [...prevDataArray];
          newArray[index] = data; // Set the response data at the correct index
          return newArray;
        });
      } catch (error) {
        console.error(
          `Error fetching data for item_code ${item.item_code}:`,
          error
        );
      }
    };

    const fetchAllData = async () => {
      if (!itemm || !itemm.bookingSize) {
        console.error("itemm or itemm.bookingSize is null or undefined", itemm);
        return;
      }

      try {
        await Promise.all(
          itemm.bookingSize.map((item, index) => fetchData(item, index))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAllData().then(() => {
      console.log(responseDataArray.length);
      console.log(responseDataArray);
    });
  }, [itemm, setResponseDataArray]);

  const handleSave = (event) => {
    const postData = {
      ...allData,
    };

    const postDataS = {
      ...storeData,
      bookingSize: storeData.bookingSize.map((dep) => {
        return {
          ...dep,
        };
      }),
    };

    console.log(postData);
    console.log(postDataS);
    console.log(allData);
    // Filter out undefined elements from reqDetail array
    postData.bookingSize = postData.bookingSize.filter(
      (detail) => detail !== null && detail !== undefined
    );

    postData.bookingSize.forEach((lang, index) => {
      if (!lang.unique_id) {
        postData.bookingSize[index] = {
          ...lang,
          PARAM: "INSERT",
        };
      } else {
        postData.bookingSize[index] = {
          ...lang,
          PARAM: "UPDATE",
        };
      }
    });

    storeData.bookingSize.forEach((lang, index) => {
      const dataIndex = postData.bookingSize.findIndex(
        (element) => element.unique_id === lang.unique_id
      );
      if (dataIndex === -1) {
        postData.bookingSize.push({
          unique_id: lang.unique_id,
          PARAM: "DELETE",
        });
      }
    });

    console.log(JSON.stringify(postData, undefined, 2));

    axios
      .patch(
        employeeData.URL +
          "/api/v1/salesOrder/" +
          postData.salesOrder[0]["booking_code"],
        postData,
        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        message.success({
          content: "Sales Order Updated Successfully!!!",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        setLoading(false);
        history("/sales/transaction/salesOrder");
      })
      .catch((err) => {
        message.error({
          content: "An Error Occurred!!!!",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        setLoading(false);
      });

    setEditMode(false);
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/sales/transaction/salesOrder");
  };

  return (
    <>
      {allData && ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{ backgroundColor: "#D0F0C0", color: "#234F1E" }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButton"]}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{ backgroundColor: "#FC9483", color: "#640000" }}
                    onClick={(event) => handleCancel(event)}
                    className={classes["ProfileButton"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => {
                  setEditMode(true);
                  setEditMode1(true);
                }}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes["Col"]}>
            <div className={classes["EditView"]}>
              <Col
                style={{
                  // border: "2px solid red",
                  marginTop: "5px",
                  marginBottom: "-28px",
                }}
              >
                <span style={{ fontWeight: "bold", color: "#1677ff" }}>
                  Sale Order
                </span>
                <BasicDetails
                  create={true}
                  ad={ad}
                  addres1={addres1}
                  count1={count1}
                  setData={setAllData}
                  data={allData.salesOrder[0]}
                  editMode={editMode}
                  editMode1={editMode1}
                />
              </Col>
              <Tabs
                defaultActiveKey="0"
                centered // Center the Tabs
                type="line" // Set the type to "card"
              >
                <TabPane
                  tab={<span>Booking Size Details</span>}
                  key="0"
                  style={{
                    // border: "none !important",
                    // border: "2px solid black",
                    marginTop: "-16px",
                  }}
                >
                  <div
                    style={{
                      overflowX: "scroll",
                      // padding: "10px",

                      // border: "2px solid red",
                    }}
                  >
                    <BookingSize
                      ad={ad}
                      setData={setAllData}
                      data={allData.bookingSize}
                      editMode={editMode}
                      editDode={editDode}
                      setEditDode={setEditDode}
                      responseDataArray={responseDataArray}
                      lenght={dataFetchingCompleted}
                      setResponseDataArray={setResponseDataArray}
                      setDataFetchingCompleted={setDataFetchingCompleted}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default SalesView;
