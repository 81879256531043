import React, { useState } from "react";
import { notification } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { RiUserSearchFill } from "react-icons/ri";
import {
  DatePicker,
  Select,
  Table,
  Button,
  Form,
  Col,
  Row,
  Skeleton,
  Tooltip,
} from "antd";
import axios from "axios";
import { useContext, useEffect } from "react";
import DataContext from "../../../Context/dataContext";
import EmployeeTable from "../EmployeeTable/EmployeeTable";
import { Link } from "react-router-dom";
const { Option } = Select;

const Approval = () => {
  const employeeData = useContext(DataContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [form] = Form.useForm();
  const [approvedType, setApprovedType] = useState("PendingforApproval");

  const handleChange = (value) => {
    //  setApprovedType(value || "PendingforApproval");
    setApprovedType(value); // Update the selected value when it changes
  };
  // Original data
  // const data = [
  //   {
  //     key: "1",
  //     fromDate: "06-05-2024",
  //     toDate: "10-05-2024",
  //     status: "Pending for Approval",
  //   },

  //   {
  //     key: "4",
  //     fromDate: "06-05-2024",
  //     toDate: "11-05-2024",
  //     status: "Pending for Approval",
  //   },

  //   {
  //     key: "2",
  //     fromDate: "11-05-2024",
  //     toDate: "15-05-2024",
  //     status: "Approved",
  //   },

  //   {
  //     key: "3",
  //     fromDate: "16-05-2024",
  //     toDate: "20-05-2024",
  //     status: "Approved",
  //   },
  // ];

  // Initialize filteredData with entries that have status "Pending for Approval"
  // const [filteredData, setFilteredData] = useState(
  //   data.filter((item) => item.status === "Pending for Approval")
  // );
  const approveHandler = (event, index, newrows, code) => {
    console.log(index);
    console.log(code);

    axios
      .get(employeeData.URL + "/api/v1/approval/app/" + code.toString(), {
        withCredentials: true,
        credentials: "include",
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          // Display success notification
          notification.success({
            message: "Approval Successful",
            description: "The Approval has been successfully updated.",
            duration: 5, // Notification will auto-hide after 3 seconds
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const unapproveHandler = (event, index, newrows, code) => {
    console.log(index);
    console.log(code);

    axios
      .delete(employeeData.URL + "/api/v1/approval/" + code.toString(), {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          // Display success notification
          notification.success({
            message: "UnApproval Successful",
            description: "The Unapproval has been successfully updated.",
            duration: 5, // Notification will auto-hide after 3 seconds
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRefresh = () => {
    setRows(null);
    setColumns(null);
    // console.log(form.values);
    form
      .validateFields()
      .then((values) => {
        // console.log("Form Values:", values);
        const { fromDate, toDate } = values;
        let { approvedType } = values;

        // If approvedType is undefined, set it to "PendingforApproval"
        if (approvedType === undefined) {
          approvedType = "PendingforApproval";
        }
        console.log("approvedType:", approvedType);
        if (!fromDate || !toDate) {
          notification.error({
            message: "Please select both From Date and To Date",
            description: "Both date fields must be filled to refresh the data.",
          });
          return;
        }
        const formattedFromDate = fromDate.toISOString().split("T")[0];
        const formattedToDate = toDate.toISOString().split("T")[0];

        const url = `${employeeData.URL}/api/v1/approval/order/?fromDate=${formattedFromDate}&toDate=${formattedToDate}&type=${approvedType}`;

        // Make the Axios GET request
        axios
          .get(url, {
            withCredentials: true, // Optionally include additional configuration options
          })
          .then((response) => {
            console.log(response);
            setColumns((columns) => {
              const newNewCols = [
                { name: "SNO", title: "SNo" },
                { name: "booking_date", title: "Booking Date" },
                { name: "booking_code", title: "Booking Code" },
                { name: "ref_booking_no", title: "Ref Booking No" },
                { name: "tot_pcs", title: "Total Pieces" },
                { name: "total", title: "Total" },
                { name: "form_id", title: "Form ID" },
                { name: "APPROVE", title: "APPROVE" },
                { name: "UNAPPROVE", title: "UNAPPROVE" },
                { name: "auth_user", title: "Auth User" },
                { name: "level_no", title: "Level No" },
                { name: "actual_name", title: "Actual Name" },
                { name: "form_name", title: "Form Name" },
                { name: "max_auth_amt", title: "Max Auth Amount" },
                { name: "pay_days", title: "Pay Days" },
                { name: "unit", title: "Unit" },
                { name: "status", title: "Status" },
                { name: "ship_to", title: "Ship To" },
                { name: "delivery_add1", title: "Delivery Address" },
                {
                  name: "sales_representative_code",
                  title: "Sales Representative ",
                },
                { name: "city", title: "City" },
                { name: "customer", title: "Customer" },
              ];
              return newNewCols;
            });

            setRows((rows) => {
              let newRows = response.data.data.level.map((row, index) => {
                return {
                  APPROVE: (
                    <Tooltip placement="bottom" title="Approve" color="red">
                      <Link
                        to="#"
                        style={{ color: "red", fontWeight: "bolder" }}
                      >
                        <button
                          onClick={(event) =>
                            approveHandler(
                              event,
                              index,
                              response.data.data.level,
                              row["booking_code"]
                            )
                          }
                          style={{
                            color: "white",
                            fontWeight: "bolder",
                            fontSize: "0.8rem",
                            backgroundColor: "green", // Green background for approve
                            border: "none",
                            cursor: "pointer",
                            padding: "5px 10px",
                            borderRadius: "5px",
                          }}
                        >
                          APPROV
                        </button>
                      </Link>
                    </Tooltip>
                  ),
                  UNAPPROVE: (
                    <Tooltip
                      placement="bottom"
                      title="Unapprove"
                      color="#1777C4"
                    >
                      <button
                        onClick={(event) =>
                          unapproveHandler(
                            event,
                            index,
                            response.data.data.level,
                            row["booking_code"]
                          )
                        }
                        style={{
                          color: "white",
                          fontWeight: "bolder",
                          fontSize: "0.9rem",
                          backgroundColor: "red", // Red background for unapprove
                          border: "none",
                          cursor: "pointer",
                          padding: "5px 10px",
                          borderRadius: "5px",
                        }}
                      >
                        UNAPPROV
                      </button>
                    </Tooltip>
                  ),
                  SNO: index + 1,
                  ...row,
                };
              });
              return newRows;
            });
          });

        console.log(fromDate);
        const from = fromDate.toDate();
        console.log(from);
        console.log(toDate);
        const to = toDate.toDate();
        console.log(to);
        let filtered;
        // if (approvedType === "All") {
        //   console.log("form.values");
        //   console.log(data);
        //   filtered = data.filter((item) => {
        //     const itemFromDate = new Date(item.fromDate);
        //     const itemToDate = new Date(item.toDate);
        //     return (
        //       itemFromDate >= from &&
        //       itemToDate <= to &&
        //       (item.status === "Pending for Approval" ||
        //         item.status === "Approved")
        //     );
        //   });
        //   console.log(filtered);
        // }
        // } else {
        //   // Otherwise, filter based on the selected status
        //   filtered = data.filter((item) => {
        //     const itemFromDate = new Date(item.fromDate);
        //     const itemToDate = new Date(item.toDate);
        //     return (
        //       itemFromDate >= from &&
        //       itemToDate <= to &&
        //       item.status === approvedType
        //     );
        //   });
        // }
        // setFilteredData(filtered);
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  // Columns configuration for the table
  // const columns = [
  //   {
  //     title: "From Date",
  //     dataIndex: "fromDate",
  //     key: "fromDate",
  //   },
  //   {
  //     title: "To Date",
  //     dataIndex: "toDate",
  //     key: "toDate",
  //   },
  //   {
  //     title: "Status",
  //     dataIndex: "status",
  //     key: "status",
  //   },
  // ];

  return (
    <>
      <h4 className="text-xl font-semibold text-blue-600 mb-4">
        Approval Form
      </h4>
      <div className="flex justify-between mx-20">
        <Form layout="vertical" form={form} className="w-full">
          <Row gutter={[32, 32]} className="w-full">
            <Col span={8}>
              <Form.Item name="fromDate" label="From Date">
                <DatePicker className="w-full" format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="toDate" label="To Date">
                <DatePicker className="w-full" format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="approvedType" label="Approved Type">
                <Select
                  className="w-full"
                  defaultValue="PendingforApproval"
                  value={approvedType || "PendingforApproval"}
                  onChange={handleChange}
                >
                  <Option value="PendingforApproval">
                    Pending for Approval
                  </Option>
                  <Option value="Approved">Approved</Option>
                  <Option value="All">All</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                type="primary"
                onClick={handleRefresh}
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Refresh
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {/* <h4 className="text-xl font-semibold text-blue-600 mt-8 mb-4">
        Approval Table
      </h4> */}
      {rows && columns.length > 0 ? (
        <>
          <EmployeeTable data={rows} columns={columns} val={true} />
        </>
      ) : (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <h2>No data available to display.</h2>
        </div>
      )}
      {/* <EmployeeTable dataSource={filteredData} columns={columns} /> */}
    </>
  );
};

export default Approval;
