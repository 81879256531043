import { Row } from "antd";
import classes from "../Pages.module.css";
import DataField from "./DataField";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import DataContext from "../../../Context/dataContext";

const BasicDetail = (props) => {
  console.log(props);
  const employeeData = useContext(DataContext);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [addres, setAddres] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [deliverySiteOptions, setDeliverySiteOptions] = useState(null);

  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["authorizeLevel"]];
      newdata[0][param] = e.target.value;
      //  console.log(newdata)
      return {
        ...data,
        authorizeLevel: newdata,
      };
    });
  };

  const handleSChange = (val, param) => {
    props.setData((data) => {
      const newdata = [...data["authorizeLevel"]];
      if (param === "dealer_name") {
        setSelectedDealer(val);

        // Make API call to fetch customer list based on dealer_code
        axios
          .get(
            employeeData.URL +
              "/api/v1/salesInvoice/additional-data-of-customer/" +
              val,
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            console.log(response);
            const customer = response.data.data.customer.rows; // Replace with the actual response structure
            setCustomers(customer);
            // Update state with the new customer list
          })
          .catch((error) => {
            console.error("Error fetching customer list:", error);
          });
      }

      if (param == "distributor_code") {
        setSelectedDealer(val);
        console.log(val);
        console.log(props);
        const dis = props["data"];
        console.log(dis);
        console.log(dis["distributor_code"]);
        axios
          .get(
            employeeData.URL +
              "/api/v1/salesOrder/additional-data-of-cust/" +
              val,
            {
              withCredentials: true,
            }
          )

          .then((response) => {
            console.log(response);
            console.log(response.data.data.custdetail.rows[0].add_1);
            // const deliverySiteOptions = [];
            const add = response.data.data.custdetail;
            setDeliverySiteOptions(add);
          });
      }

      newdata[0][param] = val;
      return {
        ...data,
        authorizeLevel: newdata,
      };
    });
  };

  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["authorizeLevel"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        authorizeLevel: newdata,
      };
    });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            name="CUSTOMER CODE"
            param="order_code"
            value={props.data.order_code}
          />
        )}

        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.AUTH_STATUS} type = "Select" name = "AUTH STATUS" param = "auth_status" value = {props.data.auth_status}/>   */}
        <DataField
          editMode={props.editMode}
          lg={6}
          md={24}
          handleChange={handleDChange}
          name="Date"
          required="True"
          type="Date"
          param="create_date"
          value={props.data.create_date}
        />

        <DataField
          editMode={props.editMode}
          lg={6}
          md={24}
          handleChange={handleSChange}
          options={props.ad.form}
          type="Select"
          name="Form Name"
          required="True"
          param="form_id"
          value={props.data.form_id}
        />

        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.PAYMENT_CODE} type = "Select" name = "PAYMENT" param = "payment_code" value = {props.data.payment_code}/>  */}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "TOLERANCE"  param = "tolerance" value = {props.data.tolerance}/>   */}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "PAYMENT AMOUNT"  param = "payment_amt" value = {props.data.payment_amt}/>   */}
      </Row>
    </div>
  );
};

export default BasicDetail;
