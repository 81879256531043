import React from "react";
import { Row, InputNumber } from "antd";
import Table from "@mui/material/Table";
import Input from "@mui/material/Input";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect, useContext } from "react";

const AdminTable = (props) => {
  console.log(props, "this is props in admin table");
  console.log(props.sizeData);

  const [data, setData] = useState(props.sizeData.dispatch);

  const onInputChange = (value, index, record) => {
    // const { value } = e.target;
    console.log(record, "recooooredfff");

    // if (!/^\d+$/.test(value)) {
    //   // If the input is not numeric, you can handle the error or simply return
    //   console.error("Invalid input. Please enter only numeric values.");
    //   return;
    // }
    // Create a new array by spreading the existing GaugeSizeDetail array

    const updatedRows = data.rows.map((row, i) => {
      if (i === index) {
        const rate = parseFloat(record.sheet_per_roll);
        const planKg = (parseFloat(value) * rate).toFixed(2);
        return {
          ...row,
          dis_pcs: value,
          dis_qty: planKg,
        };
      } else {
        return row;
      }
    });
    // const updatedDispathOrder = [...props.sizeData.dispatch.rows];

    // console.log(updatedDispathOrder);
    // const rate = parseFloat(record.rate);
    // const planKg = (parseFloat(value) * rate).toFixed(2);

    // updatedDispathOrder[index] = {
    //   ...updatedDispathOrder[index],
    //   dis_pcs: value,
    //   dis_qty: planKg,
    // };

    // console.log(updatedDispathOrder);
    // Update the state with the modified data array
    setData((prevData) => ({
      ...prevData,
      rows: updatedRows,
    }));
    //Update the state with the new array
    props.setMata((prevData) => ({
      ...prevData,
      DispatchDetail: updatedRows,
    }));
  };

  const columns = [
    { key: "11", title: "Order No", dataIndex: "booking_code" },
    { key: "12", title: "Order Date", dataIndex: "booking_date" },
    { key: "13", title: "Customer", dataIndex: "customer" },
    { key: "14", title: "Item", dataIndex: "item" },
    { key: "15", title: "Texture", dataIndex: "texture" },
    { key: "16", title: "Color", dataIndex: "color" },
    { key: "17", title: "Width", dataIndex: "width" },
    { key: "18", title: "Width For Profile", dataIndex: "profile_width" },
    { key: "19", title: "Length", dataIndex: "length" },
    { key: "20", title: "Thick", dataIndex: "thickness" },
    { key: "21", title: "Masking", dataIndex: "masking" },
    { key: "22", title: "Uv", dataIndex: "uv" },
    { key: "23", title: "Sheet Area SQF/SQM", dataIndex: "sheet_area" },
    { key: "24", title: "Per SQM Weight", dataIndex: "per_sqm" },

    { key: "25", title: "Sheet Per Roll", dataIndex: "sheet_per_roll" },
    { key: "26", title: "Order Pcs", dataIndex: "pcs" },
    { key: "27", title: "Balance Pcs", dataIndex: "bal_pcs" },
    {
      key: "28",
      title: "Plan Pcs",
      dataIndex: "dis_pcs",
      editable: true,
      render: (text, record, index) => (
        <InputNumber
          value={text}
          onChange={(e) => {
            onInputChange(e, index, record);
          }}
          style={{ textAlign: "left" }}
        />
      ),
    },
    { key: "32", title: "Order Kg", dataIndex: "qty" },
    { key: "33", title: "Bal Kg", dataIndex: "bal_qt" },

    // {
    //   key: "34",
    //   title: "Plan Kg",
    //   dataIndex: (record) => (record.plan_kg ? record.plan_kg : "0"),
    // },
    {
      key: "34",
      title: "Plan Kg",
      dataIndex: "dis_qty", // New dataIndex for "Plan Kg"
    },

    { key: "29", title: "Rate", dataIndex: "rate" },
    { key: "30", title: "Weight", dataIndex: "qty" },
    { key: "31", title: "Amount", dataIndex: "amount" },
    { key: "51", title: "Inv. Pcs", dataIndex: "ret_pcs" },
    { key: "52", title: "Inv. Kg", dataIndex: "ret_bal" },
    { key: "53", title: "Ord.Avl. Pcs", dataIndex: "order_stock_pcs" },
    { key: "54", title: "Ord.Avl. Kg", dataIndex: "ord_stock_kg" },
  ];

  return (
    <Row>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  style={{
                    color: "#071E54",
                    fontWeight: "bold",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rows.map((record, index) => (
              <TableRow key={record.id}>
                {columns.map((column) => (
                  <TableCell key={column.key} style={{ whiteSpace: "nowrap" }}>
                    {column.render
                      ? column.render(record[column.dataIndex], record, index)
                      : record[column.dataIndex]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  );
};

export default AdminTable;
