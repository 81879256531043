//import {Row} from 'antd';
import React from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Space,
  Button,
  Tooltip,
  DatePicker,
  Select,
  InputNumber,
  Modal,
  Skeleton,
} from "antd";
import { Link } from "react-router-dom";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import axios from "axios";
import dayjs from "dayjs";
import classes from "../Pages.module.css";
import DataField from "./DataField";
//import BoonkigSizeEdit from './BookingSizeEdit';
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState, useContext, useEffect } from "react";
import EmployeeTable from "../EmployeeTable/EmployeeTable";
import AdminTable from "./AdminTable";
import DataContext from "../../../Context/dataContext";
//import SalesNew from './SalesNew';
//import { useState, useEffect, useContext} from 'react';
const { Option } = Select;

const BookingSize = (props) => {
  // console.log(props.data.stock_type === "os");
  const employeeData = useContext(DataContext);
  const [sizef, setSizef] = useState([]);
  const [gradef, setGradef] = useState([]);
  const [uom, setUom] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rowsedi, setRowsedi] = useState(null);
  const [columnsedi, setColumnsedi] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [sizeData, setSizeData] = useState(null);
  const [form] = Form.useForm(); // Create a form instance
  const [responseData, setResponseData] = useState(null);
  // api/v1/stockstatus

  // Function to close modal
  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  // useEffect(() => {
  //   if (isModalVisible) {
  //     setRows(null);
  //     setColumns([]);
  //     //console.log("useEffect")
  //     axios
  //       .get(employeeData.URL + "/api/v1/dailyproduction/alldailyproduction", {
  //         withCredentials: true,
  //       })
  //       .then((response) => {
  //         console.log(response);
  //         setColumns((columns) => {
  //           let newCols = response.data.data.order.fields.map((col) => {
  //             return {
  //               name: col.name,
  //               title: col.name.split("_").join(" ").toLowerCase(),
  //               editable: true,
  //             };
  //           });

  //           const newNewCols = [
  //             { name: "SNO", title: "SNo" },
  //             { name: "Select", title: "Select" },
  //             ...newCols,
  //           ];
  //           console.log(newNewCols);
  //           return newNewCols;
  //         });

  //         setRows((rows) => {
  //           let newRows = response.data.data.order.rows.map((row, index) => {
  //             return {
  //               SNO: index + 1,
  //               Select: (
  //                 <Tooltip
  //                   placement="bottom"
  //                   title="Select Planning Here"
  //                   color="#1777C4"
  //                 >
  //                   {/* <Link
  //                   to={"/sales/transaction/invoice-create"}
  //                   style={{ color: "#1777C4", fontWeight: "bolder" }}
  //                 > */}
  //                   <BsFillArrowRightSquareFill
  //                     onClick={(event) =>
  //                       deleteHandler(event, index, row["planning_code"])
  //                     }
  //                     style={{
  //                       color: "red",
  //                       fontWeight: "bolder",
  //                       fontSize: "0.8rem",
  //                       id: "hhh",
  //                     }}
  //                   />
  //                 </Tooltip>
  //               ),
  //               ...row,
  //             };
  //           });
  //           return newRows;
  //         });
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // }, [isModalVisible === true]);

  // useEffect(() => {});

  // Function to handle button click
  const handleButtonClick = () => {
    setSizeData(null);
    console.log(props.data[0].stock_type);

    // Call your API using axios
    axios
      .get(
        `${employeeData.URL}/api/v1/stockstatus/allstatus/${props.data[0].stock_type}/${props.data[0].item_code}/${props.data[0].texture_code}/${props.data[0].color_code}/${props.data[0].width}/${props.data[0].lenght}/${props.data[0].thickness}/${props.data[0].masking_code}`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // Handle successful response
        setSizeData(response.data.data); // Update state with response data
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching data:", error);
      });
  };

  const handleSelectChange = async (selectedValue, i) => {
    console.log(selectedValue);
    props.setData((data) => {
      axios
        .get(
          employeeData.URL +
            "/api/v1/salesOrder/additional-data-of-hsn/" +
            selectedValue,
          {
            withCredentials: true,
          }
        )

        .then((response) => {
          console.log(response);
          // data.bookingSize[i].density=response.data.data.density.rows[0].density
          setSizef([]);
          setSizef(
            response.data.data.size.rows.map((option) => {
              return (
                <Option
                  style={{ textTransform: "capitalize", color: "#1777C4" }}
                  key={option[response.data.data.size.fields[0].name]}
                  value={option[response.data.data.size.fields[0].name]}
                >
                  {option[response.data.data.size.fields[1].name]}
                </Option>
              );
            })
          );

          setGradef(
            response.data.data.grade.rows.map((option) => {
              return (
                <Option
                  style={{ textTransform: "capitalize", color: "#1777C4" }}
                  key={option[response.data.data.grade.fields[0].name]}
                  value={option[response.data.data.grade.fields[0].name]}
                >
                  {option[response.data.data.grade.fields[1].name]}
                </Option>
              );
            })
          );

          // Assign the density value to data.bookingSize[i].density
          // const newData = { ...data };
          // newData.bookingSize[i].density =
          //   response.data.data.density.rows[0].density;

          // return newData;
        });

      return {
        ...data,
      };
    });
  };

  const deleteHandler = (event, index, code) => {
    console.log(index);
    console.log(code);
    setIsModalVisible(false);

    axios
      .get(
        employeeData.URL +
          "/api/v1/dailyproduction/production-planning-item-detail/" +
          code,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        const planningCode =
          response.data.data.headerDetail.rows[0].planning_code;
        const planningDate =
          response.data.data.headerDetail.rows[0].planning_dt;

        // Update the allData state
        // Update the allData state
        props.setData((prevData) => {
          const newData = {
            ...prevData,
            bookingSize: prevData.bookingSize.map((item, idx) =>
              idx === 0
                ? {
                    ...item,
                    prod_code: planningCode,
                    request_date: planningDate,
                  }
                : item
            ),
          };

          // Update the form values
          form.setFieldsValue({
            bookingSize: newData.bookingSize,
          });
          setSizeData(response.data.data);
          return newData;
        });
      });
  };

  const handleValueChanges = (changedValues, allValues) => {
    props.setData((data) => {
      //  console.log(changedValues.bookingSize.length)
      return {
        ...data,
        bookingSize: allValues.bookingSize,
        bookingSize1: changedValues.bookingSize,
      };
    });
    // console.log( allValues.bookingSize)
    // console.log(changedValues.bookingSize)
    // console.log(changedValues.bookingSize.length)
  };

  return props.editMode ? (
    <Form
      form={form} // Attach the form instance
      layout="vertical"
      name="filter_form"
      onValuesChange={handleValueChanges}
      autoComplete="off"
    >
      <Form.List name="bookingSize" initialValue={props.data}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, i) => (
              <React.Fragment key={key}>
                {/* Dynamic form fields can be added here */}
                {/* Example: A text input for booking name */}
                <Row gutter={16}>
                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "item_code"]}
                      fieldKey={[fieldKey, "item_code"]}
                      // rules={[{ required: true, message: "Missing Name" }]}
                      label={<span style={{ fontWeight: "bold" }}>Item </span>}
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        // Adding a label here
                        placeholder="Select"
                        onChange={(valu) => handleSelectChange(valu, i)}
                        optionFilterProp="children"
                      >
                        {props.ad.ITEM_CODE.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.ITEM_CODE.fields[0].name]}
                              value={option[props.ad.ITEM_CODE.fields[0].name]}
                            >
                              {option[props.ad.ITEM_CODE.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "texture_code"]}
                      fieldKey={[fieldKey, "texture_code"]}
                      label={
                        <span style={{ fontWeight: "bold" }}>Texture </span>
                      }
                      // rules={[{ message: "Missing Name" }]}
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        {console.log("size", sizef)}
                        {/* {console.log("dert",sizef.length)} */}
                        {sizef.length > 0 ? sizef : null}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "color_code"]}
                      fieldKey={[fieldKey, "color_code"]}
                      // rules={[{ required: true, message: "Missing Name" }]}
                      label={<span style={{ fontWeight: "bold" }}>Color </span>}
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        {gradef.length > 0 ? gradef : null}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "width"]}
                      fieldKey={[fieldKey, "width"]}
                      label={<span style={{ fontWeight: "bold" }}>Width </span>}
                      rules={[
                        {
                          //   required: true,
                          //message: 'Field should not be blank!!'
                        },
                        {
                          //   type: 'number',
                          // message: "please enter only numeric value"
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        placeholder="Enter Width"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "length"]}
                      fieldKey={[fieldKey, "length"]}
                      label={
                        <span style={{ fontWeight: "bold" }}>Length </span>
                      }
                      rules={
                        [
                          // {
                          //     required: true,
                          //     message: 'Field should not be blank!!'
                          // },
                          // {
                          //     type: 'number',
                          //     message: "please enter only numeric value"
                          // },
                        ]
                      }
                    >
                      <Input
                        // onChange={handleChangee}
                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        placeholder="Enter Length"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "thickness"]}
                      fieldKey={[fieldKey, "thickness"]}
                      rules={
                        [
                          // {
                          //     required: true,
                          //     message: 'Field should not be blank!!'
                          // },
                          // {
                          //     type: 'number',
                          //     message: "please enter only numeric value"
                          // },
                        ]
                      }
                      label={
                        <span style={{ fontWeight: "bold" }}>Thickness </span>
                      }
                    >
                      <Input
                        // onChange={handleChangee}
                        // onBlur={handleChangee2}

                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        placeholder="Enter Thickness"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "masking_code"]}
                      fieldKey={[fieldKey, "masking_code"]}
                      // rules={[{ required: true, message: "Missing Name" }]}
                      label={
                        <span style={{ fontWeight: "bold" }}>Masking </span>
                      }
                    >
                      <Select
                        // onChange={handleChangee}
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        {props.ad.MASKING_DESC.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.MASKING_DESC.fields[1].name]}
                              value={
                                option[props.ad.MASKING_DESC.fields[1].name]
                              }
                            >
                              {option[props.ad.MASKING_DESC.fields[0].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "status_date"]}
                      fieldKey={[fieldKey, "status_date"]}
                      rules={[{ required: true, message: "Missing Date" }]}
                      label={<span style={{ fontWeight: "bold" }}>Date </span>}
                    >
                      <DatePicker
                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        format="DD-MM-YYYY"
                        bordered={true}
                        placeholder="Select Date"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "stock_type"]}
                      fieldKey={[fieldKey, "stock_type"]}
                      label={
                        <span style={{ fontWeight: "bold" }}>Stock Type</span>
                      }
                      // rules={[{ message: "Missing Name" }]}
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        <Select.Option value="os">Order Stock</Select.Option>
                        <Select.Option value="is">
                          Inventory Stock
                        </Select.Option>
                        <Select.Option value="ostis">
                          Order Stock to Inventory Stock
                        </Select.Option>
                        <Select.Option value="oibs">
                          Order/Inventory Both Stock
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={4} md={24}>
                    <p></p>
                    <Button
                      type="primary"
                      style={{ marginTop: "14px" }}
                      onClick={handleButtonClick}
                    >
                      Refresh
                    </Button>
                  </Col>
                  <Col span={24} className={classes["Col"]}>
                    <div>
                      {/* Other components or content */}
                      {sizeData !== null ? (
                        <AdminTable
                          sizeData={sizeData}
                          setMata={props.setData}
                          // data1={props.data1}
                          // data2={props.data2}
                        />
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            ))}
          </>
        )}
      </Form.List>
    </Form>
  ) : (
    <>
      {props.data.map((data, index) => {
        return (
          <div key={index}>
            <p></p>
            <Row
              className={props.editMode ? classes["RowDEX"] : classes["RowD"]}
            >
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.ITEM_CODE}
                type="Select"
                name="Item"
                value={data.item_code}
              />

              <DataField
                editMode={false}
                lg={8}
                md={24}
                name="NET AMOUNT"
                value={data.net_size_rate}
              />
            </Row>
            <p></p>
          </div>
        );
      })}
    </>
  );
};

export default BookingSize;
