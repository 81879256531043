import React from "react";
import { Row, InputNumber, Button, notification } from "antd";
import Table from "@mui/material/Table";
import Input from "@mui/material/Input";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";

const AdminTable = (props) => {
  const employeeData = useContext(DataContext);
  console.log(props, "this is props in admin table");
  console.log(props.sizeData);

  const [data, setData] = useState(props.sizeData.level);

  const onInputChange = (value, index, record) => {
    // Update the remark value in the data state
    const newData = [...data.rows];
    newData[index] = { ...record, remarks: value };
    setData({ ...data, rows: newData });
  };

  const handleButtonClick = (booking_code, remarks, index) => {
    if (!remarks) {
      notification.error({
        message: "Remarks Required",
        description: "Please enter remarks before terminating.",
      });
      return;
    }

    axios
      .get(
        `${
          employeeData.URL
        }/api/v1/termination/app/${booking_code.toString()}/${remarks.toString()}`,
        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          notification.success({
            message: "Request Successful",
            description: "The request has been successfully processed.",
          });
          // Remove the row from the data state
          const newData = data.rows.filter((_, i) => i !== index);
          setData({ ...data, rows: newData });

          // Update the remarks field of the next row if it exists
          if (newData[index]) {
            newData[index].remarks = "";
            setData({ ...data, rows: newData });
          }
        }
      })
      .catch((error) => {
        notification.error({
          message: "Request Failed",
          description: "There was an error processing the request.",
        });
      });
  };

  const columns = [
    { key: "11", title: "Order No", dataIndex: "booking_code" },
    { key: "12", title: "Order Date", dataIndex: "order_date" },
    { key: "13", title: "Customer", dataIndex: "customer" },

    { key: "26", title: "Order Pcs", dataIndex: "order_pcs" },
    { key: "27", title: "Order Qty", dataIndex: "order_qty" },
    {
      key: "28",
      title: "Remarks",
      dataIndex: "remarks",
      editable: true,
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) => {
            onInputChange(e.target.value, index, record);
          }}
          style={{ textAlign: "left" }}
        />
      ),
    },
    { key: "32", title: "Bal Pcs", dataIndex: "bal_pcs" },
    { key: "33", title: "Bal Kg", dataIndex: "bal_qt" },
    {
      key: "34",
      title: "Action",
      render: (text, record, index) => (
        <Button
          type="primary"
          onClick={() =>
            handleButtonClick(record.booking_code, record.remarks, index)
          }
        >
          Terminate
        </Button>
      ),
    },
  ];

  return (
    <Row>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  style={{
                    color: "#071E54",
                    fontWeight: "bold",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rows.map((record, index) => (
              <TableRow key={record.id}>
                {columns.map((column) => (
                  <TableCell key={column.key} style={{ whiteSpace: "nowrap" }}>
                    {column.render
                      ? column.render(record[column.dataIndex], record, index)
                      : record[column.dataIndex]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  );
};

export default AdminTable;
