import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message, notification } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import BasicDetails from "./BasicDetails";
import ItemSize from "./ItemSize";
import GradeDetails from "./GradeDetails";
import AccountDetails from "./AccountDetails";
import MaskingDetail from "./MaskingDetail";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

const identifiers = [
  "textureDetail",
  "colorDetails",
  "makingDetails",
  "accountDetails",
];

const ItemNew = (props) => {
  const employeeData = useContext(DataContext);
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [allData, setAllData] = useState({
    itemMaster: [
      {
        item_name: null,
        item_uom: null,
        item_category: null,
        hsn: null,
        item_group_cd: null,
      },
    ],
    textureDetail: [
      {
        texture_code: null,
        density: null,
      },
    ],
    colorDetails: [
      {
        color_code: null,
      },
    ],
    makingDetails: [
      {
        masking_id: null,
      },
    ],
    accountDetails: [
      {
        account_code: null,
      },
    ],
  });
  const [ad, setAD] = useState(null);

  useEffect(() => {
    setEditMode(true);
    setAD(null);
    setLoading(false);
    axios
      .get(employeeData.URL + "/api/v1/items/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
            ITEM_HOME_YN: {
              fields: [{ name: "KEY" }, { name: "VALUE" }],
              rows: [
                { KEY: "Y", VALUE: "YES" },
                { KEY: "N", VALUE: "NO" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSave = (event) => {
    var formValid = true;
    const itemMasterData = allData.itemMaster[0];
    const emptyArrays = [];
    const invalidFields = [];

    // Check if any of the required fields in itemMaster is null
    if (
      !itemMasterData.item_name ||
      !itemMasterData.item_uom ||
      !itemMasterData.item_category ||
      !itemMasterData.hsn ||
      !itemMasterData.item_group_cd
    ) {
      notification.error({
        message: "Missing Required Fields",
        description:
          "Please fill in all the required fields in the Basic Details tab.",
        duration: 5,
      });
      formValid = false;
      return;
    }

    // Check if any of the arrays is empty or has invalid fields
    if (
      allData.accountDetails.length === 0 ||
      !allData.accountDetails[0].account_code
    ) {
      emptyArrays.push("Account Details");
      invalidFields.push("account_code");
      formValid = false;
    }
    if (
      allData.colorDetails.length === 0 ||
      !allData.colorDetails[0].color_code
    ) {
      emptyArrays.push("Color Details");
      invalidFields.push("color_code");
      formValid = false;
    }
    if (
      allData.makingDetails.length === 0 ||
      !allData.makingDetails[0].masking_id
    ) {
      emptyArrays.push("Masking Details");
      invalidFields.push("masking_id");
      formValid = false;
    }
    if (
      allData.textureDetail.length === 0 ||
      !allData.textureDetail[0].texture_code
    ) {
      emptyArrays.push("Item Texture");
      invalidFields.push("texture_code");
      formValid = false;
    }

    if (!formValid) {
      const message = `Please fill in at least one value for ${emptyArrays.join(
        ", "
      )} and ensure that the following fields are not empty: ${invalidFields.join(
        ", "
      )}.`;
      notification.error({
        message: "Incomplete Data",
        description: message,
        duration: 5,
      });
      setLoading(false);
      return;
    }

    if (formValid) {
      setLoading(true);
      const postData = {
        ...allData,
        textureDetail: allData.textureDetail.map((dep) => {
          return {
            ...dep,
          };
        }),
        colorDetails: allData.colorDetails.map((dep) => {
          return {
            ...dep,
          };
        }),
        accountDetails: allData.accountDetails.map((dep) => {
          return {
            ...dep,
          };
        }),
        makingDetails: allData.makingDetails.map((dep) => {
          return {
            ...dep,
          };
        }),
      };

      console.log(postData);

      axios
        .post(employeeData.URL + "/api/v1/items", postData, {
          withCredentials: true,
          credentials: "include",
        })

        .then((response) => {
          if (response.data.status === "fail") {
            message.error({
              content: response.data.message,
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });

            setLoading(false);
          } else {
            message.success({
              content: "item master Created Successfully!!!",
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });
            setLoading(false);
            history("/sales/itemMaster/item-details");
          }
        })

        .catch((err) => {
          // if(response.data.status === 'fail'){
          //     message.error({
          //         content: response.data.message,
          //         className: 'custom-class',
          //         style: {
          //             marginTop: '2vh',
          //         },
          //     });

          //     setLoading(false)
          // }
          message.error({
            content: "An Error Occurred!!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
        });
    }
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/sales/itemMaster/item-details");
  };

  return (
    <>
      {ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes["Col"]}>
            <div className={classes["EditView"]}>
              <Tabs
                defaultActiveKey="0"
                centered
                style={{ height: "100%" }}
                tabPosition={"left"}
                type="line"
                tabBarGutter="0"
              >
                <TabPane tab={<span>Basic Details</span>} key="0">
                  <BasicDetails
                    create={true}
                    ad={ad}
                    setData={setAllData}
                    data={allData.itemMaster[0]}
                    editMode={editMode}
                  />
                </TabPane>
                <TabPane tab={<span>Item Texture</span>} key="1">
                  <ItemSize
                    ad={ad}
                    setData={setAllData}
                    data={allData.textureDetail}
                    editMode={editMode}
                  />
                </TabPane>
                <TabPane tab={<span>Color Deatils</span>} key="2">
                  <GradeDetails
                    ad={ad}
                    setData={setAllData}
                    data={allData.colorDetails}
                    editMode={editMode}
                  />
                </TabPane>
                <TabPane tab={<span>Masking Deatils</span>} key="3">
                  <MaskingDetail
                    ad={ad}
                    setData={setAllData}
                    data={allData.makingDetails}
                    editMode={editMode}
                  />
                </TabPane>
                <TabPane tab={<span>Account Details</span>} key="4">
                  <AccountDetails
                    ad={ad}
                    setData={setAllData}
                    data={allData.accountDetails}
                    editMode={editMode}
                  />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default ItemNew;
