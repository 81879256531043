import React from "react";
import { Row, Col, Form, Input, Select, Table } from "antd"; // Import Select from Ant Design
import classes from "../Pages.module.css";
import { useEffect, useContext, useState } from "react";
import { fade } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import classess from "../EmployeeTable/EmployeeTable.module.css";
const { Option } = Select; // Destructure Option from Select

const FormRenderer = (props) => {
  const [selectedCountryName, setSelectedCountryName] = useState(null);
  const [selectedGroupName, setSelectedGroupName] = useState(
    props.data.group_name
  );
  const [showTable, setShowTable] = useState(false); // State to control showing options
  const [kata, setKata] = useState(props.sizeData.rep);
  const theme = useTheme();

  console.log(props, "dfdff");
  const handleChange = (e, param) => {
    console.log(e.target.value);
    console.log(param);
    props.setData((data) => {
      const newdata = [...data["cityHeader"]];
      console.log(newdata);
      newdata[0][param] = e.target.value;
      return {
        ...data,
        cityHeader: newdata,
      };
    });
  };

  const handleSelectChange = (value, param) => {
    console.log(value);
    console.log(param);
    props.setData((data) => {
      const newdata = [...data["cityHeader"]];
      console.log(newdata);
      newdata[0][param.emp_code] = value.code; // Assuming param.emp_code is the code parameter
      newdata[0][param.emp_name] = value.name;
      return {
        ...data,
        cityHeader: newdata,
      };
    });
  };

  const handleSelectChangee = (value, param) => {
    console.log(value);
    console.log(param);
    props.setData((data) => {
      const newdata = [...data["cityHeader"]];
      console.log(newdata);
      newdata[0][param] = value;
      return {
        ...data,
        cityHeader: newdata,
      };
    });
  };
  const handleAddRecord = () => {
    console.log(props.data);
  };

  const handleRowClick = (record) => {
    console.log(record);
    setSelectedGroupName(record.group_name);
    // setSelectedCountryName(record.group_name);
    setShowTable(false);

    // Modify the state similar to handleSelectChangee
    props.setData((data) => {
      const newdata = [...data["cityHeader"]];
      newdata[0]["group_code"] = record.group_code;
      newdata[0]["parent_group"] = record.uniq_code;
      newdata[0]["group_name"] = record.group_name;
      return {
        ...data,
        cityHeader: newdata,
      };
    });
  };
  // const dataSource = [
  //   {
  //     key: "1",
  //     country_code: "1",
  //     country_name: "India",
  //     pin_code: "3434",
  //     pm: "Modi",
  //     president: "APJ",
  //   },
  //   {
  //     key: "2",
  //     country_code: "2",
  //     country_name: "Pakistan",
  //     pin_code: "1234",
  //     pm: "Khan",
  //     president: "Zia",
  //   },
  //   {
  //     key: "3",
  //     country_code: "3",
  //     country_name: "nz",
  //     pin_code: "1234",
  //     pm: "Khan",
  //     president: "Zia",
  //   },
  // ];

  // const columns = [
  //   {
  //     title: "Country Code",
  //     dataIndex: "country_code",
  //     key: "country_code",
  //     width: 100,
  //   },
  //   {
  //     title: "Country Name",
  //     dataIndex: "country_name",
  //     key: "country_name",
  //     width: 100,
  //   },
  //   { title: "Pin Code", dataIndex: "pin_code", key: "pin_code", width: 100 },
  //   { title: "Prime Minister", dataIndex: "pm", key: "pm", width: 100 },
  //   {
  //     title: "President",
  //     dataIndex: "president",
  //     key: "president",
  //     width: 100,
  //   },
  // ];

  const handleSelectClick = () => {
    setShowTable(true);
  };

  const handleBlur = () => {
    setShowTable(false);
  };
  return (
    <Row className={classes["RowDE"]}>
      {/* <Col lg={6} md={4} className={classes["Col"]}>
        <Form layout="vertical">
          <Form.Item
            label={
              <div
                style={{
                  padding: "0rem 0.5rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold", // Adding fontWeight bold
                }}
              >
                City Name
              </div>
            }
            colon={false}
            style={{ margin: "0", padding: "0" }}
          >
            <Input
              placeholder="Enter City Name"
              value={props.save ? props.data.city_name : props.data.city_name}
              onChange={(e) => handleChange(e, "city_name")}
              bordered={false}
              style={{
                width: "100%",
                backgroundColor: "white",
                color: "#1777C4",
                fontWeight: "bold",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            />
          </Form.Item>
        </Form>
      </Col> */}

      <Col lg={4} md={4} className={classes["Col"]}>
        <Form layout="vertical">
          <Form.Item
            label={
              <div
                style={{
                  padding: "0rem 0.5rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold", // Adding fontWeight bold
                }}
              >
                Sales Representative
              </div>
            }
            colon={false}
            style={{ margin: "0", padding: "0" }}
          >
            <Select
              placeholder="Select Sales Representative"
              value={props.save ? props.data.emp_code : props.data.emp_code}
              onChange={(value, option) =>
                handleSelectChange(
                  { code: value, name: option.children },
                  { emp_code: "emp_code", emp_name: "emp_name" }
                )
              }
              style={{ width: "100%" }}
            >
              {/* Add Options here */}
              {props.ad.EMPLOYEE_CODE.rows.map((option) => (
                <Option
                  style={{ textTransform: "capitalize", color: "#1777C4" }}
                  key={option[props.ad.EMPLOYEE_CODE.fields[0].name]}
                  value={option[props.ad.EMPLOYEE_CODE.fields[0].name]}
                >
                  {option[props.ad.EMPLOYEE_CODE.fields[1].name]}
                </Option>
              ))}
              {/* Add more options if needed */}
            </Select>
          </Form.Item>
        </Form>
      </Col>

      <Col lg={4} md={4} className={classes["Col"]}>
        <Form layout="vertical">
          <Form.Item
            label={
              <div
                style={{
                  padding: "0rem 0.5rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold", // Adding fontWeight bold
                }}
              >
                Representative Type
              </div>
            }
            colon={false}
            style={{ margin: "0", padding: "0" }}
          >
            <Select
              placeholder="Select Representative Type"
              value={
                props.save ? props.data.account_type : props.data.account_type
              }
              onChange={(value) => handleSelectChangee(value, "account_type")}
              style={{ width: "100%" }}
            >
              {/* Add Options here */}
              {props.ad.REPRESENT_TYPE.rows.map((option) => (
                <Option
                  style={{ textTransform: "capitalize", color: "#1777C4" }}
                  key={option[props.ad.REPRESENT_TYPE.fields[0].name]}
                  value={option[props.ad.REPRESENT_TYPE.fields[0].name]}
                >
                  {option[props.ad.REPRESENT_TYPE.fields[1].name]}
                </Option>
              ))}
              {/* Add more options if needed */}
            </Select>
          </Form.Item>
        </Form>
      </Col>

      <Col lg={8} md={4} className={classes["Col"]}>
        <Form layout="vertical">
          <Form.Item
            label={
              <div
                style={{
                  padding: "0rem 0.5rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                }}
              >
                Parent Group
              </div>
            }
            colon={false}
            style={{ margin: "0", padding: "0" }}
          >
            <Select
              // defaultValue="Select a country"
              style={{ width: "100%" }}
              onClick={handleSelectClick}
              onBlur={handleBlur}
              value={props.data.group_name}
              disabled={props.data.account_type === "M"} // Disable the field if account_type is "m"
            >
              {showTable && (
                <>
                  <Option key="table">
                    <div style={{ maxHeight: 200, overflowY: "auto" }}>
                      {" "}
                      {/* Add scrollable div */}
                      <Table
                        dataSource={props.dataSource}
                        columns={props.columns}
                        pagination={false}
                        rowClassName={(record, index) =>
                          index % 2 === 0 ? classess.oddRow : ""
                        }
                        size="small"
                        scroll={{ x: "max-content", y: "calc(100vh - 300px)" }} // Add scroll property
                        onRow={(record, rowIndex) => ({
                          // Set onClick event handler for each row
                          onClick: () => handleRowClick(record), // Call handleRowClick function when row is clicked
                        })}
                      />
                    </div>
                  </Option>
                </>
              )}
            </Select>
          </Form.Item>
        </Form>
      </Col>
      {/* <Col span={5} className={classes["Col"]}>
        <Form layout="vertical">
          <Form.Item
            label={
              <div
                style={{
                  padding: "0rem 0.5rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold", // Adding fontWeight bold
                }}
              >
                District
              </div>
            }
            colon={false}
            style={{ margin: "0", padding: "0" }}
          >
            <Input
              placeholder="Enter District Name"
              value={props.save ? props.data.distt : props.data.distt}
              onChange={(e) => handleChange(e, "distt")}
              bordered={false}
              style={{
                width: "100%",
                backgroundColor: "white",
                color: "#1777C4",
                fontWeight: "bold",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            />
          </Form.Item>
        </Form>
      </Col> */}

      <Col span={8} style={{ margin: "auto" }}>
        {props.save ? (
          <button
            onClick={(event) => props.handleSubmit(event)}
            className={classes["ProfileButtonF"]}
          >
            Add Record
          </button>
        ) : null}
      </Col>
    </Row>
  );
};

export default FormRenderer;
