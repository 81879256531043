import React, { useState } from "react";
import PieChart, { Connector } from "devextreme-react/pie-chart";
import { BarSeries } from "@devexpress/dx-react-chart-material-ui";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  ArgumentAxis,
  Label,
  ValueAxis,
  Legend,
  Export,
  Tooltip,
  ZoomAndPan,
} from "devextreme-react/chart";
import { Select } from "antd";
import PropTypes from "prop-types";
import "./style.css";

const { Option } = Select;

function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

const Charts = (props) => {
  const [type, setType] = useState("PCS");

  const handleTypeChange = (value) => {
    setType(value);
  };

  // const dummyData = [
  //   {
  //     get_distributor: "Distributor A",
  //     item: "Item 1",
  //     quantity: 4000,
  //     amount: 2400,
  //   },
  //   {
  //     get_distributor: "Distributor B",
  //     item: "Item 2",
  //     quantity: 3000,
  //     amount: 1398,
  //   },
  //   {
  //     get_distributor: "Distributor C",
  //     item: "Item 3",
  //     quantity: 2000,
  //     amount: 9800,
  //   },
  //   {
  //     get_distributor: "Distributor D",
  //     item: "Item 4",
  //     quantity: 2780,
  //     amount: 3908,
  //   },
  //   {
  //     get_distributor: "Distributor E",
  //     item: "Item 5",
  //     quantity: 1890,
  //     amount: 4800,
  //   },
  //   {
  //     get_distributor: "Distributor F",
  //     item: "Item 6",
  //     quantity: 2390,
  //     amount: 3800,
  //   },
  //   {
  //     get_distributor: "Distributor G",
  //     item: "Item 7",
  //     quantity: 3490,
  //     amount: 4300,
  //   },
  // ];

  const renderChart = () => {
    if (props.chartType !== "line") {
      return (
        <Chart
          className="line"
          title={"Top Customer Wise Sales"}
          dataSource={props.data1}
          palette="Soft"
        >
          <CommonSeriesSettings
            label={{ visible: true }}
            argumentField="get_distributor"
            type={props.chartType}
            barPadding={0.1}
            hoverMode="allArgumentPoints"
            selectionMode="allArgumentPoints"
          />
          <ZoomAndPan
            valueAxis="both"
            argumentAxis="both"
            allowMouseWheel={true}
            panKey="shift"
          />
          <Series
            key={props.chartType}
            argumentField="get_distributor"
            valueField={type.toLowerCase()}
          />
          <ArgumentAxis>
            <Label wordWrap="none" overlappingBehavior="rotate" />
          </ArgumentAxis>
          <Legend
            visible={false}
            horizontalAlignment="right"
            verticalAlignment="top"
          />
          <ValueAxis grid={{ visible: false }} />
          <Tooltip enabled={true} />
        </Chart>
      );
    } else {
      return (
        <Chart
          className="line"
          type={props.chartType}
          dataSource={props.data1}
          palette="Pastel"
          title={"Item Wise Sales"}
        >
          <Series argumentField="item" valueField={type.toLowerCase()}>
            <Label visible={true}>
              <Connector visible={true} width={1} />
            </Label>
          </Series>
          <Tooltip enabled={true} />
        </Chart>
      );
    }
  };

  return (
    <>
      <Select
        value={type}
        bordered={false}
        dropdownStyle={{ textTransform: "capitalize" }}
        onChange={handleTypeChange}
        style={{
          width: "20%",
          float: "right",
          backgroundColor: "white",
          textTransform: "capitalize",
          color: "#1777C4",
          fontWeight: "bold",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <Option
          style={{ textTransform: "capitalize", color: "#1777C4" }}
          key="AMOUNT"
          value="AMOUNT"
        >
          Amount
        </Option>
        <Option
          style={{ textTransform: "capitalize", color: "#1777C4" }}
          key="PCS"
          value="PCS"
        >
          Quantity
        </Option>
      </Select>
      {renderChart()}
    </>
  );
};

Charts.propTypes = {
  chartType: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      get_distributor: PropTypes.string,
      item: PropTypes.string,
      pcs: PropTypes.number,
      amount: PropTypes.number,
    })
  ).isRequired,
};

export default Charts;
