import React from "react";
import axios from "axios";
import { Button, Input } from "antd";
import { CalculatorOutlined } from "@ant-design/icons";
import { useState, useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";
import Admin from "./Admin";

const TaxDetail = (props) => {
  const employeeData = useContext(DataContext);
  const [taxData, setTaxData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(""); // State for total amount input
  console.log(props);

  const handleCalculateTax = () => {
    const postData = props.sizeData.custdetail;
    console.log("Calculate Tax button clicked");
    props.setMata((data) => {
      console.log(data);

      // Add your tax calculation logic here

      // Assuming employeeData.URL, postData, and data are defined
      const url =
        employeeData.URL +
        "/api/v1/salesinvoicelist/package-listing-order-invoice-tax-cal-by-hsn/";

      // Ensure postData is an object and combine it with data.bookingSize.invoice_date
      const combinedData = {
        ...postData,
        invoice_date: data.bookingSize[0].invoice_date,
      };

      // Make the POST request with Axios
      axios
        .post(url, combinedData, { withCredentials: true })
        .then((response) => {
          setTaxData(response.data.data);
          setTotalAmount(response.data.totalAmount);
          console.log("Response:", response);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      return {
        ...data,
      };
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <Button
        type="primary"
        icon={<CalculatorOutlined />}
        onClick={handleCalculateTax}
        style={{ marginRight: "10px" }}
      >
        Calculate Tax
      </Button>

      <span
        style={{
          marginLeft: "70px",
          marginRight: "30px",
          fontWeight: "bold",
          color: "#1890ff",
        }}
      >
        Net Amount:
      </span>
      <Input
        placeholder="Total Amount"
        value={totalAmount}
        // onChange={handleTotalAmountChange}
        style={{ width: "200px", textAlign: "right", fontWeight: "bold" }}
      />

      {taxData.length > 0 && (
        <Admin
          taxData={taxData}
          totalAmount={totalAmount}
          setMata={props.setMata}
        />
      )}
    </div>
  );
};

export default TaxDetail;
