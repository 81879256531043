import React from "react";
import { Row, Input, InputNumber } from "antd";
import Table from "@mui/material/Table";
// import Input from "@mui/material/Input";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect, useContext } from "react";

const Admin = (props) => {
  console.log(props, "this is props in admin table");
  //   console.log(props.sizeData);
  useEffect(() => {
    if (props.taxData) {
      // Ensure data is not null or undefined
      props.setMata((prevData) => ({
        ...prevData,
        chargedata: props.taxData, // Set ProductionDetail to the rows from data
        totalAmount: props.totalAmount,
      }));
    }
  }, [props.taxData, props.setMata]);

  const [data, setData] = useState(props.taxdata);
  console.log(data);
  const columns = [
    { key: "14", title: "Charge Desc.", dataIndex: "charge_desc" },
    // { key: "11", title: "Plannning No", dataIndex: "planning_code" },

    { key: "13", title: "Charge Type", dataIndex: "charge_type" },

    { key: "15", title: "Charge Type On", dataIndex: "charge_type_on" },
    { key: "16", title: "Charge Value", dataIndex: "charge_value" },
    // { key: "17", title: "Width", dataIndex: "width" },

    // { key: "19", title: "Length", dataIndex: "length" },
    // { key: "20", title: "Thick", dataIndex: "thick" },
    // { key: "21", title: "Masking", dataIndex: "masking_desc" },
    // { key: "22", title: "Uv", dataIndex: "uv" },

    /////////////////////
    // { key: "27", title: "Bal. Pcs", dataIndex: "bal_pcs" },
    // { key: "34", title: "Bal Kgs", dataIndex: "bal_qty" },
    // {
    //   key: "28",
    //   title: "Pcs",
    //   dataIndex: "prod_pcs",
    //   editable: true,
    //   render: (text, record, index) => (
    //     <InputNumber
    //       value={text}
    //       onChange={(e) => {
    //         onInputChange(e, index, record);
    //       }}
    //       style={{ textAlign: "right" }}
    //     />
    //   ),
    // },
    { key: "28", title: "Deal Type", dataIndex: "deal_type" },
    // { key: "33", title: "Plan Kgs", dataIndex: "plan_qty" },

    // { key: "35", title: "SO Remarks", dataIndex: "remarks1" },
    {
      key: "36",
      title: "Ref. Charge",
      dataIndex: "ref_chrg",
    },
    { key: "35", title: "TaxValue", dataIndex: "TaxValue" },
    { key: "35", title: "RunningTotal", dataIndex: "RunningTotal" },
    // { key: "23", title: "Sheet Area ", dataIndex: "sheet_area" },
    // { key: "24", title: "Per SQM ", dataIndex: "per_sqm" },
    // { key: "61", title: "Sheet/Roll ", dataIndex: "sheet_per_roll" },

    // { key: "25", title: "Sheet/Roll", dataIndex: "sheet_per_roll" },
  ];

  return (
    <Row>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  style={{
                    color: "#071E54",
                    fontWeight: "bold",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.taxData.map((record, index) => (
              <TableRow key={record.id}>
                {columns.map((column) => (
                  <TableCell key={column.key} style={{ whiteSpace: "nowrap" }}>
                    {column.render
                      ? column.render(record[column.dataIndex], record, index)
                      : record[column.dataIndex]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  );
};

export default Admin;
