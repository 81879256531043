import React, { useState, useEffect, useContext } from "react";
import { notification } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { RiUserSearchFill } from "react-icons/ri";
import {
  DatePicker,
  Select,
  Table,
  Button,
  Form,
  Col,
  Row,
  Skeleton,
  Tooltip,
} from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import EmployeeTable from "../EmployeeTable/EmployeeTable";
import { Link } from "react-router-dom";
import AdminTable from "./AdminTable";
const { Option } = Select;

const Approval = () => {
  const employeeData = useContext(DataContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [form] = Form.useForm();
  const [approvedType, setApprovedType] = useState();
  const [ad, setAD] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [sizeData, setSizeData] = useState(null);

  useEffect(() => {
    setEditMode(true);
    setAD(null);
    setLoading(false);

    axios
      .get(employeeData.URL + "/api/v1/salesOrder/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
          };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (value) => {
    console.log(value);
    setApprovedType(value); // Update the selected value when it changes
  };

  const approveHandler = (event, index, newrows, code) => {
    console.log(index);
    console.log(code);

    axios
      .get(employeeData.URL + "/api/v1/approval/app/" + code.toString(), {
        withCredentials: true,
        credentials: "include",
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          // Display success notification
          notification.success({
            message: "Approval Successful",
            description: "The Approval has been successfully updated.",
            duration: 5, // Notification will auto-hide after 5 seconds
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const unapproveHandler = (event, index, newrows, code) => {
    console.log(index);
    console.log(code);

    axios
      .delete(employeeData.URL + "/api/v1/approval/" + code.toString(), {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          // Display success notification
          notification.success({
            message: "UnApproval Successful",
            description: "The Unapproval has been successfully updated.",
            duration: 5, // Notification will auto-hide after 5 seconds
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRefresh = () => {
    setRows(null);
    setColumns(null);

    form
      .validateFields()
      .then((values) => {
        const { fromDate, toDate } = values;

        // If approvedType is undefined, set it to "PendingforApproval"
        // if (approvedType === undefined) {
        //   approvedType = "PendingforApproval";
        // }
        console.log("approvedType:", approvedType);
        if (!fromDate || !toDate) {
          notification.error({
            message: "Please select both From Date and To Date",
            description: "Both date fields must be filled to refresh the data.",
          });
          return;
        }
        const formattedFromDate = fromDate.toISOString().split("T")[0];
        const formattedToDate = toDate.toISOString().split("T")[0];

        const url = `${employeeData.URL}/api/v1/termination/order/?fromDate=${formattedFromDate}&toDate=${formattedToDate}&type=${approvedType}`;

        // Make the Axios GET request
        axios
          .get(url, {
            withCredentials: true,
          })
          .then((response) => {
            console.log(response);
            setSizeData(response.data.data);
            setColumns((columns) => {
              const newNewCols = [
                { name: "SNO", title: "SNo" },
                { name: "booking_date", title: "Booking Date" },
                { name: "booking_code", title: "Booking Code" },
                { name: "ref_booking_no", title: "Ref Booking No" },
                { name: "tot_pcs", title: "Total Pieces" },
                { name: "total", title: "Total" },
                { name: "form_id", title: "Form ID" },
                { name: "APPROVE", title: "APPROVE" },
                { name: "UNAPPROVE", title: "UNAPPROVE" },
                { name: "auth_user", title: "Auth User" },
                { name: "level_no", title: "Level No" },
                { name: "actual_name", title: "Actual Name" },
                { name: "form_name", title: "Form Name" },
                { name: "max_auth_amt", title: "Max Auth Amount" },
                { name: "pay_days", title: "Pay Days" },
                { name: "unit", title: "Unit" },
                { name: "status", title: "Status" },
                { name: "ship_to", title: "Ship To" },
                { name: "delivery_add1", title: "Delivery Address" },
                {
                  name: "sales_representative_code",
                  title: "Sales Representative ",
                },
                { name: "city", title: "City" },
                { name: "customer", title: "Customer" },
              ];
              return newNewCols;
            });

            setRows((rows) => {
              let newRows = response.data.data.level.rows.map((row, index) => {
                return {
                  APPROVE: (
                    <Tooltip placement="bottom" title="Approve" color="red">
                      <Link
                        to="#"
                        style={{ color: "red", fontWeight: "bolder" }}
                      >
                        <button
                          onClick={(event) =>
                            approveHandler(
                              event,
                              index,
                              response.data.data.level.rows,
                              row["booking_code"]
                            )
                          }
                          style={{
                            color: "white",
                            fontWeight: "bolder",
                            fontSize: "0.8rem",
                            backgroundColor: "green", // Green background for approve
                            border: "none",
                            cursor: "pointer",
                            padding: "5px 10px",
                            borderRadius: "5px",
                          }}
                        >
                          APPROV
                        </button>
                      </Link>
                    </Tooltip>
                  ),
                  UNAPPROVE: (
                    <Tooltip
                      placement="bottom"
                      title="Unapprove"
                      color="#1777C4"
                    >
                      <button
                        onClick={(event) =>
                          unapproveHandler(
                            event,
                            index,
                            response.data.data.level.rows,
                            row["booking_code"]
                          )
                        }
                        style={{
                          color: "white",
                          fontWeight: "bolder",
                          fontSize: "0.9rem",
                          backgroundColor: "red", // Red background for unapprove
                          border: "none",
                          cursor: "pointer",
                          padding: "5px 10px",
                          borderRadius: "5px",
                        }}
                      >
                        UNAPPROV
                      </button>
                    </Tooltip>
                  ),
                  SNO: index + 1,
                  ...row,
                };
              });
              return newRows;
            });
          });

        console.log(fromDate);
        const from = fromDate.toDate();
        console.log(from);
        console.log(toDate);
        const to = toDate.toDate();
        console.log(to);
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  return (
    <>
      <h4 className="text-xl font-semibold text-blue-600 mb-4">
        Termination Form
      </h4>
      <div className="flex justify-between mx-20">
        {ad ? (
          <Form layout="vertical" form={form} className="w-full">
            <Row gutter={[32, 32]} className="w-full">
              <Col span={8}>
                <Form.Item name="fromDate" label="From Date">
                  <DatePicker className="w-full" format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="toDate" label="To Date">
                  <DatePicker className="w-full" format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="customer" label="Customer">
                  <Select
                    bordered={true}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      float: "left",
                      backgroundColor: "white",
                      color: "#1777C4",
                      fontWeight: "bold",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                    placeholder="Select"
                    onChange={(valu) => handleChange(valu)}
                    optionFilterProp="children"
                  >
                    {ad.DISTRIBUTOR_CODE.rows.map((option) => {
                      return (
                        <Option
                          style={{
                            textTransform: "capitalize",
                            color: "#1777C4",
                          }}
                          key={option[ad.DISTRIBUTOR_CODE.fields[0].name]}
                          value={option[ad.DISTRIBUTOR_CODE.fields[0].name]}
                        >
                          {option[ad.DISTRIBUTOR_CODE.fields[1].name]}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  type="primary"
                  onClick={handleRefresh}
                  className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Refresh
                </Button>
              </Col>
            </Row>
          </Form>
        ) : (
          <Skeleton active />
        )}
      </div>
      {sizeData !== null ? (
        <AdminTable
          sizeData={sizeData}

          // data1={props.data1}
          // data2={props.data2}
        />
      ) : (
        <p></p>
      )}
    </>
  );
};

export default Approval;
