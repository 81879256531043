//import {Row} from 'antd';
import {
  Row,
  Col,
  Form,
  Input,
  Space,
  Button,
  DatePicker,
  Select,
  InputNumber,
} from "antd";
import axios from "axios";
import classes from "../Pages.module.css";
import DataField from "./DataField";
//import BoonkigSizeEdit from './BookingSizeEdit';
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState, useContext, useEffect } from "react";
import DataContext from "../../../Context/dataContext";
//import SalesNew from './SalesNew';
//import { useState, useEffect, useContext} from 'react';
const { Option } = Select;

const Authorized = (props) => {
  console.log(props);
  const employeeData = useContext(DataContext);

  const handleValueChanges = (changedValues, allValues) => {
    props.setData((data) => {
      //  console.log(changedValues.bookingSize.length)
      return {
        ...data,
        authorizeLeveldet: allValues.authorizeLeveldet,
      };
    });
    // console.log( allValues.bookingSize)
    // console.log(changedValues.bookingSize)
    // console.log(changedValues.bookingSize.length)
  };

  return props.editMode ? (
    <Form
      layout="vertical"
      name="filter_form"
      onValuesChange={handleValueChanges}
      autoComplete="off"
    >
      <Form.List name="authorizeLeveldet" initialValue={props.data}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, i) => (
                <table
                  id="table_order"
                  className="table table-bordered"
                  style={
                    {
                      // tableLayout: "fixed",
                      // width: "2150px",
                      // marginBottom: "-2rem",
                    }
                  }
                >
                  {i == 0 && (
                    <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
                      <tr
                        style={{
                          height: "0.4rem",
                          backgroundColor: "rgb(217 217 217 / 35%)",
                        }}
                      >
                        <th>
                          {" "}
                          User{" "}
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>

                        {/* <th style={{}}>Uom</th> */}
                        <th style={{ width: "100px" }}>
                          Amount{" "}
                          <span
                            style={{
                              backgroundColor: "rgb(217 217 217 / -0.65%)",
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </th>

                        <th style={{}}>#</th>
                      </tr>
                    </thead>
                  )}

                  <tbody>
                    <tr>
                      <td
                        style={{
                          border: "1px solid white",
                        }}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "user_code"]}
                          fieldKey={[fieldKey, "user_code"]}
                          rules={[{ required: true, message: "Missing Name" }]}
                        >
                          <Select
                            bordered={true}
                            style={{
                              width: "100%",
                              textAlign: "left",
                              float: "left",
                              backgroundColor: "white",
                              color: "#1777C4",
                              fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            placeholder="Select"
                            optionFilterProp="children"
                          >
                            {props.ad.employees.rows.map((option) => {
                              return (
                                <Option
                                  style={{
                                    textTransform: "capitalize",
                                    color: "#1777C4",
                                  }}
                                  key={
                                    option[props.ad.employees.fields[0].name]
                                  }
                                  value={
                                    option[props.ad.employees.fields[0].name]
                                  }
                                >
                                  {option[props.ad.employees.fields[1].name]}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </td>

                      <td style={{ border: "1px solid white" }}>
                        <Form.Item
                          {...restField}
                          name={[name, "max_auth_amt"]}
                          fieldKey={[fieldKey, "max_auth_amt"]}
                          rules={
                            [
                              // {
                              //     required: true,
                              //     message: 'Field should not be blank!!'
                              // },
                              // {
                              //     type: 'number',
                              //     message: "please enter only numeric value"
                              // },
                            ]
                          }
                        >
                          <Input
                            style={{
                              width: "100%",
                              textAlign: "right",
                              backgroundColor: "white",
                              color: "#1777C4",
                              fontWeight: "bold",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                            bordered={true}
                            placeholder="Enter Amount"
                          />
                        </Form.Item>
                      </td>

                      <td style={{ border: "1px solid white" }}>
                        <MinusCircleOutlined
                          className={classes["Remove"]}
                          onClick={() => {
                            remove(name);
                          }}
                        />

                        <PlusCircleOutlined
                          className={classes["Add"]}
                          onClick={() => {
                            add();
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  style={{ display: "none" }}
                  onClick={() => {
                    add();
                  }}
                >
                  Add Booking
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </Form>
  ) : (
    <>
      {props.data.map((data, index) => {
        return (
          <div key={index}>
            <p></p>
            <Row
              className={props.editMode ? classes["RowDEX"] : classes["RowD"]}
            >
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.employees}
                type="Select"
                name="User"
                value={data.user_code}
              />

              <DataField
                editMode={false}
                lg={12}
                md={24}
                name="Amount"
                value={data.max_auth_amt}
              />
            </Row>
            <p></p>
          </div>
        );
      })}
    </>
  );
};

export default Authorized;
