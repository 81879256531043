import classes from "../Pages.module.css";
import { Row, Col, Tabs, Card, Skeleton, message, notification } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import BasicDetails from "./BasicDetails";
import BookingSize from "./BookingSize";
import AdminTable from "./AdminTable";
import dayjs from "dayjs";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;
const identifiers = ["bookingSize"];

const SalesNew = (props) => {
  const employeeData = useContext(DataContext);
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);

  const [allData, setAllData] = useState({
    salesOrder: [
      {
        order_type: null,
        distributor_code: null,
        dispatch_date: dayjs(new Date()).format("DD-MM-YYYY"),
        invoice_type_code: null,
        payment_days: null,
        del_site_code: null,
        auth_status: null,
        freight_type_code: null,
        customer_po_no: null,
        ship_to: null,
        tolerance: null,
        country_code: null,
        cur_code: null,
        remarks: null,
      },
    ],

    bookingSize: [
      {
        item_code: null,
        texture_code: null,
        color_code: null,
        masking_code: null,
        width: null,
        lenght: null,
        thickness: null,
        status_date: dayjs(new Date()),
        stock_type: "os",
      },
    ],
  });

  const [ad, setAD] = useState(null);
  const [sizeData, setSizeData] = useState(null);

  useEffect(() => {
    setEditMode(true);
    setAD(null);
    setLoading(false);

    axios
      .get(employeeData.URL + "/api/v1/salesOrder/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
            // AUTH_STATUS: {
            //     fields: [{name: "KEY"},{name: "VALUE"}],
            //     rows: [{KEY: "P", VALUE: "PENDING"},{KEY: "A", VALUE: "APPROVEL"}]
            //   },
            // ALLOWANCE_TYPE: {
            //     metaData: [{name: "KEY"},{name: "VALUE"}],
            //     rows: [{KEY: "%", VALUE: "Percentage"},{KEY: "A", VALUE: "Amount"}]
            //   },
            DISCOUNT_ON: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "r", value: "rate" },
                { key: "ta", value: "total amount" },
              ],
            },
            DIS_TYPE: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "p", value: "%" },
                { key: "a", value: "amount" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // useEffect(() => {
  //   setSizeData(null);
  //   // if (selectedItem) {
  //   axios
  //     .get(employeeData.URL + `/api/v1/production/allplanning`, {
  //       withCredentials: true,
  //     })
  //     .then((response) => {
  //       console.log(response);
  //       setSizeData(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching item details:", error);
  //     });
  //   // }
  // }, []);

  const handleSave = (event) => {
    console.log("ytu467676");
    var formv = true;

    identifiers.forEach((val) => {
      console.log(val);
      console.log(allData[val]);
      let count = 0;
      allData[val].forEach((data) => {
        console.log(data);
        console.log(allData[val]);
        if (!data) {
          formv = formv && false;
          if (count === 0) {
            message.error({
              content: "Empty Fields In " + val.toUpperCase() + " Tab!!!",
              className: "custom-class",
              style: {
                marginTop: "1vh",
              },
            });
          }

          count = count + 1;
        }
      });
    });

    if (formv) {
      setLoading(true);
      const postData = {
        ...allData,
      };

      console.log(postData);
      console.log(postData.bookingSize[0].stock_type);
      if (
        postData.bookingSize[0].stock_type === "os" ||
        postData.bookingSize[0].stock_type === "is" ||
        postData.bookingSize[0].stock_type === "oibs"
      ) {
        notification.error({
          message: "UnSuccessful",
          description:
            "Post Only With Stock Type Is Order Stock to Inventory Stock",
          duration: 5, // Notification will auto-hide after 3 seconds
        });
        setLoading(false);
        return;
      }

      if (!postData.DailyProductionDetail) {
        message.error({
          content: "Please add Prod Pcs !",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        setLoading(false);
      } else {
        axios
          .post(
            employeeData.URL + "/api/v1/stockstatus/create-status",
            postData,
            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            message.success({
              content: " Stock Status  Created Successfully!!!",
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });
            setLoading(false);
            history("/sales/transaction/openning-details");
          })
          .catch((err) => {
            message.error({
              content: "An Error Occurred!!!!",
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });
            setLoading(false);
          });
      }
    }
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/sales/transaction/openning-details");
  };

  return (
    <>
      {ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={12}>
                {/* <h2
                  style={{
                    color: "#355576",
                    fontWeight: "bold",
                    borderRadius: "20px",

                    fontFamily: "Montserrat",
                    // fontWeight: 900,
                    fontSize: "1.1rem",
                  }}
                >
                  Sale Order
                </h2> */}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes["Col"]}>
            <div className={classes["EditView"]}>
              {/* <Col
                style={{
                  // border: "2px solid red",
                  marginTop: "5px",
                  marginBottom: "-28px",
                }}
              > */}
              {/* <span style={{ fontWeight: "bold", color: "#1677ff" }}>
                  Sale Order
                </span> */}
              <Col>
                {/* <BasicDetails
                  create={true}
                  ad={ad}
                  setData={setAllData}
                  data={allData.salesOrder[0]}
                  editMode={editMode}
                /> */}

                {/* </Col> */}

                <BookingSize
                  create={true}
                  ad={ad}
                  setData={setAllData}
                  data={allData.bookingSize}
                  editMode={editMode}
                />
              </Col>
            </div>
          </Col>
          <Col span={24} className={classes["Col"]}></Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default SalesNew;
