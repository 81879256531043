import {
  Row,
  Col,
  Form,
  Input,
  Space,
  Button,
  DatePicker,
  Select,
  InputNumber,
} from "antd";
import classes from "../Pages.module.css";
import { useState, useEffect, useContext } from "react";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const DependentEdit = (props) => {
  const [cityCode, setCityCode] = useState(null);

  console.log(props);
  const handleValueChanges = (changedValues, allValues) => {
    props.setData((data) => {
      return {
        ...data,
        deliveryDetails: allValues.deliveryDetails,
      };
    });
  };

  const handleCityChange = (value) => {
    console.log(value);

    setCityCode(value);
  };

  return (
    <Form
      layout="vertical"
      name="filter_form"
      onValuesChange={handleValueChanges}
      autoComplete="off"
    >
      <Form.List name="deliveryDetails" initialValue={props.data}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space key={key} className={classes["Space"]} align="center">
                  <Row gutter={16}>
                    <p></p>
                    <Col lg={8} md={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                        label={
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.6rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Name
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Field should not be blank!!",
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: "100%",
                            float: "left",
                            backgroundColor: "white",
                            // color: "#1777C4",
                            // fontWeight: "bold",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          bordered={false}
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "city_code"]}
                        fieldKey={[fieldKey, "city_code"]}
                        label={
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.6rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            City
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Field should not be blank!!",
                          },
                        ]}
                      >
                        <Select
                          bordered={false}
                          style={{
                            width: "100%",
                            textAlign: "left",
                            float: "left",
                            backgroundColor: "white",
                            color: "#1777C4",
                            fontWeight: "bold",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          placeholder="Select"
                          optionFilterProp="children"
                          onChange={handleCityChange}
                        >
                          {props.cityOptions.rows.map((option) => {
                            return (
                              <Option
                                style={{
                                  textTransform: "capitalize",
                                  color: "#1777C4",
                                }}
                                key={option[props.cityOptions.fields[0].name]}
                                value={option[props.cityOptions.fields[0].name]}
                              >
                                {option[props.cityOptions.fields[1].name]}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "add_1"]}
                        fieldKey={[fieldKey, "add_1"]}
                        label={
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.6rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Address
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Field should not be blank!!",
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: "100%",
                            float: "left",
                            backgroundColor: "white",
                            // color: "#1777C4",
                            // fontWeight: "bold",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          bordered={false}
                          placeholder="Enter Address"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "pin"]}
                        fieldKey={[fieldKey, "pin"]}
                        label={
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.6rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Pin Number
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message:
                              "Field should not be blank And Pin No can only contain numeric characters!!",
                          },
                          {
                            pattern: /^[0-9]*$/, // Regular expression to allow only numeric values
                            message:
                              "Pin No can only contain numeric characters",
                          },
                          {
                            type: "number",
                            min: 6,
                            message: "please enter minimum 6 no",
                          },
                        ]}
                      >
                        <InputNumber
                          maxLength={6}
                          style={{
                            width: "100%",
                            float: "left",
                            backgroundColor: "white",
                            // color: "#1777C4",
                            // fontWeight: "bold",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          bordered={false}
                          placeholder="Enter Pin Number"
                          formatter={(value) => `${value}`.replace(/\D/g, "")} // Remove non-numeric characters
                          parser={(value) => value.replace(/\D/g, "")} // Remove non-numeric characters
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={8} md={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "locality_code"]}
                        fieldKey={[fieldKey, "locality_code"]}
                        label={
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.6rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Locality
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Field should not be blank!!",
                          },
                        ]}
                      >
                        <Select
                          bordered={false}
                          style={{
                            width: "100%",
                            textAlign: "left",
                            float: "left",
                            backgroundColor: "white",
                            color: "#1777C4",
                            fontWeight: "bold",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          placeholder="Select"
                          optionFilterProp="children"
                        >
                          {cityCode
                            ? props.localityOptions.rows
                                .filter(
                                  (locality) => locality.city_code === cityCode
                                )
                                .map((option) => {
                                  return (
                                    <Option
                                      style={{
                                        textTransform: "capitalize",
                                        color: "#1777C4",
                                      }}
                                      key={
                                        option[
                                          props.localityOptions.fields[0].name
                                        ]
                                      }
                                      value={
                                        option[
                                          props.localityOptions.fields[0].name
                                        ]
                                      }
                                    >
                                      {
                                        option[
                                          props.localityOptions.fields[1].name
                                        ]
                                      }
                                    </Option>
                                  );
                                })
                            : props.localityOptions.rows.map((option) => {
                                return (
                                  <Option
                                    style={{
                                      textTransform: "capitalize",
                                      color: "#1777C4",
                                    }}
                                    key={
                                      option[
                                        props.localityOptions.fields[0].name
                                      ]
                                    }
                                    value={
                                      option[
                                        props.localityOptions.fields[0].name
                                      ]
                                    }
                                  >
                                    {
                                      option[
                                        props.localityOptions.fields[1].name
                                      ]
                                    }
                                  </Option>
                                );
                              })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "gst_number"]}
                        fieldKey={[fieldKey, "gst_number"]}
                        label={
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.6rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Gst Number
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Field should not be blank!!",
                          },
                          {
                            pattern: /^[A-Z0-9]{15}$/,
                            message: "Must be 15 capital letters/numbers",
                            validator: (_, value) =>
                              value.length === 15
                                ? Promise.resolve()
                                : Promise.reject(),
                          },
                        ]}
                      >
                        <Input
                          maxLength={15}
                          style={{
                            textTransform: "uppercase", // Convert input to uppercase
                            width: "100%",
                            float: "left",
                            backgroundColor: "white",
                            // color: "#1777C4",
                            // fontWeight: "bold",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          bordered={false}
                          placeholder="Enter GST Number"
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={21} md={0}></Col>
                    <Col lg={1} md={24}>
                      <MinusCircleOutlined
                        className={classes["Remove"]}
                        onClick={() => {
                          remove(name);
                        }}
                      />
                    </Col>
                    <Col lg={1} md={24}>
                      <PlusCircleOutlined
                        className={classes["Add"]}
                        onClick={() => {
                          add();
                        }}
                      />
                    </Col>
                    <Col lg={1} md={24}></Col>
                    <Col lg={24} md={24}>
                      <hr></hr>
                    </Col>
                  </Row>
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  className={classes["DashedButton"]}
                  onClick={() => {
                    add();
                  }}
                >
                  Add Delivery
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </Form>
  );
};

export default DependentEdit;
