import React from "react";
import { Row, Col, Form, Input } from "antd";
import classes from "../Pages.module.css";
import { useEffect, useContext, useState } from "react";

const FormRenderer = (props) => {
  console.log(props, "dfdff");
  const handleChange = (e, param) => {
    console.log(e.target.value);
    console.log(param);
    props.setData((data) => {
      const newdata = [...data["uomHeader"]];
      console.log(newdata);
      newdata[0][param] = e.target.value;
      return {
        ...data,
        uomHeader: newdata,
      };
    });

    // // Call the updateUom function with the updated value
    // props.setURows((prevUrows) => {
    //   console.log(prevUrows);
    //   const updatedUrows = {
    //     ...prevUrows,

    //     uomHeader: [
    //       {
    //         ...prevUrows,
    //         [param]: e.target.value,
    //       },
    //     ],
    //   };
    //   return updatedUrows;
    // });
  };

  const handleAddRecord = () => {
    console.log(props.data);
  };

  return (
    <Row className={classes["RowDE"]}>
      <Col lg={12} md={24} className={classes["Col"]}>
        <Form layout="vertical">
          <Form.Item
            label={
              <div
                style={{
                  padding: "0rem 0.5rem",
                  fontSize: "0.6rem",
                  fontWeight: "bold",
                }}
                // className={classes["Label"]}
              >
                Unit Of Measurement
              </div>
            }
            colon={false}
            style={{ margin: "0", padding: "0" }}
          >
            <Input
              placeholder="Enter Measure Of Unit"
              value={props.save ? props.data.uom : props.data.uom}
              param="uom"
              onChange={(e) => handleChange(e, "uom")}
              bordered={false}
              style={{
                width: "100%",
                float: "left",
                backgroundColor: "white",
                color: "#1777C4",
                fontWeight: "bold",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            />
          </Form.Item>
        </Form>
      </Col>
      <Col lg={12} md={24} className={classes["Col"]}>
        {props.save ? (
          <button
            onClick={(event) => props.handleSubmit(event)}
            className={classes["ProfileButtonF"]}
          >
            Add Record
          </button>
        ) : null}
      </Col>
    </Row>
  );
};

export default FormRenderer;
