import classes from "./FieldDataC.module.css";
import { Link, Route, Routes } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import ItemDetails from "../ItemMaster/ItemDetails";
import ItemNew from "../ItemMaster/ItemNew";
import ItemView from "../ItemMaster/ItemView";
// import SalesCFormView from '../Transaction/SalesCFormView';
// import SalesCForm from '../Transaction/SalesCForm';
// import SalesCFormCreate from '../Transaction/SalesCFormCreate';
import CustomerDetails from "../CustomerMaster/CustomerDetails";
import CustomerNew from "../CustomerMaster/CustomerNew";
import CustomerView from "../CustomerMaster/CustomerView";
// import DealerDetails from '../DealerMaster/DealerDetails';
// import DealerNew from '../DealerMaster/DealerNew';
// import DealerView from '../DealerMaster/DealerView';
// import Charge from '../ChargeDefMaster/Charge';
// import ChargeCreate from '../ChargeDefMaster/ChargeCreate';
// import ChargeView from '../ChargeDefMaster/ChargeView';
import SalesDetails from "../SalesOrder/SalesDetails";
import SalesNew from "../SalesOrder/SalesNew";
import SalesView from "../SalesOrder/SalesView";
import SalesVieww from "../SalesOrder/SalesVieww";
import InvoiceDetail from "../SalesInvoice/InvoiceDetail";
import InvoiceNew from "../SalesInvoice/InvoiceNew";
import InvoiceView from "../SalesInvoice/InvoiceView";
import ChargeDetail from "../ChargeDef/ChargeDetail";
import Misc from "../Misc/Misc";
import TaxDetails from "../itemWiseTax/TaxDetails";
import TaxNew from "../itemWiseTax/TaxNew";
import TaxView from "../itemWiseTax/TaxView";
import AccountDetail from "../AccountMaster/AccountDetail";
import AccountNew from "../AccountMaster/AccountNew";
import AccMasterView from "../AccountMaster/AccMasterView";
import GaugeDetail from "../GaugePolicy/GaugeDetail";
import GaugeNew from "../GaugePolicy/GaugeNew";
import GaugeView from "../GaugePolicy/GaugeView";

import ContractDetail from "../SalesContract/ContractDetail";
import ContractNew from "../SalesContract/ContractNew";
import SalesContractView from "../SalesContract/SalesContractView";
import VoucherDetails from "../Vouchers/VoucherDetails";
import VoucherNew from "../Vouchers/VoucherNew";
import VoucherView from "../Vouchers/VoucherView";

import InvoiceRegister from "../Register/InvoiceRegister";
import DealerDetail from "../DealerMaster/DealerDetail";
import DealerNew from "../DealerMaster/DealerNew";
import DealerView from "../DealerMaster/DealerView";
import SaleOrderRegister from "../Register/SaleOrderRegister";
import PendingSalesRegister from "../Register/PendingSalesRegister";
import LedgerRegister from "../Register/LedgerRegister";
import Uom from "../UomMaster/uom";
import City from "../CityMaster/city";
import Locality from "../LocalityMaster/city";
import Country from "../CountryMaster/city";
import State from "../StateMaster/city";
import Masking from "../MaskingMaster/city";
import Color from "../ColorMaster/city";
import Texture from "../TextureMaster/city";
import AuthorizeDetail from "../AutorizeLevel/AuthoriseDetail";
import AuthorizeNew from "../AutorizeLevel/AuthorizedNew";
import AuthorizedView from "../AutorizeLevel/Authorizedview";
import Approval from "../AuthoriseApproved/Approval";
import OpeningDetail from "../OpeningBalance/OpeningDetails";
import OpeningNew from "../OpeningBalance/OpeningNew";
import OpeningView from "../OpeningBalance/OpeningView";
import DispatchDetail from "../Dispatch/SalesDetails";
import DispatchNew from "../Dispatch/SalesNew";
import ProductionDetail from "../Production/SalesDetails";
import PlanningNew from "../Production/SalesNew";
import DailyPlanningDetail from "../DailyProduction/SalesDetails";
import DailyPlanningNew from "../DailyProduction/SalesNew";
import StockStatus from "../StockStatus/SalesDetails";
import StockNew from "../StockStatus/SalesNew";
import PackingList from "../PackingList/SalesDetails";
import Packingnew from "../PackingList/SalesNew";
import SalesRep from "../SalesRep/city";
import SalesInvice from "../SalesInvoiceList/SalesDetails";
import InvoiceNewpack from "../SalesInvoiceList/SalesNew";
import Termination from "../OrderTermination/Approval";
import ProductionRegister from "../AllReports/DailyProdutionRegister";
import PendingApprovalRegister from "../AllReports/InvoiceRegister";
import ActualOrderStatus from "../AllReports/Approval";
import Consolidate from "../AllReports/LedgerRegister";
import TerminationList from "../AllReports/PendingSalesRegister";

import { useLocation } from "react-router-dom";
const FieldDataS = (fieldProps) => {
  const location = useLocation();

  console.log("Current path:", location.pathname);
  return (
    <div className={classes["Fields"]}>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/invoices-register" element={<InvoiceRegister />} />
        <Route path="/order-register" element={<SaleOrderRegister />} />
        <Route
          path="/pending-sales-register"
          element={<PendingSalesRegister />}
        />
        <Route path="/ledger" element={<LedgerRegister />} />
        <Route path="/itemMaster/item-details" element={<ItemDetails />} />
        <Route
          path="/itemMaster/item-details-view/:id"
          element={<ItemView />}
        />

        <Route path="/itemMaster/item-create" element={<ItemNew />} />

        <Route
          path="/customerMaster/customer-details"
          element={<CustomerDetails />}
        />

        <Route
          path="/customerMaster/customer-create"
          element={<CustomerNew />}
        />

        <Route
          path="/customerMaster/customer-details-view/:id"
          element={<CustomerView />}
        />
        <Route path="/DealerMaster/dealer-details" element={<DealerDetail />} />
        <Route path="/dealerMaster/dealer-create" element={<DealerNew />} />

        <Route
          path="/dealerMaster/dealer-form-view/:id"
          element={<DealerView />}
        />
        <Route
          path="/chargedefMaster/charge-details"
          element={<ChargeDetail />}
        />

        <Route path="/misc/:id1" element={<Misc />} />

        <Route path="/tax/tax-details" element={<TaxDetails />} />

        <Route path="/norms/tax-create" element={<TaxNew />} />

        <Route path="/norms/tax-form-view/:id" element={<TaxView />} />

        <Route
          path="/accountmaster/account-details"
          element={<AccountDetail />}
        />

        <Route path="/accountmaster/create-account" element={<AccountNew />} />
        <Route
          path="/accountmaster/acc-form-view/:id"
          element={<AccMasterView />}
        />
        <Route path="/transaction/salesForm" element={<ContractDetail />} />
        <Route
          path="/salescontract/create-sales-contract"
          element={<ContractNew />}
        />
        <Route
          path="/salescontract/contract-form-view/:id"
          element={<SalesContractView />}
        />
        <Route path="/norms/gauge-detail" element={<GaugeDetail />} />

        <Route path="/norms/create-gauge" element={<GaugeNew />} />
        <Route path="/norms/gauge-form-view/:id" element={<GaugeView />} />

        <Route path="/transaction/salesOrder" element={<SalesDetails />} />
        <Route path="/transaction/sales-create" element={<SalesNew />} />
        <Route
          path="/transaction/salesOrder-form-view/:id"
          element={<SalesView />}
        />

        <Route
          path="/transaction/salesOrder-form-vie/:id"
          element={<SalesVieww />}
        />

        <Route path="/transaction/salesInvoice" element={<InvoiceDetail />} />

        <Route path="/transaction/invoice-create" element={<InvoiceNew />} />
        <Route
          path="/transaction/salesInvoice-form-view/:id"
          element={<InvoiceView />}
        />

        <Route path="/transaction/voucher" element={<VoucherDetails />} />
        <Route path="/transaction/Voucher-create" element={<VoucherNew />} />
        <Route
          path="/transaction/voucher-form-view/:id"
          element={<VoucherView />}
        />

        <Route path="/uomMaster/uom-details" element={<Uom />} />
        <Route path="/cityMaster/city-details" element={<City />} />
        <Route path="/localityMaster/locality-details" element={<Locality />} />
        <Route path="/countryMaster/country-details" element={<Country />} />
        <Route path="/stateMaster/state-details" element={<State />} />
        <Route path="/masking/masking-details" element={<Masking />} />
        <Route path="/color/color-details" element={<Color />} />
        <Route path="/texture/texture-details" element={<Texture />} />

        <Route path="/master/authorizedlevel" element={<AuthorizeDetail />} />

        <Route
          path="/master/authorizedlevel-create"
          element={<AuthorizeNew />}
        />
        <Route
          path="/master/authorizedlevel-view/:id"
          element={<AuthorizedView />}
        />
        <Route path="/transaction/AuthorisedApproved" element={<Approval />} />

        <Route
          path="/transaction/openning-details"
          element={<OpeningDetail />}
        />
        <Route
          path="/transaction/opening-balance-create"
          element={<OpeningNew />}
        />

        <Route
          path="/transaction/opening-balance-view/:id"
          element={<OpeningView />}
        />

        <Route
          path="/transection/dispatch-order"
          element={<DispatchDetail />}
        />

        <Route path="/transaction/dispatch-create" element={<DispatchNew />} />
        <Route
          path="/transaction/production-details"
          element={<ProductionDetail />}
        />

        <Route
          path="/transaction/production-create"
          element={<PlanningNew />}
        />

        <Route
          path="/transaction/daily-production-details"
          element={<DailyPlanningDetail />}
        />

        <Route
          path="/transaction/daily-production-new"
          element={<DailyPlanningNew />}
        />

        <Route path="/transaction/stock-status" element={<StockStatus />} />

        <Route path="/transaction/stock-create" element={<StockNew />} />

        <Route
          path="/transaction/packing-list-details"
          element={<PackingList />}
        />

        <Route
          path="/transaction/packing-list-create"
          element={<Packingnew />}
        />

        <Route path="/master/salesrepresent" element={<SalesRep />} />

        <Route
          path="/transaction/invoice-list-details"
          element={<SalesInvice />}
        />

        <Route
          path="/transaction/invoice-list-create"
          element={<InvoiceNewpack />}
        />

        <Route path="/order/order-action" element={<Termination />} />

        <Route
          path="/transaction/production-register"
          element={<ProductionRegister />}
        />

        <Route
          path="/transaction/pending-for-approval"
          element={<PendingApprovalRegister />}
        />

        <Route
          path="/transaction/actual-order-details"
          element={<ActualOrderStatus />}
        />

        <Route
          path="/transaction/consolidate-packing-details"
          element={<Consolidate />}
        />

        <Route
          path="/transaction/order-termination-details"
          element={<TerminationList />}
        />
      </Routes>
    </div>
  );
};

export default FieldDataS;
